<template>
  <v-dialog v-model="showConfirmDialog" persistent width="400">
    <v-card>
      <v-card-title class="text-h5 bg-grey-lighten-2">競合店舗設定の変更</v-card-title>
      <v-card-text>
        <p>競合店キーワードを変更した場合、過去のデータは閲覧できなくなります。</p>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          variant="outlined"
          class="cancel-button mr-3"
          @click.stop="showConfirmDialog = false"
        >
          変更しない
        </v-btn>
        <v-btn color="primary" variant="flat" class="px-4" @click.prevent="submit()">
          変更を確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <div class="stores-editor-container">
    <div class="container tab-and-buttons">
      <div v-if="isLoading" class="progress-circular-container">
        <v-progress-circular
          :size="80"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <p
        v-if="!isLoading && tableType === TableType.Struct && structOptionCount === 0"
        class="warning-message"
      >
        店舗情報を構造化してオウンドサイトに埋め込むための構造化オプション機能は未契約です
      </p>
      <p
        v-if="tableType === TableType.Attr || tableType === TableType.Struct"
        style="font-size: small"
      >
        「はい」の場合はo(小文字のオー)、「いいえ」の場合はx(小文字のエックス)で指定してください。
      </p>
      <p v-if="tableType === TableType.Hours && canManageOpenInfo" style="font-size: small">
        店舗の営業時間は 09:00-18:00 のように開始時刻と終了時刻を -
        (ハイフン)でつないで入力してください。
        <br />
        24時間営業の場合は 00:00-24:00 と入力してください。深夜営業の場合は 20:00-03:00
        と入力してください。
        <br />
        複数設定する場合は |(半角のパイプ・バーティカルバー)でつないで入力してください。
        <br />
        特別営業時間は閉店の場合は「2023-01-01:
        x」(小文字のエックス)、時間を変更する場合は「2023-01-01:
        10:00-23:00」というように入力してください。
      </p>
      <p
        v-if="tableType === TableType.PlaceActionLinks && canManagePlaceActionLinks"
        style="font-size: small"
      >
        Googleの制約上プレイスアクションリンクの更新には時間がかかります。「変更内容を反映」ボタンを押すと更新処理が開始され、ダイアログ上に進捗が表示されます。
        <br />
        更新中は別タブを開いて他の作業をすることも可能です。
        <br />
        Googleの仕様上、リンクの優先表示の設定を単独でオフに設定することはできませんのでご注意ください。
        <br />
        表示されているプレイスアクションリンク種別のタブ以外に更新データが存在する場合も、全てのデータが「変更内容を反映」で反映されますのでご注意ください。
      </p>
      <div>
        <SubmitDialog
          :show="dialog.show"
          :title="dialog.title"
          :percentage="dialog.percentage"
          :message="dialog.message"
          :button="!dialog.button"
          @set-show="setSubmitShow"
        />
        <ImportDialog
          :show="importDialog.show"
          :title="importDialog.importTitle"
          :message="importDialog.message"
          :cancel-button="importDialog.cancelButton"
          :accept-button="importDialog.acceptButton"
          @on-accept="onImportAccept"
          @on-cancel="onImportCancel"
        />
        <div v-if="failedWeekList.includes(failedType)" class="mt-4 ml-1">
          {{ failedType }}曜日の営業時間が未記入の店舗のみ表示中
          <o-button
            class="mt-n1 ml-1"
            variant="primary"
            size="small"
            @click="clearFailedStoresFilter()"
          >
            フィルタを解除
          </o-button>
        </div>
        <div v-else-if="failedType != ''" class="mt-2 ml-1">
          {{ failedType }}が未記入の店舗のみ表示中
          <o-button
            class="mt-n1 ml-1"
            variant="primary"
            size="small"
            @click="clearFailedStoresFilter()"
          >
            未記入項目フィルタを解除
          </o-button>
        </div>
      </div>

      <div class="d-flex align-center mb-3">
        <v-autocomplete
          v-if="!canShowAllowedStoresOnly"
          v-model="selectedAreaIDList"
          :items="areas"
          item-title="name"
          item-value="areaID"
          label="グループを選択して下さい"
          single-line
          variant="underlined"
          density="compact"
          color="primary"
          multiple
          chips
          small
          hide-details
          class="me-1 w-10"
          style="max-width: 50%"
          :menu-props="{ maxHeight: 500, zIndex: 103 }"
          @keydown.capture="onAreaKeyDown"
        >
          <template #selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item[index].name }}</span>
            </v-chip>
            <span v-if="index === 6">(+{{ selectedAreaIDList.length - 6 }}グループ)</span>
          </template>
          <template #prepend-item>
            <v-list-item ripple>
              <v-list-item-action @click="toggleAllArea">
                <v-icon
                  :color="selectedAreaIDList.length > 0 ? 'primary darken-2' : ''"
                  class="mx-2"
                >
                  {{ areaCheckBoxIcon() }}
                </v-icon>
                <v-list-item-title>全選択</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
        <v-text-field
          v-model="searchWord"
          label="検索キーワード"
          variant="underlined"
          density="compact"
          single-line
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          color="primary"
          class="me-1 w-20"
          style="width: 200px; max-width: 200px"
          @keypress.enter="setFilter(searchWord)"
          @click:clear="onClearSearchWord"
        />
        <o-button variant="primary" size="small" class="me-1" @click="setFilter(searchWord)">
          絞り込み
        </o-button>
        <div class="me-1">{{ size }}件表示中</div>
        <v-checkbox
          v-if="canManage()"
          v-model="onlyDirtyRows"
          class="me-1"
          color="primary"
          hide-details
        >
          <template #label>変更行のみ表示</template>
        </v-checkbox>
        <o-button
          v-if="canManage()"
          class="reflection-button me-1"
          variant="primary"
          size="small"
          :disabled="!dirtyFlag"
          @click="tableType === TableType.Rivals ? (showConfirmDialog = true) : submit()"
        >
          変更内容を反映
        </o-button>
        <o-button
          v-if="prevErrorMessage.length > 0"
          class="reflection-button me-1"
          variant="primary"
          size="small"
          @click="showPrevErrors()"
        >
          前回反映時のエラーを表示
        </o-button>
      </div>

      <div class="tabs-container">
        <div class="store-tabs">
          <o-button
            class="tab"
            :variant="tableType !== TableType.Info ? 'light' : ''"
            :disabled="(dirtyFlag && tableType !== TableType.Info) || isInactiveStoreLocations"
            @click="changeTab(TableType.Info)"
          >
            店舗情報
          </o-button>
          <o-button
            class="tab"
            :variant="tableType !== TableType.Attr ? 'light' : ''"
            :disabled="(dirtyFlag && tableType !== TableType.Attr) || isInactiveStoreAttributes"
            @click="changeTab(TableType.Attr)"
          >
            店舗属性
          </o-button>
          <o-button
            class="tab"
            :variant="tableType !== TableType.Hours ? 'light' : ''"
            :disabled="(dirtyFlag && tableType !== TableType.Hours) || isInactiveOpenInfo"
            @click="changeTab(TableType.Hours)"
          >
            営業時間
          </o-button>
          <o-button
            class="tab"
            :variant="tableType !== TableType.PlaceActionLinks ? 'light' : ''"
            :disabled="
              (dirtyFlag && tableType !== TableType.PlaceActionLinks) || isInactivePlaceActionLinks
            "
            @click="changeTab(TableType.PlaceActionLinks)"
          >
            プレイスアクションリンク
          </o-button>
          <o-button
            class="tab"
            :variant="tableType !== TableType.Struct ? 'light' : ''"
            :disabled="(dirtyFlag && tableType !== TableType.Struct) || isInactiveStructuredInfo"
            @click="changeTab(TableType.Struct)"
          >
            構造化用情報
          </o-button>
          <o-button
            class="tab"
            :variant="tableType !== TableType.Rivals ? 'light' : ''"
            :disabled="(dirtyFlag && tableType !== TableType.Rivals) || isInactiveStoreRivals"
            @click="changeTab(TableType.Rivals)"
          >
            競合店舗
          </o-button>
          <o-button
            v-if="company.options?.includes('hosting')"
            class="tab"
            :variant="tableType !== TableType.Custom ? 'light' : ''"
            :disabled="(dirtyFlag && tableType !== TableType.Custom) || isInactiveCustomPage"
            @click="changeTab(TableType.Custom)"
          >
            カスタム情報編集
          </o-button>
          <o-upload
            v-if="canManage()"
            v-model="xlsxFile"
            accept=".xlsx"
            :disabled="isLoading || size === 0"
            @update:model-value="importFile(true)"
          >
            <span
              class="button is-primary is-small xlsx-import-button xlsx-button"
              :class="{ disabled: isLoading || size === 0 ? true : false }"
            >
              XLSXインポート
            </span>
          </o-upload>
          &nbsp;
          <o-button
            class="xlsx-button"
            variant="primary"
            size="small"
            :disabled="isLoading || size === 0"
            @click="exportFile()"
          >
            XLSXエクスポート
          </o-button>
          <div v-show="tableType === TableType.Hours" class="hours-select">
            <v-autocomplete
              v-model="selectedHoursItems"
              :items="hoursItems"
              item-title="localizedDisplayName"
              label="項目の選択"
              single-line
              variant="underlined"
              density="compact"
              chips
              small-chips
              multiple
              hide-details
              return-object
              class="more-hours-types-select"
              @change="onChangeHoursItem"
            >
              <!-- 2つ以上は"+n"で表現する -->
              <template #chip="{ item, index }">
                <v-chip v-if="index < 2" small>
                  <span>{{ item.raw.localizedDisplayName }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ selectedHoursItems.length - 2 }})
                </span>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <div v-if="tableType === TableType.Unknown">
      <p v-if="!isLoading" class="m-5">表示できる内容がありません</p>
    </div>
    <StorepageHosting
      v-else-if="tableType === TableType.Custom"
      ref="storepageHosting"
      :search-word="searchWord"
      :area-list="selectedAreaIDList"
      @records-update="updateDisplayedCount"
      @change-update="updateFromChildComponent"
      @child-emit="submit"
    />
    <place-action-links-edit
      v-else-if="tableType === TableType.PlaceActionLinks"
      ref="placeActionLinks"
      :search-word="searchWord"
      :area-list="selectedAreaIDList"
      @update-displayed-count="updateDisplayedCount"
      @change-update="updateFromChildComponent"
      @update-prev-error-message="updatePrevErrorMessage"
      @child-emit="submit"
    />
    <div
      v-else
      ref="hotContainer"
      class="hottable"
      :class="{ attrTable: tableType === TableType.Attr ? true : false }"
    >
      <div id="hot-table" ref="hot" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, toNative } from "vue-facing-decorator";
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import dot from "dot-object";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

import { useSnackbar } from "@/storepinia/snackbar";
import { requiredAuth } from "@/helpers";
import type { AxiosError } from "axios";
import { getGBPErrorMessage } from "@/helpers/error";
import { read, arrayBufferToCsv } from "@/helpers/xlsxtools";
import type {
  EntitiesStorePostResponse,
  EntitiesStore,
  EntitiesStoresResponse,
  EntitiesArea,
  EntitiesAreasResponse,
  ControllersExecStoresinfoOutput,
  EntitiesPutGMBLocationResponse,
  MybusinessbusinessinformationMoreHoursType,
} from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import type { FeatureToggle } from "@/routes/FeatureToggle";
import { getOperationLogParams } from "@/routes/operation-log";

import type { Patch, GridSettingsPlus } from "./table";
import { Model, TableType } from "./table";
import SubmitDialog from "./submit-dialog.vue";
import ImportDialog from "./import-dialog.vue";
import type { Row } from "./Row";
import { getStructType, getDepartment, updateFlattenedLocation } from "./Row";
import StorepageHosting from "./storepage-hosting.vue";
import PlaceActionLinksEdit from "./place-action-links-edit.vue";
import type { EntitiesPutGMBLocationRequest } from "@/types/ls-api";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import { fetchHoursColumns } from "./business-hours";
import { useIndexedDb, getter } from "@/storepinia/idxdb";
import { useRoute, useRouter } from "vue-router";

const numberFields: string[] = ["競合店緯度", "競合店経度"];
const model: Model = new Model();
interface UpdateError {
  poiID: number;
  title: string;
  messages: string[];
}

interface UpdateRowsResponse {
  storeUpdateCount: number;
  errors: UpdateError[];
}

@Component({
  components: {
    StorepageHosting,
    PlaceActionLinksEdit,
    SubmitDialog,
    ImportDialog,
  },
  // beforeRouteLeave(to: Route, from: Route, next: any): void {
  //   if ((this as StoreListEdit).dirtyFlag === false) {
  //     // 変更箇所なかったら普通に遷移させる
  //     next();
  //   } else if (to.name === "NotFound") {
  //     // 「店舗情報」ページのみbeforeRouteLeaveが二回発火してしまう問題の対策
  //     next();
  //   } else {
  //     const confirmation = confirm(
  //       "「変更内容を反映」ボタンがクリックされておらず、未反映の内容があります。\nこのまま別ページに移動すると編集内容は消えてしまいますが宜しいですか？"
  //     );
  //     if (confirmation) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   }
  // },
})
class StoreListEdit extends Vue {
  $route = useRoute();
  $router = useRouter();
  company = getter().company;
  canShowAllowedStoresOnly = getter().canShowAllowedStoresOnly;
  user = getter().user;
  isInactiveStoreLocations = getter().isInactiveStoreLocations;
  isInactiveStoreAttributes = getter().isInactiveStoreAttributes;
  isInactiveOpenInfo = getter().isInactiveOpenInfo;
  isInactivePlaceActionLinks = getter().isInactivePlaceActionLinks;
  isInactiveStructuredInfo = getter().isInactiveStructuredInfo;
  isInactiveStoreRivals = getter().isInactiveStoreRivals;
  isInactiveCustomPage = getter().isInactiveCustomPage;
  canShowCustomPage = getter().canShowCustomPage;
  canManageStoreLocations = getter().canManageStoreLocations;
  canManageStoreAttributes = getter().canManageStoreAttributes;
  canManageOpenInfo = getter().canManageOpenInfo;
  canManagePlaceActionLinks = getter().canManagePlaceActionLinks;
  canManageStructuredInfo = getter().canManageStructuredInfo;
  canManageStoreRivals = getter().canManageStoreRivals;
  canManageCustomPage = getter().canManageCustomPage;
  canEditStructuredID = getter().structuredIDEdit;

  @Prop({ default: "", type: String, required: false }) readonly failedType: string;
  @Prop({ default: "", type: String, required: false }) readonly failedStores: string;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  poiGroupId: number = 0;
  TableType = TableType;
  tableType = TableType.Unknown;
  hoti?: Handsontable = null; // Handsontable instance
  size: number = 0;
  structOptionCount: number = 0;
  active: boolean = false;
  // buttonはボタンの表示非表示を切り替えるためのフラグ、buttonがfalseの場合はボタンを表示しない
  dialog = {
    show: false,
    percentage: 0,
    title: "",
    message: "",
    button: true,
  };
  importDialog = {
    show: false,
    importTitle: "XLSXインポート",
    message: "",
    cancelButton: "",
    acceptButton: "",
  };
  showConfirmDialog = false;
  isLoading: boolean = true;
  dirtyFlag: boolean = false;
  xlsxFile: File = null;
  searchWord: string = "";
  dict = wordDictionary.reports;
  isComUser: boolean = false;
  featureToggle: FeatureToggle;
  updateTotal: number = 0;
  updateCount: number = 0;
  prevErrorMessage: string = "";
  areas: EntitiesArea[] = [];
  areaPulldown: { [areaName: string]: string }[] = [];
  selectedAreaIDList: number[] = [];
  onlyDirtyRows = false;
  hoursItems: MybusinessbusinessinformationMoreHoursType[] = [];
  selectedHoursItems: MybusinessbusinessinformationMoreHoursType[] = [];
  failedWeekList: string[] = ["月", "火", "水", "木", "金", "土", "日"];

  @Watch("onlyDirtyRows")
  onOnlyDirtyRowsChange(): void {
    this.setFilter(this.searchWord);
  }

  @Watch("$route")
  onRoute(): void {
    this.fetch();
  }

  beforeUnmount(): void {
    this.hoti?.destroy();
    model.isDestroyed = true;
    window.removeEventListener("resize", this.handleResize);
  }
  unmounted(): void {
    window.removeEventListener("beforeunload", this.beforeLeave);
  }
  beforeLeave(e: Event): void {
    if (this.dirtyFlag) {
      e.preventDefault();
    }
  }

  showSnackbar(snackbarToast: SnackbarToast): void {
    this.addSnackbarMessages(snackbarToast);
  }

  initialFirstTab(): TableType {
    if (!this.isInactiveStoreLocations) {
      return TableType.Info;
    } else if (!this.isInactiveStoreAttributes) {
      return TableType.Attr;
    } else if (!this.isInactiveOpenInfo) {
      return TableType.Hours;
    } else if (!this.isInactivePlaceActionLinks) {
      return TableType.PlaceActionLinks;
    } else if (!this.isInactiveStructuredInfo) {
      return TableType.Struct;
    } else if (!this.isInactiveStoreRivals) {
      return TableType.Rivals;
    } else if (this.company.options?.includes("hosting") && !this.isInactiveCustomPage) {
      // カスタム情報編集タブは企業オプションも必要
      return TableType.Custom;
    } else {
      return TableType.Unknown;
    }
  }

  async created(): Promise<void> {
    window.addEventListener("beforeunload", this.beforeLeave);
    this.poiGroupId = this.company.poiGroupID;
    this.featureToggle = this.$route.meta.featureToggle as FeatureToggle;
    this.isComUser = useIndexedDb().isComUser;
    if (!this.canShowAllowedStoresOnly) {
      // 店舗所属の場合はエリア情報が取れない
      await this.createAreaPullDown();
    }
    let firstTab = this.initialFirstTab();
    if (firstTab === TableType.Unknown) {
      this.isLoading = false;
      return;
    }
    // 店舗情報の完成度の曜日から遷移してきた場合
    if (this.failedWeekList.includes(this.failedType)) {
      if (this.isInactiveOpenInfo) {
        this.clearFailedStoresFilter();
        this.showSnackbar({
          text: "営業時間を表示する権限がありません",
          color: "danger",
        });
      } else {
        firstTab = TableType.Hours;
      }
    }
    await model.init();
    await this.initHandsontable(firstTab);
    await this.fetch();
  }
  mounted(): void {
    window.addEventListener("resize", this.handleResize);
  }
  handleResize(): void {
    if (this.hoti != null && this.$refs.hotContainer != null) {
      // ウィンドウサイズを元にしてテーブルの高さを調整 (18は content-wrapper の padding の分)
      this.hoti.updateSettings(
        {
          height: window.innerHeight - (this.$refs.hotContainer as HTMLElement).offsetTop - 10,
        },
        false
      );
    }
  }
  canManage(): boolean {
    switch (this.tableType) {
      case TableType.Info:
        return this.canManageStoreLocations;
      case TableType.Attr:
        return this.canManageStoreAttributes;
      case TableType.Hours:
        return this.canManageOpenInfo;
      case TableType.PlaceActionLinks:
        return this.canManagePlaceActionLinks;
      case TableType.Struct:
        return this.canManageStructuredInfo;
      case TableType.Rivals:
        return this.canManageStoreRivals;
      case TableType.Custom:
        return this.canManageCustomPage;
      default:
        return false;
    }
  }
  updateHoursColumns(): void {
    if (this.hoti != null) {
      this.hoti.updateSettings({ columns: fetchHoursColumns(this.selectedHoursItems) }, false);
    }
  }
  /** Handsontable を初期化する */
  async initHandsontable(tableType: TableType): Promise<void> {
    this.tableType = tableType;
    await this.$nextTick(); // hotContainer の v-if が反映されるのを待つ
    this.hoti?.destroy(); // hoti があれば破棄する
    const elem = document.getElementById("hot-table") as Element;
    const settings = model.getGridSettings(tableType, this.canManage(), this.selectedHoursItems);
    // Handsontable を使わないタブ、または hot-table が存在しない場合は hoti = null
    if (!settings || !elem) {
      this.hoti = null;
      return;
    }
    this.hoti = new Handsontable(elem, settings);
    (this.hoti as any).model = model;
    (this.hoti as any).parent = this;
    (document as any).hoti = this.hoti; // for debugging
  }
  async fetch(): Promise<void> {
    try {
      this.isLoading = true;
      const accountName: string = useIndexedDb().company.gmbAccount;
      for await (const refresh of model.fetch(this.poiGroupId, accountName)) {
        await this.hoursItemFetch();
        if (refresh.completed === true) {
          this.setFilter("");
        } else if (refresh.error) {
          // batchGet取得失敗してerrorが返ってきた場合
          const errorMessage: string =
            refresh.error.response?.status === 404
              ? "無効な店舗が見つかったため現在利用できません。ConnectOM側で対応が完了するまでお待ち下さい。"
              : "サーバーが混み合っています。すこし時間を置いて再読み込みしてください。";

          const originalErrorMessage = refresh.error.response?.data?.errorMessage ?? "";
          this.showDialog(
            "店舗情報一覧の取得に失敗しました。",
            `${errorMessage}<br/>${originalErrorMessage}`,
            true
          );
        }
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        this.showDialog("店舗情報一覧の取得に失敗しました。", e.message ?? "", true);
      }
    } finally {
      this.isLoading = false;
    }
  }
  async setFilter(searchWord: string): Promise<void> {
    if (this.tableType === TableType.Custom) {
      (this.$refs.storepageHosting as InstanceType<typeof StorepageHosting>).usePickingDirtyRows(
        this.onlyDirtyRows
      );
    }
    if (this.tableType === TableType.PlaceActionLinks) {
      (
        this.$refs.placeActionLinks as InstanceType<typeof PlaceActionLinksEdit>
      ).usePickingDirtyRows(this.onlyDirtyRows);
    }

    if (this.hoti == null || this.hoti.isDestroyed === true) {
      return;
    }

    // ソートされてたらデフォルトに戻す
    const columnSorting = this.hoti.getPlugin("columnSorting");
    if (columnSorting?.isSorted() === true) {
      columnSorting.clearSort();
    }

    const hoti: Handsontable = this.hoti;
    // dirtyRowsを正確に取得する為にapplyModelToTableをかけて一旦リセットする必要がある
    this.applyModelToTable();
    const rowLength = this.hoti.countRows();

    const dirtyRows: number[] = [];
    if (this.onlyDirtyRows) {
      for (let i = 0; i < rowLength; i++) {
        const cell: GridSettingsPlus = hoti.getCellMeta(i, 0) as GridSettingsPlus;
        const row = hoti.getSourceDataAtRow(cell.row) as Row;
        const isDirty = cell.isDirty;
        if (isDirty === true) {
          dirtyRows.push(row.poiID);
        }
      }
    }

    const newLocations = model.getLocations(
      searchWord,
      this.failedStores,
      this.tableType,
      this.selectedAreaIDList,
      this.onlyDirtyRows,
      dirtyRows
    );

    this.size = newLocations.length;
    this.structOptionCount = model.structOptionCount;
    this.selectedHoursItems = [];
    this.initSelectedMoreHours(newLocations);
    hoti.loadData(newLocations);
    this.updateDirtyFlag();
    this.handleResize();
  }

  /* 既に設定された項目を選択状態にする */
  private initSelectedMoreHours(locations: Row[]): void {
    if (this.selectedHoursItems.length > 0) {
      // 既に選択アイテムがあったらこの処理は行わない
      return;
    }
    const selected = new Set<string>();
    locations.map((l) => {
      Object.keys(l?.moreHours)
        .filter((mh) => mh.endsWith("Mon"))
        ?.forEach((key) => selected.add(key.replace("Mon", "")));
    });
    const selectedItems = new Set<MybusinessbusinessinformationMoreHoursType>(
      this.selectedHoursItems
    );
    this.hoursItems.map((hi) => {
      if (selected.has(hi?.hoursTypeId)) {
        selectedItems.add(hi);
      }
    });
    this.selectedHoursItems = Array.from(selectedItems);
  }

  clearFailedStoresFilter(): void {
    this.$router.push({
      name: "StoresSpreadForm",
      params: { poiGroupId: `${this.poiGroupId}` },
    });
    this.searchWord = "";
    this.setFilter("");
  }
  async changeTab(tableType: TableType): Promise<void> {
    await this.initHandsontable(tableType);
    this.setFilter(this.searchWord);
  }
  /** モデルの情報をテーブルに反映する */
  applyModelToTable(newLocations?: Row[]): void {
    newLocations =
      newLocations ||
      model.getLocations(
        this.searchWord,
        this.failedStores,
        this.tableType,
        this.selectedAreaIDList
      );
    const hoti: Handsontable = this.hoti;
    hoti.loadData(newLocations);
    // バリデータを起動する
    const rows: number[] = [];
    for (let i = 0; i < hoti.countRows(); i++) {
      rows.push(i);
    }
    hoti.validateRows(rows, undefined);
    // afterChangeを起動して列ヘッダのダーティフラグを更新する
    hoti.getSettings().afterChange.bind(hoti)(
      rows.map((n) => [n, null, null, null]),
      null
    );
  }
  async importFile(accept: boolean): Promise<void> {
    try {
      if (!this.xlsxFile) {
        return;
      }
      this.isLoading = true;
      // 初期化
      await new Promise((resolve) => setTimeout(resolve, 100)); // ローディングアイコン表示のため少しSleep
      const file: File = this.xlsxFile as any;
      if (this.tableType === TableType.Custom) {
        // カスタム情報編集タブを表示している場合
        (this.$refs.storepageHosting as InstanceType<typeof StorepageHosting>).importFile(file);
        this.xlsxFile = null;
      } else if (this.tableType === TableType.PlaceActionLinks) {
        // プレイスアクションリンクタブを表示している場合
        (this.$refs.placeActionLinks as InstanceType<typeof PlaceActionLinksEdit>).importFile(file);
        this.xlsxFile = null;
      } else {
        // xlsx読み取り
        let fields: string[];
        let rows: any[];
        try {
          const buf = await read(file);
          [fields, rows] = arrayBufferToCsv(buf);
          if (fields.length === 0) {
            this.showImportDialog("XLSXに ヘッダ行 がありません", "", "キャンセル");
            return;
          }
        } catch (e) {
          console.error(e);
          this.showImportDialog("XLSXの読み込みに失敗しました", "", "キャンセル");
          return;
        }

        // 「営業時間の詳細」の場合はカラムにfieldを反映
        if (this.tableType === TableType.Hours) {
          this.updateHoursColumnFromXLSX(fields);
        }

        const hoti: Handsontable = this.hoti;
        const columns = hoti.getSettings().columns as any[];

        // 店舗ID列がなければエラーとする (columnsの最初の列が店舗ID)
        if (fields.includes(columns[0].title) === false) {
          this.showImportDialog(`XLSXに ${columns[0].title} がありません`, "", "キャンセル");
          return;
        }
        // 知らないカラムがあったら警告を出す
        const columnTitles = columns.map((c) => c.title);
        if (accept === false) {
          const unknownFields = [];
          for (const field of fields) {
            if (columnTitles.includes(field) === false) {
              unknownFields.push(field);
            }
          }
          if (1 <= unknownFields.length) {
            this.showImportDialog(
              `XLSX中に不明なカラムがあります: ${unknownFields.join(", ")}<br>
                            XLSXのこの列を無視して読み込みますか`,
              "はい",
              "いいえ"
            );
            return;
          }
        }
        this.xlsxFile = null;

        // モデルにXLSXの内容を反映する
        const srcrows = hoti.getSourceDataArray();
        const locs = model.getLocations(
          this.searchWord,
          this.failedStores,
          this.tableType,
          this.selectedAreaIDList
        );
        let count = 0;
        for (const row of rows) {
          const poiId = row[columns[0].title];
          const srcrow = srcrows.find((srcrow) => `${srcrow[0]}` === `${poiId}`);
          if (!srcrow) {
            // XLSXにあってテーブルに無いpoiIdは無視する
            continue;
          }
          const loc = locs.find((l) => `${l.poiID}` === `${poiId}`);
          const name = loc.name;
          count++;
          for (const field of fields) {
            const index = columnTitles.indexOf(field);
            if (index < 0) {
              // XLSXにあってテーブルに無い不明なカラムは無視する
              continue;
            }
            if (columns[index].readOnly === true) {
              // readOnlyのカラムは更新しない
              continue;
            }
            const prop = columns[index].data;
            let newVal: string = row[field];
            // 改行コードはすべてLFにとして読み込む
            newVal = newVal.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
            // 開業日はExcelで編集するとスラッシュ区切りになってしまうので、ハイフン区切りにする
            if (field === "開業日" && 0 <= newVal.indexOf("/")) {
              newVal = dayjs(newVal, "YYYY/MM/DD").format("YYYY-MM-DD");
            }
            if (numberFields.includes(field) && newVal) {
              model.setVal(name, prop, +newVal);
            } else {
              model.setVal(name, prop, newVal);
            }
          }
          await model.deleteUneditableAttribute(name);
          await model.deleteUneditableMoreHoursTypes(name);
        }
        // モデルの更新が終わったので Handsontable にモデルのデータを読み込む
        this.applyModelToTable();
        // インポート行数を表示する
        this.showImportDialog(`${count} 行 インポートしました。`, "", "OK");
      }
    } catch (e) {
      console.error(e);
      throw Error("xlsxファイルのインポートに失敗しました。");
    } finally {
      this.isLoading = false;
    }
  }
  async exportFile(): Promise<void> {
    try {
      this.isLoading = true;
      await new Promise((resolve) => setTimeout(resolve, 100)); // ローディングアイコン表示のため少しSleep
      if (this.tableType === TableType.Custom) {
        // カスタム情報編集タブを表示している場合
        (this.$refs.storepageHosting as InstanceType<typeof StorepageHosting>).exportFile();
      } else if (this.tableType === TableType.PlaceActionLinks) {
        // プレイスアクションリンクタブを表示している場合
        (
          this.$refs.placeActionLinks as InstanceType<typeof PlaceActionLinksEdit>
        ).exportUpdateFile();
      } else {
        // Handsontable@6.2.2 の ExportFile は PRO の機能なので、自前で実装する
        const hoti: Handsontable = this.hoti;
        const columns = hoti.getSettings().columns as any[];
        // Sheet.js を用いて xlsx ファイルを作成する
        const aoa = []; // Array of arrays
        aoa.push(columns.map((c) => c.title));
        for (const src of hoti.getSourceData()) {
          const arr = [];
          for (const column of columns) {
            const str = `${dot.pick(column.data, src) ?? ""}`
              .replace(/\r\n/g, "\n")
              .replace(/\r/g, "\n");
            arr.push(str);
          }
          aoa.push(arr);
        }
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(aoa);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const buf: ArrayBuffer = XLSX.write(wb, {
          type: "array",
          bookType: "xlsx",
          bookSST: true,
        });
        const companyName = useIndexedDb().company.name;
        const datetime = dayjs().format("YYYYMMDD");
        const typeName = ["店舗情報", "店舗属性", "営業時間", "店舗構造化情報", "競合店舗"][
          this.tableType
        ];
        const filename = `${wordDictionary.service.name}-${typeName}エクスポート-${companyName}-${datetime}.xlsx`;
        saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
      }
    } catch (e) {
      console.error(e);
      throw Error("xlsxファイルのエクスポートに失敗しました。");
    } finally {
      this.isLoading = false;
    }
  }

  async submit(): Promise<void> {
    this.showConfirmDialog = false;
    // 情報統一化用タグ編集機能が有効の場合は、一意性を保つためsubmit時に重複チェックする
    if (this.tableType === TableType.Info && this.canEditStructuredID) {
      const hoti: Handsontable = this.hoti;
      const rows: Row[] = hoti?.getSourceData();
      if (rows) {
        const ids = rows.map((row) => row.structuredPageID);
        const uniqueStructuredPageIDs = [...new Set(ids)];
        if (ids.length !== uniqueStructuredPageIDs.length) {
          // 重複しているstructuredPageIDを抽出する
          const duplicatedStructuredPageIDs = ids.filter((id, index) => ids.indexOf(id) !== index);
          // 重複しているstructuredPageIDを持つpoiIDと店舗名のリストを作成する
          const duplicatedStoress = rows
            .filter((row) => duplicatedStructuredPageIDs.includes(row.structuredPageID))
            .map((row) => `${row.poiID}:${row.title}`);
          this.showDialog(
            "情報統一用タグが重複しています",
            "情報統一化用IDは一意である必要があります<br>" + duplicatedStoress.join("<br>"),
            true
          );
          return;
        }
      }
    }

    //  検索キーワードと変更行チェックをリセットする。
    //  検索と変更行チェック同時使用していた場合、
    //  そのままだと表示されている分しか反映対象に含まれなくなるので、
    //  このタイミングでリセットしてやる必要がある
    this.searchWord = "";
    this.onlyDirtyRows = false;
    await this.$nextTick();

    this.dialog.message = "";
    if (this.tableType === TableType.Custom) {
      await (this.$refs.storepageHosting as InstanceType<typeof StorepageHosting>).submit();
      return;
    }
    if (this.tableType === TableType.PlaceActionLinks) {
      await (
        this.$refs.placeActionLinks as InstanceType<typeof PlaceActionLinksEdit>
      ).submitConfirm();
      return;
    }
    // 続けて更新する場合にdisableにするためにfalseを設定しておく
    this.dialog.button = false;
    this.dialog.show = true;
    const hoti: Handsontable = this.hoti;
    const rows: Row[] = hoti.getSourceData();
    let updatedRows: Array<[Row, number, Patch]>;
    try {
      // カテゴリが変わっていた場合に営業時間の詳細の選択肢をリフレッシュしたいので初期化
      model.moreHoursTypesMap = {};
      // カテゴリが変更されていたときのために属性情報と営業時間の詳細を再取得する
      await Promise.all([model.fetchAttributeMetadata(), model.fetchMoreHoursTypes()]);
      // accounts.locations.patchにわたす情報を作成する
      updatedRows = rows.map((row, index) => {
        return [row, index, model.buildLocationPatch(row.name)];
      });
      updatedRows = updatedRows.filter(
        (x) => 0 < x[2].updateItems.length || 0 < x[2].updateAttributes.length
      );
      if (updatedRows.length === 0) {
        this.dialog.button = true;
        return;
      }
    } catch (e) {
      console.error(e);
      this.dialog.title = "変更内容を反映中";
      if (e instanceof Error) {
        this.dialog.message = e.message;
      }
      this.dialog.button = true;
      return;
    }
    // GBPアクセストークンを初期化する
    try {
      await requiredAuth<any>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/gmbapiinit`
      );
    } catch (e) {
      const error = e as AxiosError;
      this.showDialog(
        "変更内容の反映を開始できませんでした",
        `内部処理エラーが発生しました。システム管理者にお問い合わせください。 ${error.response?.status}`,
        true
      );
      return;
    }
    this.dialog.title = "変更内容を反映中";
    // プログレスバー用のパラメータ
    this.dialog.percentage = 0;
    this.updateTotal = updatedRows.length;
    this.updateCount = 0;
    this.prevErrorMessage = "";

    // 指定されたworker数で更新対象店舗を分割して反映させる
    const dividedRows = this.divideRows(updatedRows);
    const workers = [];
    for (let idx = 0; idx < model.bulkUpdateWorkers; idx++) {
      const p = new Promise((resolve, _) => {
        const response = this.updateRows(dividedRows[idx]);
        resolve(response);
      });
      workers.push(p);
    }
    let updateStructCount = 0;
    await new Promise((resolve) => setTimeout(resolve, 100)); // ダイアログ表示のため少しSleep
    Promise.all(workers).then((resultList) => {
      const results = resultList as UpdateRowsResponse[];
      // モデルの更新が終わったので Handsontable にモデルのデータを読み込む
      this.applyModelToTable();

      let errorMessage = "";
      for (const result of results) {
        updateStructCount += result.storeUpdateCount;
        for (const error of result.errors) {
          errorMessage += "<hr>";
          errorMessage += `<b>${error.poiID}</b> ${error.title}<br/>`;
          for (const message of error.messages) {
            errorMessage += message + "<br/>";
          }
        }
      }

      const showResult = () => {
        let dialogTitle = "反映が完了しました";
        let dialogMessage =
          this.updateTotal + "件中 " + this.updateCount + "件反映しました。<br/><br/>";
        this.dialog.message = "";
        if (errorMessage.length > 0) {
          console.log("errorMessage: %s", errorMessage);
          dialogTitle += " (エラーあり)";
          let message = "以下の店舗はエラーがあり反映できませんでした。<br/>";
          message += "エラー内容をご確認の上、修正と再反映をお試しください。<br/>";
          message += errorMessage;
          this.updatePrevErrorMessage(message);
          dialogMessage += message;
        }
        this.dialog.message = dialogMessage;
        this.dialog.percentage = 100;
        this.dialog.button = true;
        this.dialog.title = dialogTitle;
        this.updateDirtyFlag(true);
      };

      if (updateStructCount > 0) {
        // すべてのデータ更新が完了したので、店舗ページの再作成を実行
        requiredAuth<ControllersExecStoresinfoOutput>(
          "post",
          `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
            this.poiGroupId
          }/storesinfo/execStoresinfo`
        )
          .then((res) => {
            if (res.status !== 200) {
              errorMessage += "--------------------<br/>";
              errorMessage += `店舗ページの出力開始が失敗しました ${res.status} <br/>`;
            }
          })
          .catch((e) => {
            errorMessage += "--------------------<br/>";
            errorMessage += `店舗ページの出力開始が失敗しました ${e} <br/>`;
          })
          .finally(() => {
            showResult();
          });
      } else {
        showResult();
      }
    });
    await this.hoursItemFetch();
  }

  updatePrevErrorMessage(message: string) {
    this.prevErrorMessage = message;
  }

  divideRows(rows: [Row, number, Patch][]): [Row, number, Patch][][] {
    const result: [Row, number, Patch][][] = [];
    for (let i = 0; i < model.bulkUpdateWorkers; i++) {
      result[i] = [];
    }
    rows.forEach((row, index) => result[index % model.bulkUpdateWorkers].push(row));
    return result;
  }

  async updateRows(rows: [Row, number, Patch][]): Promise<UpdateRowsResponse> {
    const errors: UpdateError[] = [];
    let storeUpdateCount = 0;
    for (const [row, _, patch] of rows) {
      try {
        const errorDetails = model.verify(row.name, this.tableType).map((message) => {
          return message;
        });
        if (0 < errorDetails.length) {
          errors.push({
            poiID: row.poiID,
            title: row.title,
            messages: errorDetails,
          });
          continue;
        }
        this.dialog.message = `${row.title} を更新中`;
        if (
          this.tableType === TableType.Info ||
          this.tableType === TableType.Attr ||
          this.tableType === TableType.Hours
        ) {
          if (patch.updateItems.includes("structuredPageID")) {
            // 情報統一化用タグの更新は store への反映なので、locationとは別で処理する
            const store: EntitiesStore = { structuredPageID: row.structuredPageID };
            await requiredAuth<EntitiesStorePostResponse>(
              "put",
              `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores/${
                row.poiID
              }`,
              getOperationLogParams(this.$route, "structuredpageid-put"),
              store
            )
              .then((response) => {
                if (response.status !== 200) {
                  errors.push({
                    poiID: row.poiID,
                    title: row.title,
                    messages: [`情報統一化用タグの更新失敗(不正なエラーが発生:${response.status})`],
                  });
                } else {
                  model.updateStructuredPageID(row.poiID, row, row.structuredPageID);
                  storeUpdateCount++;
                }
              })
              .catch((e) => {
                errors.push({
                  poiID: row.poiID,
                  title: row.title,
                  messages: ["情報統一化用タグの更新失敗(他の店舗と重複している可能性があります)"],
                });
              });
            patch.updateItems = patch.updateItems.filter((item) => item !== "structuredPageID");
            storeUpdateCount++;
          }
          // structuredPageIDの更新以外があった場合は、GBP locationsの更新処理を行う
          if (patch.updateItems.length > 0 || patch.updateAttributes.length > 0) {
            // GBP locationsの更新処理
            delete patch.gmbLocation.localBusinessInfo;
            const actionType = this.tableType === TableType.Info ? "put" : "addition-put";
            var gmbLocPut: EntitiesPutGMBLocationRequest;
            gmbLocPut = {
              gmbLocation: patch.gmbLocation,
              oldGmbLocation: patch.oldGmbLocation,
              updateAttributes: patch.updateAttributes,
              updateItems: patch.updateItems,
            };
            await requiredAuth<EntitiesPutGMBLocationResponse>(
              "put",
              this.getPutURL(row.poiID),
              getOperationLogParams(this.$route, actionType),
              gmbLocPut
            ).then((response) => {
              // Lambdaが200以外を返してきた場合はエラーとする
              if (response.status !== 200) {
                const res = JSON.parse(response?.request?.response);
                errors.push({
                  poiID: row.poiID,
                  title: row.title,
                  messages: [res?.errorMessage],
                });
              } else {
                updateFlattenedLocation(
                  row,
                  response.data?.location,
                  response.data?.attributes?.attributes,
                  response.data?.location?.moreHours,
                  model.categoryNameToDisplayNameMap
                );
                model.updateCurrent(row);
                model.updateSource(row);
                storeUpdateCount++;
              }
            });
          }
        } else if (this.tableType === TableType.Struct) {
          const store: EntitiesStore = { localBusinessInfo: patch.localBusinessInfo };
          await requiredAuth<EntitiesStorePostResponse>(
            "put",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores/${
              row.poiID
            }/localBusinessInfo`,
            getOperationLogParams(this.$route, "struct-put"),
            store
          );
          // 反映に成功したら結果をテーブルに反映する（構造化情報はレスポンスがほぼ空のため自力で更新）
          row.smokingAllowed = patch.localBusinessInfo.smokingAllowed ? "o" : "x";
          row.department = getDepartment(patch.localBusinessInfo.department);
          row.primaryType = getStructType(patch.localBusinessInfo.type)[0];
          row.subType = getStructType(patch.localBusinessInfo.type)[1];
          model.updateCurrent(row);
          model.updateSource(row);
          storeUpdateCount++;
        } else if (this.tableType === TableType.Rivals) {
          // 最新データを再取得
          const currentStore = await requiredAuth<EntitiesStoresResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores/${
              row.poiID
            }`,
            null
          );
          if (currentStore?.data?.stores?.length === 1) {
            currentStore?.data?.stores[0]?.rivals?.forEach((cr) => {
              patch.rivals.forEach((r) => {
                // キーワードが同じならrivalIDを変えない
                if (r != null && r?.keyword === cr.keyword) {
                  r.rivalID = cr.rivalID;
                  r.latestReviewCollectedAt = cr.latestReviewCollectedAt;
                }
              });
            });
          }
          let store: EntitiesStore = {};
          if (patch.isRivalsUpdated) {
            store = { ...store, rivals: patch.rivals };
          }
          await requiredAuth<EntitiesStorePostResponse>(
            "put",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores/${
              row.poiID
            }/rivals`,
            getOperationLogParams(this.$route, "rivals-update"),
            store
          );
          // 反映に成功したら結果をテーブルに反映する（構造化情報はレスポンスがほぼ空のため自力で更新）
          model.updateCurrent(row);
          model.updateSource(row);
          storeUpdateCount++;
        }

        this.hoti.render();

        // プログレスバーを進捗させる
        this.updateCount++;
        this.dialog.percentage = (this.updateCount / this.updateTotal) * 100;
      } catch (e) {
        const errorMessage = this.getErrorMessage(e as any, row.poiID);
        errors.push({
          poiID: row.poiID,
          title: row.title,
          messages: [errorMessage],
        });
      }
    }
    return {
      storeUpdateCount,
      errors,
    };
  }
  getPutURL(poiID: number): string {
    // 権限カスタマイズの兼ね合いでタブによってAPIエンドポイントが変わります
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
      this.poiGroupId
    }/stores/${poiID}/gmbLocation`;
    switch (this.tableType) {
      case TableType.Info:
        return url;
      case TableType.Attr:
        return `${url}/attributes`;
      case TableType.Hours:
        return `${url}/openInfo`;
    }
  }
  getErrorMessage(
    e: {
      response: { data: { GMBError: { error: any }; message: string; errorMessage: any } };
      message: any;
    },
    poiID: number
  ): string {
    let errorMessage = "";
    if (e?.response?.data?.GMBError?.error) {
      // GBPからエラー情報が返ってきた
      errorMessage = `${getGBPErrorMessage(
        e.response.data.GMBError.error,
        model.getDashboardURL(poiID) // GBP管理画面のURL
      )}`;
    } else if (e?.response?.data?.message) {
      // API Gatewayのエラー
      errorMessage = e?.response?.data?.message ?? "想定外のエラー";
      errorMessage = errorMessage.replace(
        "Endpoint request timed out",
        "30秒経ってもサーバーから応答がありませんでした。<br/>お手数ですが画面を読み込み直して、更新に成功しているかご確認ください。"
      );
    } else {
      errorMessage =
        `時間を置いても同じエラーになる場合、お手数ですが担当者までお問い合わせください。<br/><br/>・${e?.message}<br/>` +
        `・${e?.response?.data?.errorMessage}`;
    }
    return errorMessage;
  }
  setSubmitShow(show: boolean): void {
    if (show === false) {
      // ダイアログ閉じた時点でタイトルと本文初期化しておく
      this.dialog.title = "";
      this.dialog.message = "";
    }
    this.dialog.show = show;
  }
  onImportAccept(): void {
    this.importFile(true);
  }
  onImportCancel(): void {
    this.importDialog.show = false;
    this.xlsxFile = null;
  }
  showDialog(title: string, message: string, showButton: boolean): void {
    this.dialog.title = title;
    this.dialog.message = message;
    this.dialog.button = showButton;
    this.dialog.show = true;
  }
  showImportDialog(message: string, acceptButton: string, cancelButton: string): void {
    this.importDialog.message = message;
    this.importDialog.acceptButton = acceptButton;
    this.importDialog.cancelButton = cancelButton;
    this.importDialog.show = true;
  }
  showPrevErrors(): void {
    this.dialog.title = "前回反映時のエラー";
    this.dialog.message = this.prevErrorMessage;
    this.dialog.button = true;
    this.dialog.show = true;
  }
  /** ダーティフラグを更新する */
  updateDirtyFlag(submitted: boolean = false): void {
    // Handsontableが初期化されていなければ false
    if (!this.hoti) {
      this.dirtyFlag = false;
      return;
    }

    const rowLength = this.hoti.countRows();
    const hoti: Handsontable = this.hoti;
    // セルのisDirtyが一つでもあれば false
    let isClean = true;
    for (let i = 0; i < rowLength; i++) {
      const cell: GridSettingsPlus = hoti.getCellMeta(i, 0) as GridSettingsPlus;
      const isDirty = cell.isDirty;
      if (submitted === true && isDirty === true) {
        model.updateModificationMark(hoti, false, cell, i);
      } else {
        // 検索キーワードによる絞り込み後にもdirtyな行を再びマークする
        const row = hoti.getSourceDataAtRow(cell.row) as Row;
        if (!row) continue; // rowが取得できない場合はスキップ
        const isCellDirty = model.isDirtyRow(row.name);
        model.updateModificationMark(hoti, isCellDirty, cell, i);
      }
      if (isDirty) {
        isClean = false;
      }
    }
    if (submitted === true) {
      // 反映直後はキレイな状態
      this.dirtyFlag = false;
    } else {
      // それ以外はisCleanを参照
      this.dirtyFlag = !isClean;
    }

    hoti.render();
    return;
  }

  updateDisplayedCount(length: number): void {
    this.size = length;
  }

  updateFromChildComponent(isUpdate: boolean): void {
    this.dirtyFlag = isUpdate;
  }

  /** グループ選択プルダウン生成 */
  async createAreaPullDown(): Promise<void> {
    const response = await requiredAuth<EntitiesAreasResponse>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/areas`
    );
    if (response == null || response.data == null || response.data.areas == null) {
      this.areas = [];
    } else {
      this.areas = response.data.areas;
    }
  }
  /** グループ全選択トグル */
  toggleAllArea(e: PointerEvent): void {
    // Enterキーによる「全選択」誤爆を防ぐ
    if (e.pointerId === -1) {
      return;
    }
    if (this.selectedAreaIDList.length === this.areas.length) {
      this.selectedAreaIDList = [];
    } else {
      this.selectedAreaIDList = this.areas.map((a) => a.areaID);
    }
  }
  /** グループ選択チェックボックスのclassNameを返す */
  areaCheckBoxIcon(): string {
    if (this.selectedAreaIDList.length === this.areaPulldown.length) return "mdi-close-box";
    if (this.selectedAreaIDList.length > 0) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }
  /** Enterキーで最後にON/OFFしていたグループ項目が選択されてしまうのを抑止 */
  onAreaKeyDown(e: KeyboardEvent): void {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  }

  onClearSearchWord(): void {
    this.searchWord = "";
    // なぜかカスタム情報タブでクリア時にフィルタリング解除されない問題対策
    this.$nextTick(() => {
      this.setFilter("");
    });
  }

  /* 選択できる営業時間の詳細一覧を取得 */
  async hoursItemFetch(): Promise<void> {
    this.hoursItems.splice(0, this.hoursItems.length);
    // model側で取得している全てのmoreHoursTypesを重複を排除して1つの配列にする
    const distinct = Object.values(model.moreHoursTypesMap)
      .reduce((acc, val) => acc.concat(val), [])
      .filter((x, i, arr) => arr.findIndex((y) => y?.hoursTypeId === x?.hoursTypeId) === i);
    this.hoursItems.push(...distinct);
  }

  onChangeHoursItem(): void {
    this.updateHoursColumns();
  }

  updateHoursColumnFromXLSX(fields: string[]): any {
    // field名からどの営業時間の詳細を設定しているかを特定
    const weekRegex = /.曜日の/;
    const items = fields
      .map((f) => {
        if (f.match(weekRegex) == null) {
          return;
        }
        const s = f.replace(weekRegex, "");
        if (s !== "営業時間") {
          return s;
        }
      })
      .filter((e) => e);
    const selected = this.hoursItems.filter((i) => items.includes(i.localizedDisplayName));
    // 重複を排除して項目を選択状態に
    this.selectedHoursItems.splice(0, this.selectedHoursItems.length);
    this.selectedHoursItems.push(...Array.from(new Set([...selected])));
    this.updateHoursColumns();
  }
}
export default toNative(StoreListEdit);
</script>

<style lang="scss" scoped>
.area-selector-container {
  margin-bottom: 30px;
  display: flex;
  flex-basis: calc(100% - 110px) 100px;
  align-items: flex-end;

  .area-selector {
    margin-top: 0;
    padding-top: 0;
    max-width: 600px;
  }
}

.searchbox {
  margin-top: -2px;
  margin-left: 10px;

  // 虫眼鏡
  .v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width)
    .v-input__prepend-inner
    .v-input__icon
    > .v-icon {
    margin-top: -2px;
  }

  // 「検索キーワード」placeholder
  .v-text-field .v-label {
    top: 1px;
  }

  // 検索キーワード
  .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding-top: 0;
    padding-bottom: 5px;
  }

  // ✖︎ボタン
  .v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width)
    .v-input__append-inner
    .v-input__icon
    > .v-icon {
    margin-top: 0;
  }
}

.xlsx-import-button {
  margin-left: 10px;

  &.disabled {
    cursor: no-drop;
    opacity: 0.5;
  }
}

.button {
  color: inherit;

  &.is-light {
    background-color: whitesmoke;
    border-color: transparent;
  }
}

:deep() {
  // 「グループを選択してください」
  .v-text-field .v-label {
    top: 17px;
  }

  // ▼
  .v-text-field .v-input__append-inner {
    margin-top: 15px;
  }

  // グループ検索入力テキスト
  .v-select.v-select--chips input {
    margin-top: 9px;
  }

  table.htCore {
    font-size: 0.85em;

    .cornerHeader::before {
      content: "店舗ID";
    }
  }

  // テーブル最下段に余白を設ける
  .ht_master,
  .ht_clone_left {
    table.htCore {
      padding-bottom: 20em;
    }
  }

  // 変更があった行マーク
  .hottable .rowheader {
    color: #000;
    background-color: #f0f0f0;

    &.dirtyrow::after {
      content: " *";
    }
  }

  .hottable .handsontable {
    // セレクトボックスの選択肢のほうを上に表示
    z-index: 8;
  }

  .attrTable {
    // 店舗属性テーブルのヘッダを縦書きにする
    table.htCore th > div.relative {
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
    }

    // ソートの向きを示す矢印を上に持ってくる
    .handsontable span.colHeader.columnSorting::before {
      top: 0;
    }

    .rowheader {
      width: 5em;
    }
  }

  // 折返しなし、行の高さが変わるのを防ぐ
  table.htCore td.nowrap {
    white-space: nowrap;
  }

  button.tab {
    border-radius: 10px 10px 0 0;
  }

  div.container.tab-and-buttons {
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .warning-message {
    color: red;
    font-weight: bold;
    padding-right: 30px;
  }

  .htSelectEditor {
    background-color: white;
  }

  .tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  .store-tabs {
    display: flex;

    button {
      display: block;
    }
  }

  .reflection-button {
    margin-left: 20px;
  }

  .xlsx-button {
    margin-top: 5px;

    &:last-of-type {
      margin-right: 10px;
    }
  }

  .hours-select {
    margin-left: 10px;

    & > .v-input {
      margin-top: -2px;
    }
  }

  .found-items {
    margin-left: 10px;
    white-space: nowrap;
  }

  .searchbox {
    margin-top: -2px;
  }

  .store-tabs {
    .only-error-rows {
      padding: 0 !important;
      margin: 1px 0 0 0 !important;
      min-width: 150px;

      .theme--light {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  .more-hours-types-select {
    min-width: 200px;
    max-width: 340px !important;
    // タブとテーブルをくっつけるための措置
    margin-bottom: -1rem !important;
  }
}
</style>
