<template>
  <div>
    <div class="register" style="margin-bottom: 30px">
      <div class="header-title">Y!プレイス画像登録履歴</div>
      <button class="button is-primary margin-right" @click="goBack">戻る</button>
    </div>
    <div
      v-if="!canShowAllowedStoresOnly"
      style="display: flex; flex-direction: row; align-items: center"
    >
      <div>
        <v-select
          v-model="selectedAreas"
          :items="areaItems"
          item-title="title"
          item-value="id"
          label="グループ"
          multiple
          chips
          hint="履歴を表示するグループを選択"
          persistent-hint
          style="margin-right: 20px; width: 500px"
        />
      </div>
      <div>
        <button class="button is-primary margin-right" @click="selectAreas">表示</button>
      </div>
    </div>
    <div>
      <div v-if="histories.length === 0 && !loading">条件に該当する画像登録履歴はありません</div>
      <o-table
        v-else
        v-model:current-page="currentPage"
        :data="histories"
        :total="total"
        :per-page="perPage"
        :loading="loading"
        paginated
        backend-pagination
        @page-change="pageChange"
      >
        <o-table-column v-slot="props" label="日時">
          {{ props.row.friendlyCreatedAt }}
        </o-table-column>
        <o-table-column v-slot="props" label="更新者">{{ props.row.createdBy }}</o-table-column>
        <o-table-column v-slot="props" label="店舗名">{{ props.row.storeName }}</o-table-column>
        <!-- <o-table-column label="サムネイルURL">
            <a :href="props.row.thumbnailUrl">{{ props.row.thumbnailUrl }}</a>
          </o-table-column> -->
        <o-table-column v-slot="props" label="処理内容">
          {{ props.row.actionName }} {{ props.row.changes }}
        </o-table-column>
      </o-table>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" src="./style.scss"></style>
