<template>
  <div>
    <!-- 画像追加ダイアログ -->
    <v-dialog v-model="imageDialog.show" width="677" persistent class="uploading-dialog">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>画像追加</v-card-title>
        <v-card-text>
          <o-upload
            v-if="!imageDialog.mediaFile"
            class="upload-part"
            accept=".jpg,.jpeg,.png,.gif"
            drag-drop
            @update:model-value="loadFile"
          >
            <section class="section">
              <div class="content has-text-centered">
                <strong>
                  ここをクリックするか、
                  <br />
                  JPG/PNG/GIFをドラッグ＆ドロップして
                  <br />
                  画像登録
                </strong>
              </div>
            </section>
          </o-upload>
          <div v-else>
            <v-img contain :src="imageDialog.mediaFile.src" />
            <span>{{ imageDialog.mediaFile.file.name }}</span>
            <span v-if="imageDialog.mediaFile.width && imageDialog.mediaFile.height">
              横{{ imageDialog.mediaFile.width }} x 縦{{ imageDialog.mediaFile.height }}
            </span>
          </div>
          <p v-if="imageError !== ''" class="img-file-error">
            {{ imageError }}
          </p>
          <v-select
            v-model="imageDialog.selectedCategory"
            :items="imageDialog.categoryInfos"
            item-title="title"
            item-value="category"
            label="画像のカテゴリ"
            style="width: 80%"
          />
          <div class="store-selector">
            <auto-complete-card
              v-model="imageDialog.selectedStores"
              :items="imageDialog.storeList"
              :disabled="allSelect"
              label="追加する店舗"
              unit="店舗"
              variant="filled"
              style="width: 80%"
            />
            <v-checkbox v-model="allSelect" class="all-select" hide-details color="primary">
              <template #label>
                <span class="text-black">全ての店舗</span>
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="imageDialog.show = false">キャンセル</v-btn>
          <v-btn
            color="primary"
            :disabled="
              imageError !== '' ||
              !imageDialog.selectedCategory ||
              (allSelect === false && imageDialog.selectedStores.length === 0)
            "
            @click="addImage"
          >
            追加
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 反映中のダイアログ (プログレスバーつき) -->
    <v-dialog v-model="applyDialog.show" width="500" persistent>
      <v-card class="reflection-dialog">
        <v-card-title class="headline grey lighten-2" primary-title>変更内容を反映</v-card-title>
        <v-progress-linear v-model="applyDialog.percentage" color="light-blue"></v-progress-linear>
        <v-card-text>
          <div>{{ applyDialog.message }}</div>
          <ul
            v-if="applyDialog.errorMessages && applyDialog.errorMessages.length > 0"
            class="error-dialog"
          >
            <li v-for="(errorMes, index) in applyDialog.errorMessages" :key="index">
              <p class="error-head">{{ errorMes.message }}</p>
              <dl v-if="errorMes.action" class="error-action">
                <dt>操作</dt>
                <dd>
                  {{ errorMes.action }}
                </dd>
              </dl>
              <dl v-if="errorMes.store" class="error-store">
                <dt>店舗コード / 店舗名</dt>
                <dd>{{ errorMes.store }}</dd>
              </dl>
              <dl v-if="errorMes.status" class="error-status">
                <dt>エラーステータス</dt>
                <dd>{{ errorMes.status }}</dd>
              </dl>
              <dl v-if="errorMes.reasons" class="error-reason">
                <dt>原因</dt>
                <dd v-for="(reason, reasonIndex) in errorMes.reasons" :key="reasonIndex">
                  {{ reason }}
                </dd>
              </dl>
              <ul v-if="errorMes.solutions" class="error-solution">
                <li v-for="(solution, solIndex) in errorMes.solutions" :key="solIndex">
                  {{ solution }}
                </li>
              </ul>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            variant="text"
            :disabled="!applyDialog.button"
            @click="applyDialog.show = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- XLSXインポートダイアログ -->
    <xlsx-import-dialog
      v-if="showXlsxImportDialog"
      ref="xlsxImportDialog"
      :files="[xlsxFile]"
      :stores="selectedAreaStores"
      :media-files="mediaFiles"
      :finish="importFileDialogFinish"
      :abort-import="abortImport"
    />

    <!-- グループ選択欄 -->
    <div class="d-flex align-center mb-2">
      <auto-complete-card
        v-if="!canShowAllowedStoresOnly"
        v-model="selectedAreaIDs"
        :items="areaItems"
        :disabled="isDirty"
        label="グループを選択して下さい"
        unit="グループ"
        show-all-select
        :chip-count="6"
        hint=""
        :persistent-hint="false"
        hide-details
        single-line
        density="compact"
        :clearable="false"
        data-testid="area-selector"
        class="me-1 w-25"
      />
      <v-btn
        v-if="!canShowAllowedStoresOnly"
        color="primary"
        size="small"
        :disabled="isDirty"
        data-testid="show-groups"
        @click="selectAreas()"
      >
        表示
      </v-btn>
      <v-text-field
        v-model="searchWord"
        :disabled="isNotOperationAllowed"
        label="検索キーワード"
        variant="underlined"
        density="compact"
        single-line
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        color="primary"
        style="width: 200px; max-width: 200px"
        @keypress.enter="filterBySearchWord"
        @click:clear="
          searchWord = '';
          filterBySearchWord();
        "
      />
      <v-btn
        color="primary"
        class="filter-button mr-2"
        size="small"
        :disabled="isNotOperationAllowed"
        @click="filterBySearchWord"
      >
        絞り込み
      </v-btn>
      <v-btn
        v-if="canManage"
        class="primary mr-5"
        size="small"
        :disabled="isNotOperationAllowed || !isDirty"
        @click="selectAreas"
      >
        変更内容を破棄
      </v-btn>
      <v-btn
        class="primary"
        size="small"
        :disabled="
          featureToggle.getStatus('companies_x_gmbmedia_histories') === 'STOP' ||
          isInactiveMediaHistories
        "
        tag="router-link"
        :to="{ name: 'YpMediaHistories' }"
      >
        画像の更新履歴
      </v-btn>
    </div>
    <!-- ボタン類 -->
    <div class="d-flex align-center">
      <v-btn
        v-if="canManage"
        class="primary"
        size="small"
        :disabled="isNotOperationAllowed"
        @click="showImageDialog()"
      >
        画像追加
      </v-btn>
      <v-btn
        v-if="canManage"
        class="primary mr-5"
        size="small"
        :disabled="isNotOperationAllowed || !isDirty"
        @click="applyChanges"
      >
        反映
      </v-btn>
      <input ref="importInput" type="file" hidden @change="importFile" />
      <v-btn
        v-if="canManage"
        class="primary"
        size="small"
        :disabled="isNotOperationAllowed || isLoading > 0"
        @click="($refs.importInput as HTMLInputElement).click()"
      >
        XLSXインポート
      </v-btn>
      <v-btn
        class="primary"
        size="small"
        :disabled="isNotOperationAllowed || isLoading > 0"
        @click="exportFile"
      >
        XLSXエクスポート
      </v-btn>
      <ToolTipIcon
        :label="'「XLSXエクスポート」はすぐに出力され、画像の一括追加に使えます。「XLSX全件クスポート」は選択中の全グループ、または検索キーワードで絞り込まれた全ての店舗の画像データを読み込んでXLSXファイルを出力するため時間はかかりますが、画像の一括削除にも使えます。'"
      />
      <span v-if="selectedAreaStores.length > 0" class="ml-4">
        <span v-if="isLoading === 3">
          インポート対象読み込み中
          {{ Math.floor((numOfLoadedStores / targetStores.length) * 100) }}％完了
        </span>
        <span v-else-if="isLoading === 2">
          全件読み込み中
          {{ Math.floor((numOfLoadedStores / selectedAreaStores.length) * 100) }}％完了
        </span>
        <span v-else-if="isLoading === 1">読み込み中</span>

        全{{ displayedStores.length }}件
        <span v-if="applyDialog.show" class="upload-progression">
          未反映{{ remainingTaskCount }}件
        </span>
      </span>
    </div>
    <v-pagination
      v-model="page"
      :disabled="isLoading > 0 ? true : false"
      :length="Math.ceil(displayedStores.length / pageSize)"
      density="compact"
      size="default"
      class="mb-1"
    />
    <!-- 画像一覧表 -->
    <div v-if="displayedStores.length !== 0" style="height: calc(100vh - 230px)" class="table">
      <table class="imagetable">
        <thead>
          <tr>
            <th>店舗ID</th>
            <th style="min-width: 105px">店舗コード</th>
            <th>店舗名</th>
            <th colspan="100%">画像</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="store of displayedStores.slice((page - 1) * pageSize, page * pageSize)"
            :key="store.poiId"
          >
            <td>
              {{ store.poiId }}
            </td>
            <td>
              <div>{{ store.storeCode }}</div>
            </td>
            <td>
              <div style="width: 150px">{{ store.storeName }}</div>
            </td>
            <!-- 画像表示セル 読込中 -->
            <td v-if="store.isLoading === true">読み込み中</td>
            <!-- 画像表示セル start -->
            <td v-else-if="store.columns.length === 0">画像が登録されておりません</td>
            <td v-else class="image-columns">
              <div
                v-for="column of store.columns"
                :key="column.key"
                :class="column.manipulation === 'NONE' ? '' : 'dirty'"
              >
                <v-select
                  v-model="column.category"
                  :items="categories"
                  item-title="title"
                  item-value="category"
                  dense
                  solo
                  @update:model-value="changeCategory(column)"
                />
                <span v-if="canManage">
                  <v-checkbox
                    v-if="column.manipulation !== 'ADD_IMAGE'"
                    v-model="column.deleteCheckbox"
                    label="削除"
                    dense
                    color="primary"
                    @change="toggleDelete(store, column)"
                  ></v-checkbox>
                  <v-btn v-else @click="toggleDelete(store, column)">追加をやめる</v-btn>
                </span>
                <v-img contain max-height="200" max-width="300" :src="column.src" />
              </div>
            </td>
            <!-- 画像表示セル end -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import pLimit from "p-limit";
import type { EntitiesStore, EntitiesYahooImage } from "@/types/ls-api";
type EntitiesStores = EntitiesStore[];
import type { YpMediaCategory } from "./YpMediaColumn";
import {
  YpCategoryInfo,
  businessImageToMediaColumns,
  mediaColumnsToBusinessImage,
  YpMediaColumn,
} from "./YpMediaColumn";
import { type FeatureToggle } from "@/routes/FeatureToggle";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import wordDictionary from "@/word-dictionary";
import dayjs from "dayjs";
import XlsxImportDialog from "./YpXlsxImportDialog.vue";
import { TOAST_CRITICAL_DURATION } from "@/const";
type MediaItem = EntitiesYahooImage & { category: YpMediaCategory };
import { useSnackbar } from "@/storepinia/snackbar";
import { useIndexedDb } from "@/storepinia/idxdb";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import { api as yapi } from "@/helpers/api/yahoo";
import { MediaFile } from "@/components/root/contents/gmbmedia/MediaColumn";
import type { definitions } from "@/types/swagger";

// 反映エラーダイアログに出す内容
type ReflectionErrorMessage = {
  message: string;
  action: string;
  status: string;
  reasons?: string[];
  store: string;
  solutions?: string[];
};

export class StoreWithImages {
  poiId: number;
  storeCode: string;
  storeName: string;
  uuid: string;
  placeSeq: number;
  columns: YpMediaColumn[];
  isLoading: boolean;
  static of(store: EntitiesStore): StoreWithImages {
    const s = new StoreWithImages();
    s.poiId = store.poiID;
    s.storeCode = store.gmbStoreCode;
    s.storeName = store.name;
    s.uuid = store.yahooplace.uuid;
    s.placeSeq = store.yahooplace.placeSeq;
    s.columns = [];
    s.isLoading = null;
    return s;
  }

  toString(): string {
    return this.storeName;
  }
}

// コンポーネント定義
export default defineComponent({
  components: { XlsxImportDialog, AutoCompleteCard },
  props: {
    poiGroupId: { type: String, required: true, default: "0" },
  },
  data: () => {
    return {
      featureToggle: {} as FeatureToggle,
      imageDialog: {
        show: false,
        mediaFile: null as MediaFile,
        storeList: [] as SelectItem[], // 店舗一覧(オートコンプリート用、重複あり)
        selectedStores: [] as number[],
        categoryInfos: [] as YpCategoryInfo[], // カテゴリプルダウン用
        selectedCategory: null as string,
      },
      applyDialog: {
        show: false,
        percentage: 0,
        message: "",
        errorMessages: [] as ReflectionErrorMessage[],
        button: false,
      },
      isDirty: false,
      areaItems: [] as SelectItem[],
      getCategoryInfo: YpCategoryInfo.of,
      categories: YpCategoryInfo.values,
      stores: [] as EntitiesStores,
      selectedAreaIDs: [] as number[],
      mediaFiles: [] as MediaFile[],
      selectedAreaStores: [] as StoreWithImages[], // 店舗一覧 (絞り込み前)
      displayedStores: [] as StoreWithImages[], // 店舗一覧 (絞り込み後)
      locationMediaCache: {} as { [name: string]: MediaItem[] },
      showXlsxImportDialog: false,
      xlsxFile: null as File,
      isLoading: 0,
      // 現在のページ
      page: 1,
      // 1ページに何件表示するか
      pageSize: 10,
      // 読み込み済み店舗数
      numOfLoadedStores: 0,
      // vueインスタンスが破棄される際に店舗画像中断させる為のフラグ
      isDestroyed: false,
      // 未反映件数
      remainingTaskCount: 0,
      // 画像追加の全店舗選択チェックボックス
      allSelect: false,
      searchWord: "",
      // 追加しようとした画像が壊れているか、またはファイルサイズが上限下限を超えている場合のエラーメッセージ
      imageError: "",
      // XLSXインポート対象のストア数
      targetStores: [] as StoreWithImages[],
      // 店舗権限ユーザかどうか
      canShowAllowedStoresOnly: useIndexedDb().canShowAllowedStoresOnly,
      // 履歴画面制御
      isInactiveMediaHistories: useIndexedDb().isInactiveMediaHistories,
      // 操作権限があるかどうか
      canManage: useIndexedDb().canManageMedia,
    };
  },
  computed: {
    isNotOperationAllowed(): boolean {
      if (this.canShowAllowedStoresOnly) {
        return this.displayedStores.length === 0;
      }
      return this.selectedAreaIDs.length === 0;
    },
  },
  beforeUnmount: function () {
    this.isDestroyed = true;
  },
  async created() {
    this.featureToggle = useRoute().meta.featureToggle as FeatureToggle;
    // 店舗一覧作成
    this.stores = (useIndexedDb().stores?.stores ?? []).filter(
      (s) => s.enabled && s.yahooplace?.placeSeq
    );
    // グループプルダウン作成
    ({ areas: this.areaItems } = makeSelectItems(useIndexedDb().areaStores, this.stores, false));
    // 店舗権限の場合は全店舗を初期表示する
    if (this.canShowAllowedStoresOnly) {
      this.selectAreas();
    }
  },
  methods: {
    async selectAreas() {
      this.imageDialog.storeList = [];
      this.selectedAreaStores = [];
      this.isDirty = false;

      for (const store of this.stores) {
        const canUse =
          this.selectedAreaIDs.filter((id) => (store.areas ?? []).includes(id)).length !== 0;
        // 店舗参照権限ではエリア参照できないので全表示のみ、それ以外はエリアに紐付く店舗のみ
        if (canUse || this.canShowAllowedStoresOnly) {
          this.selectedAreaStores.push(StoreWithImages.of(store));
        }
      }
      // 検索キーワードで絞り込んだ店舗を表示する
      this.filterBySearchWord();

      // 画像を読み込む
      await this.fetchStoreImages(this.selectedAreaStores);
    },

    /** 検索キーワードで絞り込み、画像追加ダイアログの店舗プルダウンアイテムを作成する */
    filterBySearchWord() {
      // ページリセットする
      this.page = 1;
      // 検索キーワードで絞り込み
      this.displayedStores = this.selectedAreaStores.filter((s) => this.checkStoreMatching(s));

      // 画像追加ダイアログの店舗プルダウンアイテムを作成
      if (this.searchWord || this.canShowAllowedStoresOnly) {
        // 検索キーワード絞り込みしてるor店舗ユーザの場合はグループ名見出し無しの画像追加リストを生成する
        this.imageDialog.storeList = this.displayedStores.map((s) => {
          return { isHeader: false, id: s.poiId, title: s.storeName };
        });
      } else {
        // 選択したグループの店舗だけを表示する
        const areas = useIndexedDb().areaStores.filter((a) =>
          this.selectedAreaIDs.includes(a.areaID)
        );
        ({ stores: this.imageDialog.storeList } = makeSelectItems(areas, this.stores, false));
        // グループが一つだけなら、グループ行を除去する
        if (this.imageDialog.storeList.filter((s) => s.isHeader).length === 1) {
          this.imageDialog.storeList = this.imageDialog.storeList.filter((s) => !s.isHeader);
        }
      }
    },

    /** 店舗毎に yplace の place-businesses を実行して、サムネイルを表示していく */
    async fetchStoreImages(storeData: StoreWithImages[]): Promise<void> {
      try {
        // 一旦ページ内全部の店舗を「読み込み中」にする
        for (const s of storeData) {
          s.isLoading = true;
        }
        // 同じ uuid で店舗をまとめる
        const ss: { [uuid: string]: StoreWithImages[] } = {};
        for (const s of storeData) {
          if (!ss[s.uuid]) {
            ss[s.uuid] = [];
          }
          ss[s.uuid].push(s);
        }
        // 同時実行数を 5 に制限しつつ、すべての店舗のMediaItem取得を実行する
        const tasks = Object.entries(ss).map(([uuid, stores]) => {
          return async () => {
            const poiGroupId = parseInt(this.poiGroupId);
            const size = 100;
            const placeSeqs = stores.map((s) => s.placeSeq);
            const list: definitions["entities.YahooPlaceBusiness"][] = [];
            for (let i = 0; i * size < stores.length; i++) {
              const res = await yapi.listPlaceBusiness(poiGroupId, uuid, size, i, placeSeqs);
              if (this.isDestroyed === true) return; // 店舗画像の一括更新ページから離脱していたら中止する
              if (res.error) {
                useSnackbar().addSnackbarMessages({
                  text: res.error.reason,
                  color: "danger",
                  timeout: TOAST_CRITICAL_DURATION,
                });
                return;
              }
              list.push(...res.data.list);
            }
            for (const store of stores) {
              store.isLoading = false;
              this.numOfLoadedStores++;
              const x = list.find((b) => b.placeSeq === store.placeSeq);
              if (!x) continue;
              store.columns.push(...businessImageToMediaColumns(x.businessImage));
              store.isLoading = false;
            }
          };
        });
        const limit = pLimit(5);
        await Promise.all(tasks.map((fn) => limit(fn)));
      } catch (error) {
        console.log("[fetchStoreImages ERROR]", error);
      }
    },

    showImageDialog() {
      this.imageError = "";
      this.imageDialog.mediaFile = null;
      this.imageDialog.categoryInfos = [];
      this.imageDialog.selectedCategory = null;
      this.imageDialog.selectedStores = [];
      this.imageDialog.show = true;
    },
    async loadFile(file: File) {
      this.imageError = "";
      // 新しいファイルならばメディアファイル一覧に追加する
      const newmf = await MediaFile.of(file);
      if (newmf.imageError !== "") {
        // 画像破損だったらエラー表示出す
        this.imageError = newmf.imageError;
        return;
      }
      let mf = this.mediaFiles.find((mf) => mf.equals(newmf));
      if (!mf) {
        this.mediaFiles.push(newmf);
        mf = newmf;
      }
      this.imageDialog.mediaFile = mf;
      this.imageDialog.categoryInfos = YpCategoryInfo.values;
    },
    addImage() {
      this.imageDialog.show = false;
      // すべての店舗のチェックがついていた場合は、全店舗のidを用いる
      const selectedStores = this.allSelect
        ? this.selectedAreaStores.map((s) => s.poiId)
        : this.imageDialog.selectedStores;

      const ci = YpCategoryInfo.of(this.imageDialog.selectedCategory);
      for (const id of selectedStores) {
        const store = this.selectedAreaStores.find((s) => s.poiId === id);
        const newCol = YpMediaColumn.ofMediaFile(this.imageDialog.mediaFile, ci.category);
        if (ci.canDuplicate) {
          store.columns.unshift(newCol);
        } else {
          for (let i = store.columns.length - 1; 0 <= i; i--) {
            const col = store.columns[i];
            if (col.category === ci.category) {
              col.deleteCheckbox = true;
              this.toggleDelete(store, col);
            }
          }
          store.columns.unshift(newCol);
        }

        store.columns.sort((a, b) => {
          return YpCategoryInfo.compare(a.category, b.category);
        });
      }
      this.updateIsDirty();
    },
    storeCheckBoxIcon() {
      if (this.imageDialog.selectedStores.length === this.selectedAreaStores.length)
        return "mdi-close-box";
      if (this.imageDialog.selectedStores.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    filterAreaStores: (item: StoreWithImages, queryText: string, itemText: string) => {
      const storeName = item?.storeName?.toLocaleLowerCase();
      const searchText = queryText?.toLocaleLowerCase();
      return storeName?.includes(searchText);
    },
    toggleDelete(s: StoreWithImages, c: YpMediaColumn) {
      if (c.manipulation === "ADD_IMAGE") {
        // 追加した画像であれば表から削除する
        const index = s.columns.findIndex((col) => col.key === c.key);
        s.columns.splice(index, 1);
      } else {
        // 既存の画像であればカラムに変更を適用する
        c.applyDeleteCheckbox();
        // 変更されたのが重複できないタイプで、削除フラグをOFFにする操作ならば、その他の同じタイプのものを削除する
        if (YpCategoryInfo.of(c.category).canDuplicate === false && c.deleteCheckbox === false) {
          for (let i = s.columns.length - 1; 0 <= i; i--) {
            const col = s.columns[i];
            if (col.key !== c.key && col.category === c.category) {
              s.columns.splice(i, 1);
            }
          }
        }
      }
      this.updateIsDirty();
    },
    changeCategory(c: YpMediaColumn) {
      c.applyChangeCategory();
      this.updateIsDirty();
    },
    updateIsDirty() {
      for (const s of this.selectedAreaStores) {
        for (const c of s.columns) {
          if (c.manipulation !== "NONE") {
            this.isDirty = true;
            return;
          }
        }
      }
      this.isDirty = false;
    },

    async applyChanges() {
      this.applyDialog.show = true;
      this.applyDialog.percentage = 0;
      this.applyDialog.button = false;
      this.applyDialog.errorMessages.length = 0;

      const uploadErrorMessages: ReflectionErrorMessage[] = [];
      const uploadStores = this.selectedAreaStores.filter((s) => {
        return s.columns.some((c) => c.manipulation !== "NONE" || c.deleteCheckbox);
      });
      if (uploadStores.length === 0) {
        useSnackbar().addSnackbarMessages({ text: "更新対象がありません", color: "warning" });
        return;
      }
      // cover が複数ある場合はエラーとする
      for (const store of this.selectedAreaStores) {
        const coverCount = store.columns.filter((c) => c.category === "COVER").length;
        if (coverCount > 1) {
          this.applyDialog.message =
            "店舗カバー写真は1枚まで登録できます。カバー画像の選択を確認してください";
          this.applyDialog.button = true;
          return;
        }
      }
      const poiGroupId = Number(this.poiGroupId);
      // すでにアップロードした画像はそれ以上アップロードしないように cache で管理する
      const cache: { [src: string]: EntitiesYahooImage } = {};
      let count = 0;
      this.remainingTaskCount = uploadStores.length;
      for (const store of uploadStores) {
        this.applyDialog.message = `店舗ID: ${store.poiId}, 店舗名: ${store.storeName} の画像を更新中`;
        // 画像追加の場合は画像をアップロードする
        let hasError = false;
        for (const column of store.columns) {
          if (column.manipulation === "ADD_IMAGE") {
            if (!cache[column.src]) {
              const res = await yapi.postPlaceImage(poiGroupId, store.poiId, column.mediaFile.file);
              if (this.isDestroyed === true) return; // 店舗画像の一括更新ページから離脱していたら中止する
              if (res.error) {
                console.log(res);
                uploadErrorMessages.push({
                  message: "画像のアップロードに失敗しました",
                  action: "店舗ID: " + store.poiId,
                  status: res.error.reason,
                  store: store.storeName,
                });
                hasError = true;
                break;
              } else {
                cache[column.src] = res.data;
              }
            }
            column.mediaItem = { ...cache[column.src], category: column.category };
          }
        }
        // 画像アップロード時にエラー発生した場合は次の店舗へ
        if (hasError) {
          count++;
          this.applyDialog.percentage = (count / uploadStores.length) * 100;
          this.remainingTaskCount = uploadStores.length - count + uploadErrorMessages.length;
          continue;
        }
        // Yahooプレイス店舗の businessImage に反映する
        const businessImage = mediaColumnsToBusinessImage(store.columns);
        const res = await yapi.patchStore(poiGroupId, store.poiId, { businessImage });
        if (this.isDestroyed === true) return; // 店舗画像の一括更新ページから離脱していたら中止する
        if (res.error) {
          uploadErrorMessages.push({
            message: "店舗画像の更新に失敗しました",
            action: "店舗ID: " + store.poiId,
            status: res.error.errorBody?.map((e) => e.reason).join(", "),
            store: store.storeName,
          });
        } else {
          // 更新が成功したら店舗の画像一覧を更新する
          const res2 = await yapi.listPlaceBusiness(poiGroupId, store.uuid, 1, 0, [store.placeSeq]);
          store.columns = businessImageToMediaColumns(res2.data.list[0].businessImage);
        }
        // プログレスバーを進捗させる
        count++;
        this.applyDialog.percentage = (count / uploadStores.length) * 100;
        this.remainingTaskCount = uploadStores.length - count + uploadErrorMessages.length;
        await this.$nextTick();
      }
      this.updateIsDirty();
      if (uploadErrorMessages.length > 0) {
        // エラーがあった場合はエラーダイアログを出す
        this.applyDialog.message = "";
        this.applyDialog.errorMessages = uploadErrorMessages;
      } else {
        this.applyDialog.message = "完了しました";
      }
      this.applyDialog.button = true;
    },

    async exportFile() {
      const aoa = []; // Array of arrays
      // ヘッダ行を追加する
      const maxImageCount = this.selectedAreaStores.reduce((prev, curr) => {
        return Math.max(prev, curr.columns.length);
      }, 0);
      const columns: string[] = ["店舗ID", "店舗コード", "ビジネス名"];
      for (let i = 0; i < maxImageCount; i++) {
        columns.push("");
      }
      aoa.push(columns);
      // 店舗ごとの行を追加する
      for (const s of this.selectedAreaStores) {
        const arr = [];
        arr.push(s.poiId);
        arr.push(s.storeCode);
        arr.push(s.storeName);
        for (const column of s.columns) {
          if (column.manipulation === "DELETE") {
            arr.push("");
          } else if (column.mediaItem) {
            arr.push(`${column.category}: ${column.mediaItem.thumbnail}`);
          } else {
            arr.push(`${column.category}: ${column.mediaFile.file.name}`);
          }
        }
        aoa.push(arr);
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const companyName = useIndexedDb().company.name;
      const datetime = dayjs().format("YYYYMMDD");
      const filename = `${wordDictionary.service.name}-Yahooプレイス店舗画像一覧エクスポート-${companyName}-${datetime}.xlsx`;
      saveAs(new Blob([buf], { type: "application/octet-stream" }), filename);
    },
    async importFile(e) {
      const file: File = e.target.files[0];
      if (!file) {
        return;
      }
      e.target.value = "";
      this.xlsxFile = file;
      this.showXlsxImportDialog = true;
    },
    abortImport(): void {
      this.showXlsxImportDialog = false;
      this.targetStores = null;
      this.isLoading = 0;
    },
    async importFileDialogFinish(
      deleteCols: { poiId: number; key: number }[],
      deleteCancelCols: { poiId: number; key: number }[],
      changeCols: { poiId: number; key: number; category: YpMediaCategory }[],
      addCols: { poiId: number; category: string; thumbnail: string; mediaFile: MediaFile }[]
    ) {
      this.showXlsxImportDialog = false;

      // インポート内容を反映していく
      const find = (poiId: number, key: number): [StoreWithImages, YpMediaColumn] => {
        const store = this.selectedAreaStores.find((s) => s.poiId === poiId);
        if (!store) {
          return [null, null];
        }
        const col = store.columns.find((c) => c.key === key);
        return [store, col];
      };
      for (const deleteCol of deleteCols) {
        const [store, col] = find(deleteCol.poiId, deleteCol.key);
        col.deleteCheckbox = true;
        this.toggleDelete(store, col);
      }
      for (const changeCol of changeCols) {
        const [_, col] = find(changeCol.poiId, changeCol.key);
        col.category = changeCol.category;
        this.changeCategory(col);
      }
      for (const deleteCancelCol of deleteCancelCols) {
        const [store, col] = find(deleteCancelCol.poiId, deleteCancelCol.key);
        col.deleteCheckbox = false;
        this.toggleDelete(store, col);
      }
      for (const addCol of addCols) {
        await this.loadFile(addCol.mediaFile.file);
        this.imageDialog.selectedStores = [addCol.poiId];
        this.imageDialog.selectedCategory = addCol.category;
        this.addImage();
      }
      // 役目が終わったのでnullにする
      this.targetStores = null;
    },
    /** 検索キーワードにマッチするか */
    checkStoreMatching(store: StoreWithImages): boolean {
      if (!this.searchWord || this.searchWord === "") {
        return true;
      }
      this.searchWord = this.searchWord?.trim();
      const searchWord = this.searchWord?.toLowerCase();
      // 店舗名でマッチするか
      if (store.storeName?.toLowerCase().includes(searchWord)) {
        return true;
      }
      // 企業IDでマッチするか
      if (this.searchWord === store.poiId.toString()) {
        return true;
      }
      // 店舗コードでマッチするか
      if (store.storeCode?.toLowerCase().includes(searchWord)) {
        return true;
      }

      return false;
    },
  },
});

function _getGbpErrorMessage(statusCode: number): string {
  if (statusCode === 429) {
    return `アクセス試行回数が上限に達しました。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  } else if (statusCode >= 400 && statusCode < 500) {
    return `システムエラーが発生しました。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  } else if (statusCode >= 500) {
    return `ただいまGoogle側のサービスが一時的に利用できません。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  } else {
    return `システムエラーが発生しました。<br>少し時間を置いてリロードして下さい。<br>エラーコード[${statusCode}]`;
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-btn__content) {
  color: inherit;
}

button,
.history-button {
  box-shadow: none;
}

.upload-progression {
  margin-left: 1em;
}

.media-edit-header {
  display: flex;
  align-items: flex-end;
}

.area-selector {
  max-width: 600px;
  margin-right: 10px;
}

:deep(.v-select.v-select--chips .v-select__selections) {
  min-height: 0;
}

.searchbox {
  max-width: 300px;
  margin-left: 20px;
  margin-right: 10px;

  &.v-input--is-disabled {
    background-color: #d7d8d9;
  }
}

:deep(.v-text-field.v-input--dense:not(.v-text-field--outlined) input) {
  padding-bottom: 5px;
}
.filter-button {
  &.button.is-primary[disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: #d7d8d9;
    opacity: 1;
    cursor: auto;

    &:hover {
      color: rgba(0, 0, 0, 0.26);
      background-color: #d7d8d9 !important;
    }
  }
}
.head-buttons {
  margin-top: 24px;
  display: flex;
}

.change-cancel-button {
  margin-left: 10px;
  margin-right: 20px;
}

.buttons {
  margin-top: 0.5rem;

  button {
    margin-right: 10px;

    &.reflection-button {
      margin-right: 20px;
    }
  }
}

.store-selector {
  display: flex;
}

.all-select {
  margin-left: 10px;
}

.scroll {
  overflow-y: scroll;
  height: 250px;
}

.table {
  overflow-x: scroll;
  overflow-y: scroll;
  margin: 0;
}

.v-card {
  box-shadow: none;
}

.dirty {
  background: #aff;
}

.imagetable {
  border-collapse: collapse;
  border: 1px 0 1px 1px solid #ccc;

  thead th {
    position: sticky;
    top: 0;
    background-color: #f0f0f0;
    z-index: var(--z-index-loading);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;
    }
  }

  thead th:nth-child(1),
  thead th:nth-child(2),
  thead th:nth-child(3) {
    /* 行内の他のセルより手前に表示する */
    z-index: calc(var(--z-index-loading) + 1);
  }

  th:nth-child(1),
  th:nth-child(2),
  th:nth-child(3),
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3) {
    /* 横スクロール時に固定する */
    position: sticky;
    background-color: #f0f0f0;
    z-index: var(--z-index-loading);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      border: 1px solid #ccc;
    }
  }

  th:nth-child(1),
  td:nth-child(1) {
    min-width: 90px;
    max-width: 90px;
    left: 0;
  }

  th:nth-child(2),
  td:nth-child(2) {
    min-width: 120px;
    max-width: 120px;
    left: 90px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 150px;
    left: 210px;
  }

  td {
    background-color: #fff;
  }
}

.image-columns {
  display: flex;
  gap: 10px;

  & > div {
    width: 300px;
    padding: 10px;

    .theme--light.v-image {
      padding: 10px;
    }
  }
}

.v-pagination {
  margin-bottom: 20px;
  padding: 0;
}

:deep() {
  .v-pagination {
    button {
      box-shadow: none;
    }
  }
}

:deep(.v-pagination__item--active) {
  cursor: auto;
}

:deep(.reflection-dialog) {
  .error-dialog {
    height: calc(75vh - 48px);
    overflow-y: auto;

    & > li {
      border-bottom: 1px dashed;
      padding-bottom: 1em;
      margin-bottom: 1em;

      &:last-of-type {
        border-bottom-style: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .error-head {
      margin: 0 0 1em 0;
    }

    dl {
      margin-bottom: 1em;
    }

    dt {
      font-weight: bold;
    }

    .error-solution {
      padding: 0;
      margin: 0;
    }
  }

  .v-card__actions > .v-btn.v-btn {
    margin-left: auto;
  }
}

.uploading-dialog {
  line-height: 160%;
}

:deep(.upload .upload-draggable) {
  border-width: 2px;
  background-color: #eee;
  margin-top: 30px;

  strong {
    display: block;
    margin-bottom: 60px;
    line-height: 160%;
    font-size: 1.2em;
  }

  p {
    margin: 0 0 10px 0;
  }

  .section {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.acceptable-size-list {
  margin: 1em 0 0 0;

  p {
    display: inline-block;
    margin: 0 0 0.5em 0;
    padding-bottom: 2px;
    border-bottom: 4px solid #cdcdcd;
  }

  dl {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 190px auto 1fr;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  dt {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  dd {
    margin-bottom: 10px;
    margin-left: 0;

    &:nth-of-type(2n - 1) {
      &::after {
        content: "〜";
        margin: 0 0.25em;
      }
    }
  }
}

.img-file-error {
  color: red;
  margin-top: 1em;
}

.invalid-image-size {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
// Invalid aspect ratio. Got: 800x781 (1.024328), valid ratio 1.777778.
