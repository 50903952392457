<template>
  <!-- OAuth利用企業初期設定画面 -->
  <v-layout>
    <v-app-bar>
      <v-spacer></v-spacer>
      <v-btn
        size="x-small"
        variant="outlined"
        icon="fas fa-power-off"
        color="primary"
        @click="logout"
      />
    </v-app-bar>
    <v-main v-if="!loading">
      <v-card v-if="disableMessage !== ''">
        <v-card-title class="text-h2">{{ disableMessage }}</v-card-title>
      </v-card>
      <v-container v-else>
        <v-row>
          <v-col cols="12">
            <span class="text-h3">{{ message }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-card>
              <v-card-title class="text-h5">
                Googleビジネスプロフィールと{{ appName }}の接続
              </v-card-title>
              <v-card-text>
                <div v-if="!!customerGbpTokenInfo && customerGbpTokenInfo.hasCustomerGbpToken">
                  Googleビジネスプロフィールと{{ appName }}は接続済みです。
                  <br />
                  接続を行ったGoogleアカウント: {{ customerGbpTokenInfo.googleEmail }}
                  <br />
                  <span>接続した日時: {{ customerGbpTokenInfo.updateTime }}</span>
                </div>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  v-if="!customerGbpTokenInfo.hasCustomerGbpToken"
                  color="primary"
                  @click="gotoOauth()"
                >
                  {{ appName }}と接続する
                </v-btn>
                <v-btn v-else @click="gotoOauth()">接続をやり直す</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="auto">
            <v-card v-if="customerGbpTokenInfo.hasCustomerGbpToken">
              <v-card-title class="text-h5">ビジネスグループ</v-card-title>
              <v-card-text v-if="!!gmbAccount">
                <span>
                  選択したGoogleビジネスプロフィールのグループ:
                  {{ account ? account.accountName : "" }}
                </span>
                <br />
                <span>一度設定したビジネスグループは変更できません</span>
              </v-card-text>
              <v-card-actions v-else>
                <v-select
                  v-model="selectDialog.selected"
                  :items="selectDialog.accounts"
                  item-title="accountName"
                  item-value="name"
                  label="ビジネスグループ"
                />
                <v-spacer />
                <v-btn
                  color="primary"
                  :disabled="selectDialog.selected === ''"
                  @click="selectAccount()"
                >
                  ビジネスグループを設定する
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-overlay :model-value="loading" persistent class="align-center justify-center">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-layout>
</template>
<!-- <script type="application/javascript" src="https://accounts.google.com/gsi/client"></script> -->
<script lang="ts">
import { defineComponent } from "vue";
import { api as capi } from "@/helpers/api/company";
import { api as iapi } from "@/helpers/api/setup";
import { api as aapi } from "@/helpers/api/auth";
import type {
  EntitiesCustomerGbpTokenInfo,
  MybusinessaccountmanagementAccount,
} from "@/types/ls-api";
import { googleOauthClientId } from "@/const";
import { useIndexedDb } from "@/storepinia/idxdb";
import { currentTheme } from "@/components/shared/theme";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  props: {
    cancelButton: { type: String, required: false, default: "キャンセル" },
    submitButton: { type: String, required: false, default: "OK" },
  },
  data: () => {
    return {
      $route: useRoute(),
      $router: useRouter(),
      poiGroupId: 0,
      disableMessage: "",
      message: "",
      loading: true,
      useCustomerGbpToken: false,
      customerGbpTokenInfo: null as EntitiesCustomerGbpTokenInfo,
      gmbAccount: "",
      account: null as MybusinessaccountmanagementAccount,
      selectDialog: { accounts: [] as MybusinessaccountmanagementAccount[], selected: "" },
      appName: currentTheme().appName as string,
      operatingCompany: currentTheme().operatingCompany as string,
    };
  },
  computed: {},
  async created() {
    this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
    this.loading = true;
    try {
      await this.load(this.poiGroupId);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async load(poiGroupId: number) {
      const company = await capi.getCompany(poiGroupId);
      this.useCustomerGbpToken = company.useCustomerGbpToken ?? false;
      this.customerGbpTokenInfo = company.customerGbpTokenInfo || {};
      this.gmbAccount = company.gmbAccount;
      // 設定可能な状態かチェック
      if (this.useCustomerGbpToken === false) {
        this.disableMessage = `オーナー提供のトークンを用いる企業ではないので、OAuthによる${this.appName}との連携はできません`;
        return;
      }
      if (4 <= useIndexedDb().user.roleLv) {
        this.disableMessage = "オーナー以上の権限でないと設定できません";
        return;
      }
      // ユーザに対する指示のメッセージを決める
      if ((company.customerGbpTokenInfo?.hasCustomerGbpToken ?? false) === false) {
        this.message = `Googleビジネスプロフィールと${this.appName}を接続してください`;
      } else if (!company.gmbAccount) {
        this.message = "ビジネスグループを選択してください";
      } else {
        this.message = `お疲れさまでした。設定完了です。${this.operatingCompany}担当者にご連絡ください。`;
      }
      if (company.customerGbpTokenInfo?.hasCustomerGbpToken) {
        const accs = await iapi.getAccounts(this.poiGroupId);
        if (company.gmbAccount) {
          this.account = accs[0];
        } else {
          this.selectDialog.accounts = [{ name: "", accountName: "選択してください" }, ...accs];
        }
      }
    },
    async logout() {
      try {
        this.loading = true;
        if (sessionStorage.AccessToken != null) {
          await aapi.logout(sessionStorage.AccessToken);
        }
      } catch (e) {
        console.error(e);
      } finally {
        sessionStorage.clear();
        window.location.hash = "#/login";
        this.loading = false;
      }
    },
    async gotoOauth() {
      const url = "https://accounts.google.com/o/oauth2/v2/auth";
      const params = {
        scope: [
          "https://www.googleapis.com/auth/business.manage",
          "https://www.googleapis.com/auth/userinfo.email",
        ].join(" "),
        access_type: "offline",
        include_granted_scopes: "true",
        redirect_uri: `${import.meta.env.VITE_APP_API_BASE}v1/setup/oauth2/callback/google`,
        state: `${this.poiGroupId} ${location.href} ${sessionStorage.IdToken}`,
        response_type: "code",
        client_id: googleOauthClientId,
        prompt: "consent",
      };
      const oauthurl = url + "?" + new URLSearchParams(params).toString();
      location.href = oauthurl;
    },
    async selectAccount() {
      if (this.selectDialog.selected === "") {
        return;
      }
      this.loading = true;
      try {
        await iapi.setGmbAccount(this.poiGroupId, this.selectDialog.selected);
        await this.load(this.poiGroupId);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
* {
  text-transform: none !important;
}
</style>
