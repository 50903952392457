<template>
  <div>
    <v-card>
      <v-card-text><p class="text-h5">Facebook/Instagram 連携設定</p></v-card-text>
    </v-card>
    <v-card class="mt-3 p-3">
      <p class="text-h6 mb-2">{{ serviceName }}と Facebook/Instagram の接続の管理</p>
      <p class="text-body-1">接続作成者</p>
      <div class="d-flex flex-row align-center">
        <div>
          <v-autocomplete
            v-model="uuid"
            style="min-width: 20rem"
            :items="accountPulldownItems"
            item-title="label"
            item-value="uuid"
            label="ユーザを選択してください"
            density="compact"
            single-line
            hide-details
            :disabled="!canSelectFacebookAccount"
            @update:model-value="selectFacebookAccount"
          />
        </div>
        <div v-if="selectedAccount == null" class="ml-2">まだ接続していません</div>
        <div class="d-flex flex-wrap justify-end ml-auto">
          <div v-if="isConnectedFacebook.canLogin" class="ml-2">
            <v-facebook-login
              :app-id="fbAppID"
              :login-options="fbLoginOpts"
              version="v16.0"
              @sdk-init="fbInit"
              @login="fbLogin"
              @logout="fbLogout"
            >
              <template #login>
                {{ dict.button_facebook_login }}
              </template>
              <template #logout>
                {{ dict.button_facebook_logout }}
              </template>
            </v-facebook-login>
          </div>
          <div class="ml-2">
            <o-button
              v-show="isConnectedFacebook.connected"
              variant="danger"
              @click.prevent="showConfirm('deauthorize')"
            >
              {{ dict.button_facebook_deauthorize }}
            </o-button>
          </div>
          <div class="ml-2">
            <o-button
              v-show="isConnectedFacebook.data"
              variant="warning"
              @click.prevent="showConfirm('deletion')"
            >
              {{ dict.button_facebook_deletion }}
            </o-button>
          </div>
        </div>
      </div>
      <v-dialog v-if="isConfirmModalActive" v-model="isConfirmModalActive" width="500">
        <confirm-modal-form
          v-bind="confirmModalProps"
          @fb-deauthorize="fbDeauthorize()"
          @fb-deletion="fbDeletion()"
          @cancel="isConfirmModalActive = false"
        />
      </v-dialog>
      <v-dialog v-if="isStatusModalActive" v-model="isStatusModalActive" width="500">
        <status-modal-form v-bind="statusModalProps" @cancel="isStatusModalActive = false" />
      </v-dialog>
    </v-card>

    <v-card v-if="fbPages.length > 0 && !isLoading" class="mt-3 p-3">
      <div class="d-flex flex-row">
        <p class="text-h6 mb-2 me-auto">{{ serviceName }}の店舗とFacebookページの紐づけの管理</p>
        <button class="button is-primary" @click="updateStores()">反映する</button>
      </div>
      <p>
        「{{ serviceName }}の店舗一覧」で行を選択して「{{
          dict.col_citation_facebook
        }}ページ一覧」から紐づけるページを選択してください。
      </p>
      <div class="d-flex flex-row mt-3">
        <div class="border w-66 p-2">
          <div class="d-flex flex-row">
            <p class="text-h6 mr-auto">{{ serviceName }}の店舗一覧</p>
            <o-checkbox v-model="showUnlinkedOnly">紐付け未設定の店舗だけ表示</o-checkbox>
          </div>
          <o-table
            v-model:selected="selected"
            :data="storesToShow"
            paginated
            :per-page="100"
            focusable
            responsive
            sticky-header
            style="font-size: 14px"
          >
            <o-table-column field="name" searchable class="text-column">
              <template #header>店舗名</template>
              <template #default="props">{{ props.row.name }}</template>
            </o-table-column>
            <o-table-column field="fbPageID" searchable class="text-column">
              <template #header>Facebookページ</template>
              <template #default="props">{{ joinNameAndID(props.row.fbPageID) }}</template>
            </o-table-column>
            <o-table-column field="fbPageSync" class="text-column">
              <template #header>GBPと同期</template>
              <template #default="props">
                <o-switch
                  v-if="props.row.fbPageID"
                  v-model="props.row.fbPageSync"
                  @change="changeFbPageSync(props.row)"
                >
                  {{ props.row.fbPageSync ? "有効" : "無効" }}
                </o-switch>
              </template>
            </o-table-column>
            <o-table-column field="delete" label centered>
              <template #header>紐付け解除</template>
              <template #default="props">
                <button v-show="props.row.fbPageID" @click="dischargeFBPage(props.row.poiID)">
                  <o-icon pack="far" icon="trash-alt" />
                </button>
              </template>
            </o-table-column>
          </o-table>
        </div>
        <div class="w-33 border ml-3 p-2">
          <p class="text-h6">{{ dict.col_citation_facebook }}ページ一覧</p>
          <p v-if="Object.keys(selected).length !== 0" class="my-2">
            下記の {{ getAccountName(uuid) }} の管理するページから選択してください
          </p>
          <v-autocomplete
            v-model="selectedFBPage"
            item-value="id"
            :items="selectData"
            label="店舗を選択してください"
            item-title="displayName"
            :disabled="Object.keys(selected).length === 0"
          />
        </div>
      </div>
    </v-card>
    <div v-else>
      <p v-if="canSelectFacebookAccount" style="color: red">
        連携を実施するには{{
          dict.col_citation_facebook
        }}アプリにログインするかユーザを選択してください。
      </p>
      <p v-else style="color: red">
        連携を実施するには{{ dict.col_citation_facebook }}アプリにログインしてください
      </p>
    </div>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import { useSnackbar } from "@/storepinia/snackbar";
import { requiredAuth } from "@/helpers";
import type { FacebookSDK } from "@/types/Facebook";
import VFacebookLogin from "vue-facebook-login-component-next";
import { getOperationLogParams } from "@/routes/operation-log";
import wordDictionary from "@/word-dictionary";
import { fbAppID, fbScope, igScope } from "@/const";
import type {
  DomainFacebookDeletionResponse,
  StorageResponse,
  EntitiesFacebookAccount,
  EntitiesFBPagesResponse,
  EntitiesFBPage,
  EntitiesStore,
  EntitiesUsersResponse,
  EntitiesUser,
} from "@/types/ls-api";
import { Prop } from "vue-facing-decorator";
import { Watch } from "vue-facing-decorator";
import type { AxiosError } from "axios";
import dayjs from "dayjs";
import { getter, action } from "@/storepinia/idxdb";
import ConfirmModalForm from "./ConfirmModalForm.vue";
import StatusModalForm from "./StatusModalForm.vue";
import { useRoute, useRouter } from "vue-router";

export interface SelectData {
  id: string;
  displayName: string;
  assigned: boolean;
  fbPageToken: string;
}

export interface PoiToUpdate {
  poiID: number;
  fbPageID: string;
  fbLinkageUserID: string;
  fbPageToken: string;
  fbPageSync: boolean;
}
type fbAction = "deauthorize" | "deletion";

interface AccountPulldownItem {
  uuid: string;
  label: string;
}

/** nameでもフィルタリングヒットさせる用 */
interface EntitiesStorePlus extends EntitiesStore {
  fbPageIDandName?: string;
}

@Component({
  components: {
    VFacebookLogin,
    ConfirmModalForm,
    StatusModalForm,
  },
})
class CitationSettings extends Vue {
  @Prop({ type: Number }) poiGroupID: number;

  $route = useRoute();
  $router = useRouter();
  company = getter().company;
  user = getter().user;
  stores = getter().stores;
  isComUser = getter().isComUser;
  facebookEnabled = getter().facebookEnabled;
  instagramEnabled = getter().instagramEnabled;
  canSelectFacebookAccount = getter().canSelectFacebookAccount;
  setStores = action().setStores;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  isLoading: boolean = false;
  storesWithCitationOption: EntitiesStore[] = [];
  storesToShow: EntitiesStorePlus[] = [];
  dict = wordDictionary.company_form;
  serviceName = wordDictionary.service.fullName;
  fbSDK: FacebookSDK["FB"];
  fbAppID: string = fbAppID;
  accountPulldownItems: AccountPulldownItem[] = [];
  uuid: string | null = null;
  fbAccounts: EntitiesFacebookAccount[] = [];
  selectedAccount: EntitiesFacebookAccount | null = null;
  fbPages: EntitiesFBPage[] = [];
  selectData: SelectData[] = [];
  selected: EntitiesStore = {};
  isConnectedFacebook = {
    option: false,
    canLogin: true,
    connected: false,
    data: false,
  };
  isConfirmModalActive: boolean = false;
  confirmModalProps = {};
  isStatusModalActive: boolean = false;
  statusModalProps = {};
  selectedFBPage: string = "";
  updates: PoiToUpdate[] = [];
  showUnlinkedOnly: boolean = false;

  async created(): Promise<void> {
    this.isLoading = true;
    await this.getFacebookAccounts();
    this.selectedAccount = this.fbAccounts?.find((a) => a.uuid === this.user.uuID) || null;
    this.filterStoresWithCitationOption();
    this.getIsConnectedFacebook();
    if (this.isConnectedFacebook.connected) {
      await this.getFbPages();
    }
    this.storesToShow = this.storesWithCitationOption;
    // pageIDとnameでフィルタリングヒットする様にする
    for (const store of this.storesToShow) {
      store.fbPageIDandName = this.joinNameAndID(store.fbPageID);
    }

    this.isLoading = false;
  }

  filterStoresWithCitationOption(): void {
    this.storesWithCitationOption = this.stores.stores
      ?.filter((s) => s.options?.includes(wordDictionary.stores.options.citation) && s.enabled)
      .map((s) => JSON.parse(JSON.stringify(s)) as EntitiesStore);
  }

  async selectFacebookAccount(): Promise<void> {
    try {
      this.isLoading = true;
      this.selectedAccount = this.fbAccounts?.find((a) => a.uuid === this.uuid) || null;
      // 紐付けが途中だったらキャンセルする
      this.selected = {};
      this.updates = [];
      this.selectedFBPage = "";
      this.filterStoresWithCitationOption();
      this.storesToShow = this.storesWithCitationOption;

      this.getIsConnectedFacebook();
      await this.getFbPages();
      await this.createFBpagesSelectData();
    } finally {
      this.isLoading = false;
    }
  }

  async getFbPages(): Promise<void> {
    if (this.selectedAccount?.uuid == null || this.selectedAccount.userAccessToken == "") {
      this.fbPages = [];
      return;
    }
    await requiredAuth<EntitiesFBPagesResponse>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}/fbPages`,
      { uuid: this.selectedAccount.uuid }
    )
      .then((res) => {
        if (res.data.fbPages === null) {
          console.error("Facebookページの一覧取得でnullが返ってきた（権限不足の可能性あり）");
          this.addSnackbarMessages({
            text: "アクセストークンが無効になっている可能性があります<br />選択ユーザにFacebookログインから再度連携してもらうよう依頼してください",
            color: "danger",
          });
          return;
        }
        this.fbPages.splice(0, this.fbPages.length);
        this.fbPages.push(...res.data.fbPages);
      })
      .catch((e) => {
        this.fbPages.splice(0, this.fbPages.length);
        console.error("Facebookページの一覧取得に失敗: ", e);
        if ((e as AxiosError).response?.status === 500) {
          this.addSnackbarMessages({
            text: "アクセストークンが無効になっている可能性があります<br />選択ユーザにFacebookログインから再度連携してもらうよう依頼してください",
            color: "danger",
          });
        }
      });
    await this.createFBpagesSelectData();
  }

  async createFBpagesSelectData(): Promise<void> {
    const data = this.fbPages.map((x) => {
      for (const store of this.storesWithCitationOption) {
        if (x.id == store.fbPageID) {
          return;
        }
      }
      return {
        id: x.id,
        displayName: this.joinNameAndID(x.id),
        fbPageToken: x.access_token,
      } as SelectData;
    });
    this.selectData = data.filter((x) => x != null);
  }

  async updateStores(): Promise<void> {
    this.isLoading = true;
    if (this.updates.length === 0) {
      this.addSnackbarMessages({
        text: "紐付けの設定が変更されていません",
        color: "warning",
      });
      this.isLoading = false;
      return;
    }
    for (const update of this.updates) {
      try {
        const param: EntitiesStore = {
          fbPageID: update.fbPageID,
          // 現在表示しているアカウントのuuidを入れる
          fbLinkageUserID: update.fbLinkageUserID,
          fbPageAccessToken: update.fbPageToken,
          // 紐付け時のみfbPageAccessTokenUpdateを更新する（厳密にはfbPageSyncのみ変更したときは更新すべきでない）
          fbPageAccessTokenUpdate: update.fbPageID === "" ? "" : dayjs().format("YYYY-MM-DD"),
          fbPageSync: update.fbPageSync,
        };
        const response = await requiredAuth<EntitiesStore>(
          "put",
          `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.company.poiGroupID}/stores/${
            update.poiID
          }/citation`,
          getOperationLogParams(this.$route, "page-linkage"),
          param
        );
        if (response == null || response.data == null) {
          throw Error("権限不足です");
        }
        this.$router.push({ name: "CitationSettings" });
      } catch (e) {
        console.log(e);
        throw Error("サイテーション設定の更新に失敗しました。");
      } finally {
        this.setStores(this.company.poiGroupID);
        this.isLoading = false;
      }
    }

    this.addSnackbarMessages({
      text: "サイテーション設定を更新しました",
      color: "success",
    });
  }

  get fbLoginOpts(): FacebookSDK["loginOptions"] {
    // 企業のサイテーションオプション設定によってアクセス許可のリストを作成
    var allowedList: string[] = [];
    if (this.facebookEnabled) {
      allowedList.push(...fbScope);
    }
    if (this.instagramEnabled) {
      allowedList.push(...igScope);
    }
    // 重複を除いて文字列に
    const scope = Array.from(new Set(allowedList)).join(",");
    // Facebookログインのオプション設定を返す
    return {
      scope: scope, // アクセス許可
      return_scopes: true, // 許可されたアクセス許可のリストを返す
      enable_profile_selector: true, // 1以上のページを許可してもらう
    };
  }

  // SDKの読み込み(Facebook APIを叩くのに必要)
  fbInit(sdk: FacebookSDK): void {
    this.fbSDK = sdk.FB;
  }

  // 過去にFacebook or Instagramと連携済みかを取得する
  getIsConnectedFacebook(): void {
    // オプションのパラメータと別に持っていないと画面の制御ができないのでここで定義を持つ
    this.isConnectedFacebook.option = this.facebookEnabled ? true : this.instagramEnabled;
    // 本人ではなかったらログインボタンは表示しない
    this.isConnectedFacebook.canLogin =
      this.selectedAccount == null || this.user.uuID === this.selectedAccount?.uuid;
    // アクセストークンの有無で連携済みかデータ削除かのフラグを持たせる
    this.isConnectedFacebook.connected =
      this.selectedAccount != null && this.selectedAccount?.userAccessToken != null;
    this.isConnectedFacebook.data = this.selectedAccount != null;
  }

  async getFacebookAccounts() {
    const [fbAccounts, users] = await Promise.all([
      this.fetchFacebookAccounts(),
      this.fetchUsers(this.company.poiGroupID),
    ]);

    // 管理者用のuser情報を取得
    if (this.isComUser) {
      const adminUsers = await this.fetchUsers(0);
      users.push(...adminUsers);
    }
    // プルダウンに表示するためのデータを作成
    const items: AccountPulldownItem[] = [];
    if (!fbAccounts.some((account) => account.uuid === this.user.uuID)) {
      // 自分がfbAccountsにない場合は選択肢に追加する
      items.push({
        uuid: this.user.uuID,
        label: `${this.user.familyName ?? ""} ${this.user.firstName ?? ""}`,
      });
    }
    fbAccounts.forEach((account) => {
      const user = users.find((user) => user.uuID === account.uuid);
      if (user) {
        items.push({
          uuid: account.uuid,
          label: `${user.familyName ?? ""} ${user.firstName ?? ""}`,
        });
      }
    });
    items.find((item) => item.uuid === this.user.uuID).label += " (自分)";
    this.accountPulldownItems = items;
    this.uuid = this.user.uuID;
    this.fbAccounts = fbAccounts;
    this.selectedAccount = this.fbAccounts?.find((a) => a.uuid === this.user.uuID);
  }

  private async fetchFacebookAccounts(): Promise<EntitiesFacebookAccount[]> {
    const response = await requiredAuth<EntitiesFacebookAccount[]>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
        this.company.poiGroupID
      }/facebook/accounts`
    );
    return response?.data || [];
  }

  private async fetchUsers(poiGroupID: number): Promise<EntitiesUser[]> {
    if (!this.canSelectFacebookAccount) {
      // アカウント選択の権限がないユーザは自分のみを返す
      return [this.user];
    }
    const response = await requiredAuth<EntitiesUsersResponse>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupID}/users`
    );
    return response?.data?.users || [];
  }
  getAccountName(uuid: string): string {
    if (!uuid) return "-";
    const item = this.accountPulldownItems.find((item) => item.uuid === uuid);
    return item.label;
  }

  // Facebookログイン後の処理
  async fbLogin(response: FacebookSDK["statusResponse"]): Promise<void> {
    // ログインをキャンセルした場合は処理をしない
    if (response?.authResponse == null) {
      return;
    }

    // 取得したアカウント情報(トークン等)をAPI経由で保存する
    if (response?.status === "connected") {
      this.isLoading = true;
      const req: EntitiesFacebookAccount = {
        poiGroupID: this.company.poiGroupID,
        uuid: this.user.uuID,
        userID: response?.authResponse?.userID,
        userAccessToken: response?.authResponse.accessToken,
      };
      await requiredAuth<EntitiesFacebookAccount>(
        "put",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
          this.company.poiGroupID
        }/facebook/authorize`,
        getOperationLogParams(this.$route, "put"),
        req
      )
        .then(async () => {
          // 連携確認フラグの更新とFacebookページ一覧取得
          await this.getFacebookAccounts();
          this.selectedAccount = this.fbAccounts?.find((a) => a.uuid === this.user.uuID);
          this.getIsConnectedFacebook();
          await this.getFbPages();
        })
        .catch((ex) => {
          console.error(ex);
          this.addSnackbarMessages({
            text: "Facebook連携エラー",
            color: "danger",
          });
        });
      await this.createFBpagesSelectData();
      this.isLoading = false;
    }
  }

  // Facebookログアウト後の処理
  fbLogout(): void {
    // 処理自体はFacebookコンポーネント側でやってくれるので特にtoSTORE側で何かをする必要は無い
    // あくまでFacebookアプリからのログアウトであり、引き続きFacebookにはログインしている状態を維持している
    // https://developers.facebook.com/docs/reference/javascript/FB.logout?locale=ja_JP
    this.addSnackbarMessages({
      text: "Facebookアプリからログアウトしました",
      color: "success",
    });
  }

  // Facebook解除関連のモーダルを出すための関数
  showConfirm(type: fbAction): void {
    var title: string;
    var message: string;
    if (type == "deauthorize") {
      title = `${wordDictionary.service.fullName}Facebookアプリとの連携を解除しますがよろしいですか？`;
      message = `Facebookアプリの仕様上、同一のFacebookアカウントで連携を行った${wordDictionary.service.fullName}の他のユーザでも関連機能をご使用いただけなくなります。`;
    } else if (type == "deletion") {
      title =
        `${wordDictionary.service.fullName}に保存されている ${this.getAccountName(this.uuid)} ` +
        `のFacebook関連のデータを全て削除しますがよろしいですか？`;
    }
    this.confirmModalProps = {
      propTitle: title,
      propMessage: message,
      propType: type,
    };
    this.isConfirmModalActive = true;
    return;
  }

  // Facebookアプリ連携解除
  async fbDeauthorize(): Promise<void> {
    this.isConfirmModalActive = false;
    this.isLoading = true;

    await requiredAuth<StorageResponse>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
        this.company.poiGroupID
      }/facebook/deauthorize`,
      getOperationLogParams(this.$route, "deauthorize"),
      {
        userID: this.selectedAccount.userID,
      }
    )
      .then(() => {
        // Lambda側で解除処理をinvokeしているのでタイムラグがある模様、一旦手動で書き換える
        this.isConnectedFacebook.connected = false;
        this.fbAccounts.splice(this.fbAccounts.indexOf(this.selectedAccount), 1);
        this.fbPages = [];
      })
      .catch((ex) => {
        console.error(ex);
        this.addSnackbarMessages({
          text: "Facebook連携解除エラー",
          color: "danger",
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  // Facebookデータ削除処理
  async fbDeletion(): Promise<void> {
    this.isConfirmModalActive = false;
    this.isLoading = true;
    await requiredAuth<DomainFacebookDeletionResponse>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
        this.company.poiGroupID
      }/facebook/deletion`,
      getOperationLogParams(this.$route, "delete"),
      {
        poiGroupID: this.selectedAccount.poiGroupID,
        uuid: this.selectedAccount.uuid,
      }
    )
      .then(async (res) => {
        const code: string = res?.data?.confirmation_code || "";
        // Lambda側で削除処理をinvokeしているのでタイムラグがある模様、一旦手動で書き換える
        this.isConnectedFacebook.data = false;
        this.isLoading = false;
        if (code != "") {
          // ステータス確認のモーダル表示
          this.statusModalProps = { propCode: code };
          this.isStatusModalActive = true;
        } else {
          throw new Error("コード生成失敗");
        }
      })
      .catch((ex) => {
        this.isLoading = false;
        console.log(ex);
        this.addSnackbarMessages({
          text: "Facebookデータ削除エラー",
          color: "danger",
        });
      });
    return;
  }

  joinNameAndID(fbPage: string): string {
    const fbRecord = this.fbPages.find((x) => x.id === fbPage);
    if (fbRecord == null) {
      return;
    }
    return `${fbRecord.name}（${fbRecord.id}）`;
  }

  @Watch("selectedFBPage")
  async assignFBPageToStore(): Promise<void> {
    if (this.selectedFBPage == null || this.selectedFBPage == "") {
      return;
    }
    const i = this.storesWithCitationOption.findIndex((x) => x.poiID == this.selected.poiID);
    this.storesWithCitationOption[i]["fbPageID"] = this.selectedFBPage;
    const d = this.selectData.find((x) => x.id == this.selectedFBPage);
    updateTargets(
      this.selectedFBPage,
      this.selectedAccount.uuid,
      this.updates,
      this.selected.poiID,
      d.fbPageToken,
      false
    );
    await this.createFBpagesSelectData();
  }

  async dischargeFBPage(poiID: number): Promise<void> {
    const i = this.storesWithCitationOption.findIndex((x) => x.poiID == poiID);
    this.storesWithCitationOption[i]["fbPageID"] = "";
    updateTargets("", "", this.updates, poiID, "", false);
    await this.createFBpagesSelectData();
  }

  changeFbPageSync(row: EntitiesStore): void {
    updateTargets(
      row.fbPageID,
      this.selectedAccount.uuid,
      this.updates,
      row.poiID,
      row.fbPageAccessToken,
      row.fbPageSync
    );
  }

  @Watch("showUnlinkedOnly")
  async filterTable(): Promise<void> {
    const copy = this.storesWithCitationOption.slice(0, this.storesWithCitationOption.length);
    if (this.showUnlinkedOnly) {
      this.storesToShow = copy.filter((x) => x.fbPageID === "" || x.fbPageID == null);
    } else {
      this.storesToShow = copy;
    }
  }
}
export default toNative(CitationSettings);

// 更新対象poiIDリストを更新
function updateTargets(
  fbPage: string,
  fbLinkageUserID: string,
  updates: PoiToUpdate[],
  poiID: number,
  pageToken: string,
  fbPageSync: boolean
) {
  const i = updates.findIndex((x) => x.poiID === poiID);
  if (updates.length === 0 || i === -1) {
    updates.push({
      poiID: poiID,
      fbLinkageUserID: fbLinkageUserID,
      fbPageID: fbPage,
      fbPageToken: pageToken,
      fbPageSync: fbPageSync,
    });
    return;
  }
  updates[i].fbPageID = fbPage;
  updates[i].fbLinkageUserID = fbLinkageUserID;
  updates[i].fbPageToken = pageToken;
  updates[i].fbPageSync = fbPageSync;
}
</script>

<style lang="scss" scoped>
@use "../style.scss" as style;

.text-column {
  vertical-align: middle;
}

:deep(.v-facebook-login) {
  display: flex;
  cursor: default;
  color: #fff;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  box-sizing: border-box;
  justify-content: center;
  border: 1px solid hsla(0, 0%, 100%, 0.05);
  background-color: #3c57a4;
  .logo {
    opacity: 0.925;
    margin-right: 1.25ch;
  }
}
</style>
