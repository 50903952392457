<template>
  <div>
    <v-menu offset-y nudge-width="200">
      <template #activator="{ props: menuProps }">
        <v-tooltip bottom transition="fade-transition">
          <template #activator="{ props: tooltipProps }">
            <v-badge :model-value="hasData" color="#CE0E0B" icon="fas fa-exclamation">
              <!--データが無いときはボタンの色を変更する-->
              <v-btn
                v-bind="{ ...menuProps, ...tooltipProps }"
                color="primary"
                size="x-small"
                variant="outlined"
                icon="fas fa-bell"
              ></v-btn>
            </v-badge>
          </template>
          <span>通知一覧</span>
        </v-tooltip>
      </template>
      <!-- 通知がある場合 -->
      <v-list v-if="hasData" subheader class="pr-4">
        <v-list-subheader>通知一覧</v-list-subheader>
        <v-badge class="counts-badge" color="primary" inline :content="googleUpdateCount">
          <v-list-item @click="goStoreUpdates">
            <v-list-item-title class="menu-title">
              <v-icon size="small" class="mt-n1">fas fa-exclamation-triangle</v-icon>
              店舗情報の変更提案
            </v-list-item-title>
          </v-list-item>
        </v-badge>
      </v-list>
      <!-- 通知が無い場合 -->
      <v-card v-else class="pa-2">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-icon large outlined>far fa-bell</v-icon>
          </v-row>
          <v-row align="center" justify="center">
            <div class="no-data-text">現在の通知はありません</div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { requiredAuth } from "@/helpers";
import type { GoogleupdatesgetGoogleUpdatesResponse } from "@/types/ls-api";
import { apiBase } from "@/const";
import { useIndexedDb } from "@/storepinia/idxdb";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  data() {
    return {
      $route: useRoute(),
      $router: useRouter(),
      poiGroupId: 0,
      googleUpdateCount: 0,
    };
  },
  computed: {
    hasData(): boolean {
      return this.googleUpdateCount > 0;
    },
  },
  watch: {
    async "$route.path"(to, from) {
      // 対象項目を表示するor権限が無いときはチェックしない（例:店舗情報のGoogle変更提案）
      if (!to.endsWith("store-updates") && useIndexedDb().canShowStoreUpdate) {
        await this.fetchGoogleUpdateCount();
      }
    },
  },
  async created() {
    // 対象項目を表示するor権限が無いときはチェックしない（例:店舗情報のGoogle変更提案）
    if (!this.$route.path.endsWith("store-updates") && useIndexedDb().canShowStoreUpdate) {
      await this.fetchGoogleUpdateCount();
    }
  },
  methods: {
    async fetchGoogleUpdateCount() {
      this.poiGroupId = useIndexedDb().poiGroupId;
      if (this.poiGroupId === 0) {
        this.googleUpdateCount = 0;
      } else {
        const response = await requiredAuth<GoogleupdatesgetGoogleUpdatesResponse>(
          "get",
          `${apiBase}/companies/${this.poiGroupId}/googleUpdates?page=1`
        );
        this.googleUpdateCount = response?.data?.count ?? 0;
      }
    },
    goStoreUpdates() {
      this.$router.push({ path: `/companies/${this.poiGroupId}/store-updates` });
      useIndexedDb().selectReport.selectReportType = "other";
    },
  },
});
</script>
<style lang="scss" scoped>
.no-data-text {
  margin-top: 1rem;
}
.menu-title {
  color: #404040 !important;
  font-weight: bold !important;
}
.counts-badge {
  font-weight: bold !important;
}
</style>
