<template>
  <!-- ChatGPTによるクチコミ返信の下書き生成ボタン -->
  <v-btn
    :class="'button ai-button ' + (isMobile ? 'is-mobile' : 'is-primary')"
    small
    :disabled="loading"
    @click="createReplyTemplate"
  >
    <v-icon size="small" style="margin-right: 5px">fas fa-robot</v-icon>
    AI下書きβ
  </v-btn>
  <loading-dialog :show="loading" :message="waitingMessage" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { requiredAuth } from "@/helpers";
import {
  createReplyPromptSingle,
  createReplyPromptMulti,
  customaryOpening,
} from "./reply-templates";

import type { PropType } from "vue";
import type { AxiosError } from "axios";
import type { DomainGenerateReviewsReplyRequest } from "@/types/ls-api";
import LoadingDialog from "./loading-dialog.vue";
import { getter } from "@/storepinia/idxdb";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: { LoadingDialog },
  props: {
    reviews: { type: Array as PropType<Array<string>>, default: () => [] as string[] },
  },
  emits: ["copyTemplate"],
  data: () => {
    return {
      $route: useRoute(),
      $router: useRouter(),
      loading: false,
      waitingMessage:
        "選択されたクチコミからChatGPTが返信文を自動生成しています……（5秒ほどお待ち下さい）",
      isSubmitButtonDisabled: false, // demo環境の場合に、送信ボタンを無効化するためのフラグ
      isMobile: false,
    };
  },
  computed: {
    repliedReviews(): string[] {
      return this.reviews?.filter((r: string) => r?.length > 0);
    },
    replyReviewsExists(): boolean {
      return this.repliedReviews?.length > 0;
    },
  },
  watch: {},
  created(): void {
    if (import.meta.env.VITE_APP_APP_ENV == "demo") {
      // デモ環境の場合は送信ボタンを無効にする
      this.isSubmitButtonDisabled = true;
    }
    this.isMobile = getter().isMobile;
  },
  mounted(): void {},
  methods: {
    async createReplyTemplate(): Promise<void> {
      let userPrompt = "";
      let systemPrompt = "";

      if (typeof this.repliedReviews === "string" || this.repliedReviews.length === 1) {
        systemPrompt = createReplyPromptSingle;
        userPrompt = `以下のクチコミへの返信を考えてください。\n"""\n${this.repliedReviews}\n"""`;
      } else {
        systemPrompt = createReplyPromptMulti;
        userPrompt += "以下のクチコミへ共通する返信を1つだけ考えてください。";
        userPrompt += this.repliedReviews
          .map((review, index) => `\nクチコミ${index + 1}: """\n${review}\n"""`)
          .join("\n");
      }

      const poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
      const data: DomainGenerateReviewsReplyRequest = {
        poiGroupID: poiGroupId,
        messages: [
          {
            role: "system",
            content: systemPrompt,
          },
          {
            role: "user",
            content: userPrompt,
          },
        ],
      };
      this.loading = true;
      await requiredAuth<string>(
        "post",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/reviews/reply/generate`,
        null,
        data
      )
        .then((res) => {
          const generatedTemplate =
            customaryOpening + res.data ?? "返信文の生成に失敗しました（APIからのレスポンスが空）";
          this.$emit("copyTemplate", generatedTemplate);
        })
        .catch((error: AxiosError) => {
          console.error(error.response.data);
          const data = error.response.data as any;
          const errorMessage = data?.errorMessage || data?.message;
          this.$emit("copyTemplate", `返信文の生成に失敗しました（${errorMessage}）`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.ai-button {
  text-transform: unset !important;
}

.is-mobile {
  display: block;
  padding: 0 0.5rem;
  min-width: 3.5rem;
  text-align: center;
  border: solid 1px #999999;
  border-radius: 3px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #ffffff 0%, #cccccc 100%);
  color: black;
}
</style>
