import { requiredAuth } from "@/helpers";
import type {
    StorageGMBMediaHistoryGetResponse,
    StorageGMBMediaHistoryGetResponseItem,
} from "@/types/ls-api";
import { Component, Vue, toNative } from "vue-facing-decorator";
import dayjs from "dayjs";
import wordDictionary from "@/word-dictionary";
import { mediaCategories } from "@/helpers/media-categories";
import { getter } from "@/storepinia/idxdb";
import { useSnackbar } from "@/storepinia/snackbar";
import { useRoute, useRouter } from "vue-router";

@Component({})
class GMBMediaHistories extends Vue {
    $route = useRoute();
    $router = useRouter();
    dict: Record<string, unknown> = wordDictionary.reports;
    private poiGroupId: number;
    canShowAllowedStoresOnly = getter().canShowAllowedStoresOnly;

    async created(): Promise<void> {
        this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
        if (this.canShowAllowedStoresOnly) {
            this.updateHistories(this.QueryParameter());
        }
    }

    goBack(): void {
        this.$router.push({ name: "GmbMediaEdit" });
    }

    // グループ選択プルダウン
    areas = getter().areas;
    selectedAreas = [];

    get areaSelections(): { name: string; areaID: string }[] {
        return Object.keys(this.areas).map((k) => {
            return { name: this.areas[k], areaID: k };
        });
    }

    addSnackbarMessages = useSnackbar().addSnackbarMessages;
    selectAreas(): void {
        if (this.selectedAreas.length === 0) {
            this.addSnackbarMessages({
                text: "エリアを選択してください",
                color: "danger",
            });
            return;
        }
        this.updateHistories(this.QueryParameter());
    }

    get areaSelectorPlaceholder(): "グループを選択" {
        return "グループを選択";
    }

    private QueryParameter() {
        if (this.canShowAllowedStoresOnly) {
            const poiIDs = this.stores.stores.map((s) => s.poiID);
            return { poiIDs: poiIDs.join(",") };
        }
        return { areaIDs: this.selectedAreas.join(",") };
    }

    // 表
    stores = getter().stores;

    histories: StorageGMBMediaHistoryGetResponseItem[] = [];
    total: number = 0;
    perPage: number = 10;
    currentPage: number = 1;
    loading: boolean = false;

    pageChange(page: number): void {
        this.updateHistories({
            ...this.QueryParameter(),
            offset: this.perPage * (page - 1),
            limit: this.perPage,
        });
    }

    // API処理
    private async updateHistories(params: any) {
        this.loading = true;

        // フェッチ
        const resHistories = await requiredAuth<StorageGMBMediaHistoryGetResponse>(
            "get",
            `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
                this.poiGroupId
            }/gmb/media-histories`,
            params
        );

        //  表示用データを付加
        this.histories = resHistories.data.histories.map((h) => {
            const storeName = this.stores?.stores
                .filter((s) => s.poiID === Number(h.poiId))
                .map((s) => s.name)
                .reduce((t) => t);

            const nextCategoryName = this.toCategoryName(h.nextCategory);
            const prevCategoryName = this.toCategoryName(h.prevCategory);

            const act = [
                { action: "create", actionName: "作成", changes: `${nextCategoryName}` },
                {
                    action: "patch",
                    actionName: "カテゴリ変更",
                    changes: `${nextCategoryName} → ${prevCategoryName}`,
                },
                { action: "delete", actionName: "削除", changes: "" },
            ].find((a) => a.action === h.action) ?? { actionName: h.action, changes: "" };

            return {
                ...h,
                ...act,
                storeName,
                friendlyCreatedAt: dayjs(h.createdAt).format("YYYY/MM/DD HH:mm"),
            };
        });
        this.total = resHistories.data.total;

        this.loading = false;
    }

    private toCategoryName(cat: string): string {
        return mediaCategories.find((mc) => mc.name === cat)?.title || "";
    }
}
export default toNative(GMBMediaHistories);
