<template>
  <div>
    <v-card v-if="deletionStatus.status == 'FINISHED'">
      <v-card-title>
        <v-icon color="green" class="mr-1">fas fa-check</v-icon>
        {{ serviceName }}内のFacebook連携データは削除されました
      </v-card-title>
      <v-card-text>
        {{ requestedAt }}に承ったリクエストにより、{{
          serviceName
        }}内のFacebook連携データは正常に全て削除されました。
      </v-card-text>
    </v-card>
    <v-card v-if="deletionStatus.status == 'READY' || deletionStatus.status == 'IN_PROGRESS'">
      <v-card-title>{{ serviceName }}内のFacebook連携データを削除中です</v-card-title>
      <v-card-text>
        現在、{{ requestedAt }}に承ったリクエストにより、{{
          serviceName
        }}内のお客様のFacebook連携データを削除しています。通常、1分以内に処理は完了します。
      </v-card-text>
      <v-card-actions>
        <v-btn text="こちらをクリックして再確認できます" color="primary" @click="fetch" />
      </v-card-actions>
    </v-card>
    <v-card v-if="deletionStatus.status == 'FAILED'">
      <v-card-title>
        <v-icon color="#E95454" class="mr-1">fas fa-xmark</v-icon>
        {{ serviceName }}内のFacebook連携のデータ削除に失敗しました
      </v-card-title>
      <v-card-text>
        {{ requestedAt }}に承ったリクエストは正常に処理できませんでした。
        <br />
        貴社カスタマーサポートまでご連絡ください。
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import dayjs from "dayjs";
import type { EntitiesFBDeletionStatus } from "@/types/ls-api";
import { requiredAuth } from "@/helpers";
import wordDictionary from "@/word-dictionary";
import { useRoute, useRouter } from "vue-router";

@Component({})
class FacebookDeletion extends Vue {
  $route = useRoute();
  $router = useRouter();
  deletionStatus: EntitiesFBDeletionStatus = {};

  async created(): Promise<void> {
    await this.fetch();
  }

  get serviceName(): string {
    return wordDictionary.service.fullName;
  }

  get requestedAt(): string {
    return dayjs(this.deletionStatus.requestedAt).format("YYYY/M/DD HH:mm:ss");
  }

  async fetch() {
    await requiredAuth<EntitiesFBDeletionStatus>(
      "get",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
        this.$route.params.poiGroupId
      }/facebook/deletion`,
      { uuid: this.uuid() },
      null
    ).then((res) => (this.deletionStatus = res.data));
  }

  private uuid() {
    const code: string = this.$route.params.confirmationCode as string;
    const a = code.split("_");
    const sliced = a.slice(1);
    return sliced.join("");
  }
}
export default toNative(FacebookDeletion);
</script>
<style lang="scss" scoped></style>
