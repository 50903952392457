<template>
  <!-- XLSXインポートダイアログ -->
  <v-dialog v-model="show" width="600" persistent>
    <!-- エラーあり -->
    <v-card v-if="0 < errs.length">
      <v-card-title class="headline grey lighten-2" primary-title>XLSXインポート</v-card-title>
      <v-card-text>
        読み込んだファイルにエラーがあります
        <v-list>
          <v-list-item v-for="(item, index) in errs" :key="index">
            <v-list-item-title>
              {{ item.i + 1 }} 行 {{ item.j + 1 }}列 {{ item.error }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="finish([], [], [], [])">キャンセル</v-btn>
        <v-btn color="primary" :disabled="!canOk()">OK</v-btn>
      </v-card-actions>
    </v-card>

    <!-- 画像の追加なし -->
    <v-card v-else-if="0 === addCols.length">
      <v-card-title class="headline grey lighten-2" primary-title>XLSXインポート</v-card-title>
      <v-card-text v-if="loadingStatus === 2">読み込んだファイルを表に反映します</v-card-text>
      <v-card-text v-else-if="loadingStatus === 1">変更点はありませんでした</v-card-text>
      <v-card-text v-else>XLSX読込中</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="isPrepareLoading ? abortImport() : finish([], [], [], [])">キャンセル</v-btn>
        <v-btn
          color="primary"
          :disabled="!canOk()"
          @click="finish(deleteCols, deleteCancelCols, changeCols, addCols)"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 画像追加が必要 -->
    <v-card v-else>
      <v-card-title class="headline grey lighten-2" primary-title>XLSXインポート</v-card-title>
      <v-card-text>
        次のファイルを追加してください
        <v-list>
          <v-list-item v-for="(item, name) in getRequiredFiles()" :key="name">
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-img v-if="item" contain max-height="200" max-width="300" :src="item.src" />
          </v-list-item>
        </v-list>
        <o-upload
          v-if="!canOk()"
          class="upload-part"
          accept=".jpg,.jpeg,.png,.gif"
          drag-drop
          multiple
          @update:model-value="loadFiles"
        >
          <section class="section">
            <div class="content has-text-centered">
              ここをクリックするか、 JPG/PNG/GIF をドラッグ＆ドロップして画像登録
              <br />
              画像ファイルサイズは横720x縦720以上で10MB以下のものを使用してください
            </div>
          </section>
        </o-upload>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="finish([], [], [], [])">キャンセル</v-btn>
        <v-btn
          color="primary"
          :disabled="!canOk()"
          @click="finish(deleteCols, deleteCancelCols, changeCols, addCols)"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { StoreWithImages } from "./YpMediaEdit.vue";
import { MediaFile } from "../gmbmedia/MediaColumn";
import { XlsxTable } from "../gmbmedia/XlsxTable";
import { YpCategoryInfo } from "./YpMediaColumn";

// コンポーネント定義
export default defineComponent({
  props: {
    files: { type: Array as PropType<Array<File>>, required: true },
    stores: { type: Array as PropType<Array<StoreWithImages>>, required: true },
    mediaFiles: { type: Array as PropType<Array<MediaFile>>, required: true },
    finish: { type: Function, required: true },
    abortImport: { type: Function, required: true },
  },
  data: () => {
    return {
      show: true,
      deleteCols: [] as { poiId: number; key: number }[],
      deleteCancelCols: [] as { poiId: number; key: number }[],
      changeCols: [] as { poiId: number; key: number; category: string }[],
      addCols: [] as { poiId: number; category: string; thumbnail: string; mediaFile: MediaFile }[],
      errs: [] as { i: number; j: number; error: string }[],
      loadingStatus: 0,
      table: null as XlsxTable,
      isPrepareLoading: false,
    };
  },
  async created() {
    // インポートする際に予めAPIから店舗データを取得して保持しておく必要がある
    const file: File = this.files[0];
    this.table = await XlsxTable.read(file);
    this.isPrepareLoading = true;
    this.importFile();
  },
  methods: {
    /**
     * propsで渡されたfileを読み込む
     */
    async importFile() {
      this.isPrepareLoading = false;

      const stores: StoreWithImages[] = this.stores;
      const mediaFiles: MediaFile[] = this.mediaFiles;

      // 「XLSX読込中」にする
      this.loadingStatus = 0;
      // xlsx読み取り
      this.errs = this.table.validate(YpCategoryInfo.of);
      if (0 < this.errs.length) {
        return;
      }
      // 変更点を列挙する
      for (const store of stores) {
        if (!this.table.findRow(store.poiId)[0]) {
          continue;
        }
        // 削除された、削除キャンセルされた、カテゴリ変更されたカラムを調べる
        for (const col of store.columns) {
          const [cate, _thumb, _i, _j] = this.table.get(store.poiId, col.getThumbnail());
          // 削除フラグのあるカラムの場合
          if (col.manipulation === "DELETE") {
            // 削除キャンセルされたか調べる
            if (cate) {
              this.deleteCancelCols.push({ poiId: store.poiId, key: col.key });
            }
            continue;
          }
          // 削除されたか調べる
          if (!cate) {
            this.deleteCols.push({ poiId: store.poiId, key: col.key });
            continue;
          }
          // カテゴリ変更されたカラムか調べる
          if (cate !== col.category) {
            this.changeCols.push({ poiId: store.poiId, key: col.key, category: cate });
          }
        }
        // 追加されたカラムを調べる
        const addedCols = this.table.getAddedCols(store.poiId);
        for (const [cate, thumb, _i, _j] of addedCols) {
          // 既に追加済みの画像であれば追加しない
          if (store.columns.find((s) => s.getThumbnail() === thumb)) {
            continue;
          }
          this.addCols.push({
            poiId: store.poiId,
            category: cate,
            thumbnail: thumb,
            mediaFile: null,
          });
        }
      }
      for (const x of mediaFiles) {
        this.addMediaFile(x);
      }
      this.changeLoadingStatus();
    },
    changeLoadingStatus(): void {
      // 変更検出しなかったら1「変更点はありませんでした」にする
      this.loadingStatus = this.isDirty() === false ? 1 : 0;
    },
    addMediaFile(mf: MediaFile) {
      for (const x of this.addCols) {
        if (x.thumbnail === mf.file.name) {
          x.mediaFile = mf;
        }
      }
    },
    async loadFiles(files: File[]) {
      for (const file of files) {
        const newmf = await MediaFile.of(file);
        this.addMediaFile(newmf);
      }
    },
    isDirty(): boolean {
      return !(
        this.deleteCols.length === 0 &&
        this.deleteCancelCols.length === 0 &&
        this.changeCols.length === 0 &&
        this.addCols.length === 0
      );
    },
    getRequiredFiles(): { [name: string]: MediaFile } {
      const result: { [name: string]: MediaFile } = {};
      for (const c of this.addCols) {
        result[c.thumbnail] = c.mediaFile;
      }
      return result;
    },
    canOk(): boolean {
      if (0 < this.errs.length) {
        return false;
      }
      if (this.isDirty() === false) {
        return false;
      }
      if (0 < this.addCols.filter((c) => !c.mediaFile).length) {
        return false;
      }
      // 変更スタンバイになったら2「読み込んだファイルを反映します」にする
      this.loadingStatus = 2;
      this.table = null;
      return true;
    },
  },
});
</script>
<style lang="scss" scoped></style>
