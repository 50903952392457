import { routeNames, titles } from "@/const";
import { watch } from "vue";
import { Component, Vue, Prop, toNative } from "vue-facing-decorator";
import wordDictionary from "@/word-dictionary";
import { useIndexedDb, getter, action } from "@/storepinia/idxdb";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";

export interface MenuLink {
    canShow?: boolean;
    isInactive?: boolean;
    title?: string;
    routeName: string;
    routeParams?: Record<string, unknown>;
}

@Component({})
class Sidebar extends Vue {
    @Prop() domain: string;
    $route = useRoute();
    $router = useRouter();
    poiGroupId = getter().poiGroupId;
    user = getter().user;
    company = getter().company;
    isDrawerOpened = getter().isDrawerOpened;

    canShowHealthCheck = getter().canShowHealthCheck;
    canShowSummary = getter().canShowSummary;
    canShowCompany = getter().canShowCompany;
    canShowOwnCompany = getter().canShowOwnCompany;
    canShowStore = getter().canShowStore;
    canShowGroup = getter().canShowGroup;
    canShowUser = getter().canShowUser;
    canShowProfile = getter().canShowProfile;
    canShowCustomRoles = getter().canShowCustomRoles;
    canShowArea = getter().canShowArea;
    canShowStoreArea = getter().canShowStoreArea;
    canShowBeta = getter().canShowBeta;
    canShowCitationSettings = getter().canShowCitationSettings;
    canShowCitationSettingsYahoo = getter().canShowCitationSettingsYahoo;
    canShowCitationSettingsApple = getter().canShowCitationSettingsApple;
    canShowPost = getter().canShowPost;
    canShowReview = getter().canShowReview;
    canShowReport = getter().canShowReport;
    canShowStoreLocation = getter().canShowStoreLocation;
    canShowMediaLink = getter().canShowMediaLink;
    canShowAppleInfo = getter().canShowAppleInfo;
    canShowStoreUpdate = getter().canShowStoreUpdate;
    canShowMedia = getter().canShowMedia;
    canShowMenu = getter().canShowMenu;

    isMobile = getter().isMobile;
    isInactiveHealthCheck = getter().isInactiveHealthCheck;
    isInactiveSummary = getter().isInactiveSummary;
    isInactiveCompany = getter().isInactiveCompany;
    isInactiveOwnCompany = getter().isInactiveOwnCompany;
    isInactiveStore = getter().isInactiveStore;
    isInactiveGroup = getter().isInactiveGroup;
    isInactiveUser = getter().isInactiveUser;
    isInactiveProfile = getter().isInactiveProfile;
    isInactiveCustomRoles = getter().isInactiveCustomRoles;
    isInactiveArea = getter().isInactiveArea;
    isInactiveStoreArea = getter().isInactiveStoreArea;
    isInactiveBeta = getter().isInactiveBeta;
    isInactiveCitationSettings = getter().isInactiveCitationSettings;
    isInactiveCitationSettingsYahoo = getter().isInactiveCitationSettingsYahoo;
    isInactiveCitationSettingsApple = getter().isInactiveCitationSettingsApple;
    isInactivePost = getter().isInactivePost;
    isInactiveReview = getter().isInactiveReview;
    isInactiveReport = getter().isInactiveReport;
    isInactiveStoreLocation = getter().isInactiveStoreLocation;
    isInactiveMediaLink = getter().isInactiveMediaLink;
    isInactiveAppleInfo = getter().isInactiveAppleInfo;
    isInactiveStoreUpdate = getter().isInactiveStoreUpdate;
    isInactiveMedia = getter().isInactiveMedia;
    isInactiveMenu = getter().isInactiveMenu;
    isInactiveApple = getter().isInactiveApple;

    canManagePost = getter().canManagePost;
    canManageMedia = getter().canManageMedia;

    setDrawerMenu = action().setDrawerMenu;

    dict = wordDictionary.sidebar;
    dictReport = wordDictionary.reports;

    isTutorialActive: boolean = false;
    isSubMenuOpened = false;
    gearColor: "primary" | "inherit" = "inherit";

    showAllReview: boolean = false;
    isMobileDevice: boolean = false;

    // サイドバー再描画用
    updateKey = 0;
    activeItem = "";
    // PC版のメニュー一覧
    pageItemsPC: { [key: string]: string } = {
        healthCheck: "/admin/dashboard/health-check",
        summary: "report/summary",
        review: "review/review-analysis",
        reportDownload: "report-files",
        storeInfoBatch: "stores/edit",
        mediaLink: "media-link",
        appleInfo: "apple-info",
        storeUpdatesS: "store-updates",
        storeImageBatch: "gmbmedia/edit",
        ypStoreImageBatch: "ypmedia/edit",
        gmbpost: "v2posts/histories",
        foodMenu: "food-menu/groups",
        reviewReply: "review/review-reply",
    };
    // SP版のメニュー一覧（遷移先が違う）
    pageItemsSP: { [key: string]: string } = {
        gmbpost: "v2posts",
        gmbpostHistory: "v2posts/histories",
        reviewReply: "review/review-reply",
        storeImageBatch: "gmbmedia/upload",
    };

    pageItems: { [key: string]: string } = this.pageItemsPC;

    created(): void {
        // サブメニューどこからでも閉じられる様にする
        window.addEventListener("click", this.closeSubMenu);
        // ウィンドウが一定サイズ未満になったら自動でsidebar畳む
        window.addEventListener("resize", this.autoCloseSidebar, { passive: true });
    }

    mounted(): void {
        const { user } = getter();
        watch(user, () => {
            this.$forceUpdate();
        });

        if (this.isMobile) {
            this.pageItems = this.pageItemsSP;
        }

        this.updateActiveItem(this.$route.fullPath);
        onBeforeRouteUpdate((to, from, next) => {
            this.updateActiveItem(to.fullPath);
            next();
        });
        this.isMobileDevice = /iPhone|Android.+Mobile/.test(navigator.userAgent);
    }

    beforeUnmount(): void {
        window.removeEventListener("click", this.closeSubMenu);
        window.removeEventListener("resize", this.autoCloseSidebar);
    }

    get userName(): string {
        if (this.user != null) {
            return this.user.familyName + this.user.firstName;
        } else {
            return "user name";
        }
    }

    get companyName(): string {
        return this.company.name;
    }

    // サブメニュー関連
    menuLinks(): MenuLink[] {
        return [
            {
                // 企業管理
                canShow: this.canShowCompany,
                isInactive: this.isInactiveCompany,
                title: titles.companies,
                routeName: routeNames.companies,
            },
            {
                // 企業管理(オーナー向け)
                // 企業一覧を見れる管理者、パワーユーザーには個別企業への編集リンクは表示しない
                canShow: this.canShowOwnCompany && !this.canShowCompany,
                isInactive: this.isInactiveOwnCompany,
                title: titles.companies,
                routeName: routeNames.company,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // 店舗管理
                canShow: this.canShowStore,
                isInactive: this.isInactiveStore,
                title: titles.adminStores,
                routeName: routeNames.adminStores,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // ユーザー管理
                canShow: this.canShowUser,
                isInactive: this.isInactiveUser,
                title: titles.users,
                routeName: routeNames.users,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // プロファイル管理
                canShow: !this.canShowUser && this.canShowProfile,
                isInactive: this.isInactiveProfile,
                title: titles.usersEdit,
                routeName: routeNames.usersEdit,
                routeParams: { poiGroupId: this.poiGroupId, userId: this.user.uuID },
            },
            {
                // 権限管理
                canShow: this.canShowCustomRoles,
                isInactive: this.isInactiveCustomRoles,
                title: titles.customRoles,
                routeName: routeNames.customRoles,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // グループ管理
                canShow: this.canShowArea,
                isInactive: this.isInactiveArea,
                title: titles.areas,
                routeName: routeNames.areas,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // 店舗設定管理
                canShow: this.canShowGroup,
                isInactive: this.isInactiveGroup,
                title: titles.stores,
                routeName: routeNames.stores,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // サイテーション管理
                canShow: this.canShowCitationSettings,
                isInactive: this.isInactiveCitationSettings,
                title: titles.citationSettings,
                routeName: routeNames.citationSettings,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // Yahoo!プレイス 連携設定
                canShow: this.canShowCitationSettingsYahoo,
                isInactive: this.isInactiveCitationSettingsYahoo,
                title: titles.CitationSettingsYplace,
                routeName: routeNames.citationSettingsYplace,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // Apple Business Connect 連携設定
                canShow: this.canShowCitationSettingsApple,
                isInactive: this.isInactiveCitationSettingsApple,
                title: titles.CitationSettingsApple,
                routeName: routeNames.citationSettingsApple,
                routeParams: { poiGroupId: this.poiGroupId },
            },
            {
                // ベータ機能
                canShow: this.canShowBeta,
                isInactive: this.isInactiveBeta,
                title: titles.beta,
                routeName: routeNames.beta,
            },
        ]
            .filter((ml) => ml.canShow || ml.isInactive)
            .map((ml) => {
                return {
                    canShow: ml.canShow,
                    isInactive: ml.isInactive,
                    title: ml.title,
                    routeName: ml.routeName,
                    routeParams: ml.routeParams ?? {},
                };
            });
    }

    get drawer(): boolean {
        return this.isDrawerOpened;
    }

    set drawer(val: boolean) {
        this.isDrawerOpened = val;
    }

    get mini(): boolean {
        return !this.isDrawerOpened;
    }

    set mini(bool: boolean) {
        this.isDrawerOpened = bool;
    }

    beforeEnter(routeName: string, routeParams?: { [key: string]: any }) {
        useIndexedDb().selectReport.selectReportType = "other";
        this.$router.push({ name: routeName, params: routeParams });
        this.closeSubMenu();
    }
    transitionend() {
        window.dispatchEvent(new Event("resize"));
    }

    public openSubMenu(e: Event): void {
        e.preventDefault();
        this.isSubMenuOpened = !this.isSubMenuOpened;
    }

    public closeSubMenu(): void {
        this.isSubMenuOpened = false;
    }

    public autoCloseSidebar(e: Event): void {
        // 何故かハンバーガーボタンクリックでも呼び出されてしまうのでその場合はキャンセルする
        if (e.isTrusted === false) {
            return;
        }
        if (this.isDrawerOpened === false) {
            return;
        }
        const width = document.documentElement.clientWidth;
        if (width < 1264) {
            this.setDrawerMenu(false);
        }
    }

    jumpTo(target: string = null): void {
        this.routerPush(target);
        // 強制再描画してアイコンアクティブ状態更新
        this.activeItem = target;
        this.updateKey++;
        if (this.isMobile) {
            this.isDrawerOpened = false;
        }
    }

    routerPush(path: string): void {
        if (path !== this.pageItems.healthCheck) {
            path = `/companies/${this.company.poiGroupID}/` + path;
        }
        // NavigationDuplicated対策
        if (this.$route.path !== path) {
            this.$router.push({ path: path });
        }
    }

    routeMatch(target: string, fullPath: string): boolean {
        // モバイル版は対応画面数が少ないので厳しくチェック
        if (this.isMobile) {
            return fullPath.endsWith(target);
        }
        // パスあるいはクエリパラメータに含まれていたらTrue
        return fullPath.includes(target);
    }

    // PC版でアイコンの色を変える用
    isActive(target: string): "primary" | "inherit" {
        return target === this.activeItem ? "primary" : "inherit";
    }

    // SP版で項目全体を変える用
    isActiveItem(target: string): "color: var(--primary)" | "color: inherit" {
        return target === this.activeItem ? "color: var(--primary)" : "color: inherit";
    }

    // fullPath に応じて activeItem と updateKey を更新する
    updateActiveItem(fullPath: string): void {
        this.gearColor = "inherit";
        let isChanged = false;
        // メインメニュー内の該当アイコンを点灯させる
        Object.values(this.pageItems).some((page: string) => {
            const matched = this.routeMatch(page, fullPath);
            if (matched) {
                this.activeItem = page;
                isChanged = true;
                return true;
            }
        });
        // メインメニュー内のページからリンクされているページもチェック対象に加える
        if (this.routeMatch("gmbmedia/histories", fullPath)) {
            // 画像・動画の更新履歴画面
            this.activeItem = this.pageItems.storeImageBatch;
            isChanged = true;
        } else if (this.routeMatch("ypmedia/histories", fullPath)) {
            // Yahooプレイス画像の更新履歴画面
            this.activeItem = this.pageItems.ypStoreImageBatch;
            isChanged = true;
        } else if (
            this.routeMatch("v2posts", fullPath) || // V2投稿の履歴、作成、編集画面
            this.routeMatch("v2-post-", fullPath) // V2ファイル一括投稿、V2投稿テンプレート作成画面
        ) {
            this.activeItem = this.pageItems.gmbpost;
            isChanged = true;
        }
        // メインメニュー内のページ開いたのならここで終了
        if (isChanged) {
            this.updateKey++;
            return;
        }
        // サブメニューにあるページかをチェック
        const menuLinksPlus: MenuLink[] = this.menuLinks();
        // サブメニューからリンクされてるページもチェック対象に加える
        const subMenuNames: string[] = [
            "AdminCompaniesNew", // 企業登録
            "AdminCompaniesEdit", // 企業編集
            "StoreKeywords", // 検索キーワード設定
            "UsersNew", // ユーザー登録
            "UsersEdit", // ユーザー編集
            "AreasNew", // グループ登録
            "AreasEdit", // グループ編集
            "StoresEdit", // 店舗設定管理(編集)
            "StoreHistories", // 店舗情報履歴
            "ServiceItems", // サービス機能
        ];
        for (const subMenuName of subMenuNames) {
            menuLinksPlus.push({
                routeName: subMenuName,
            });
        }

        const isSubMenu = menuLinksPlus.find((item) => item.routeName === this.$route.name);
        this.gearColor = isSubMenu ? "primary" : "inherit";
        // メインメニューのアイコン全て消灯
        this.activeItem = "";
        this.updateKey++;
    }

    changeDeviceMode(e: Event): void {
        this.isMobile = !this.isMobile;
        this.pageItems = this.isMobile ? this.pageItemsSP : this.pageItemsPC;
        if (this.isMobile) {
            // モバイル対応している画面は少ないので、投稿作成画面に遷移しておく
            this.routerPush(this.pageItems.gmbpost);
            setTimeout(() => {
                this.isDrawerOpened = true;
            }, 200);
        }
    }
}
export default toNative(Sidebar);
