<template>
  <div>
    <!-- 条件選択 -->
    <v-card-actions>
      <!-- キーワードの選択 -->
      <v-menu bottom>
        <template #activator="{ props }">
          <!-- キーワードは4つまで選択できる -->
          <v-btn v-bind="props" depressed class="select-keywords" :disabled="selected.length === 4">
            <v-icon left small :color="buttonColor">fas fa-plus</v-icon>
            キーワード選択
          </v-btn>
        </template>
        <!-- キーワードのチェックボックスリスト -->
        <v-list density="compact">
          <v-list-item v-for="(keyword, i) in volumeKeywords" :key="i">
            <v-checkbox-btn
              v-model="selected"
              :disabled="selected.length === 4"
              :value="keyword"
              :label="keyword"
              density="compact"
              color="primary"
            />
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- 選択されたキーワード -->
      <div v-for="(keyword, i) of selected" :key="keyword" class="text-center">
        <v-btn
          :style="'border-top: 4px solid ' + lineColors[i] + '; background-color: ' + fillColors[i]"
          class="ma-2 selected-keyword"
          elevation="1"
          @click.prevent="removeKeyword(i)"
        >
          <v-icon left small :color="buttonColor">fas fa-xmark</v-icon>
          {{ keyword }}
        </v-btn>
      </div>
    </v-card-actions>
    <v-spacer />
    <!-- 折れ線グラフ -->
    <v-card-text>
      <div>
        <div id="search-keyword-volume" style="height: 360px"></div>
      </div>
    </v-card-text>
    <v-overlay :model-value="loading" persistent contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_ja_JP from "@amcharts/amcharts5/locales/ja_JP";
import { initChart } from "@/components/shared/am5-shared";

import { requiredAuth } from "@/helpers";
import { captureAndThrow } from "@/helpers/error";
import type {
  DomainPerformanceSearchKeywordVolumeResponse,
  DomainPerformanceSeries,
} from "@/types/ls-api";
import type { StoreFilter } from "./performance";
import { fillColors, lineColors } from "./performance";
import { useIndexedDb } from "@/storepinia/idxdb";
import { useRoute, useRouter } from "vue-router";

interface SelectedData extends DomainPerformanceSeries {
  name?: string;
}

// コンポーネント定義
export default defineComponent({
  props: {
    storeFilter: {
      type: Object as () => StoreFilter,
      required: false,
      default: () => ({} as StoreFilter),
    },
  },
  data: () => {
    return {
      $route: useRoute(),
      $router: useRouter(),
      monthsToDisplay: 18 as number, // 表示する期間を過去18ヶ月に固定
      volumeKeywords: [] as string[],
      selected: [] as string[],
      data: [] as DomainPerformanceSeries[],
      loading: false as boolean,
      buttonColor: "#B3B3B3" as string,
      fillColors: fillColors,
      lineColors: lineColors,
      isFirstRun: true,
    };
  },
  computed: {
    poiGroupID: function (): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
  },

  async created() {
    this.getVolumeKeywords();
  },
  methods: {
    onKeywordSelect(): void {
      this.dataSet();
    },
    /* 企業情報から検索キーワードボリュームの集計キーワードを取得する */
    getVolumeKeywords() {
      const company = useIndexedDb().company;
      this.volumeKeywords = company?.searchKeywordVolumeKeywords ?? [];
    },
    /* 企業情報から検索キーワードボリュームの集計キーワードの初期選択 */
    initSelectedVolumeKeywords() {
      // APIのレスポンスをname毎にvalueを合計して、それの多いものから順に4つを初期状態で選択した状態にする
      const initialKeywords =
        this.data
          ?.map((item) => {
            const sum = item?.data.reduce((acc, cur) => acc + cur.value, 0);
            return { name: item.name, sum };
          })
          ?.sort((a, b) => b.sum - a.sum)
          ?.slice(0, 4)
          ?.map(({ name }) => name) ?? [];
      // APIから返される値とCompanyに入っているsearchKeywordVolumeKeywordsの値は同じ想定
      this.selected.splice(0, this.selected.length, ...initialKeywords);
    },
    /* 検索キーワードボリュームの集計キーワードの選択をキャンセル */
    async removeKeyword(index: number) {
      this.selected.splice(index, 1);
    },
    /* データ取得処理 */
    async fetch() {
      // リトライ処理を入れている
      for (let i = 0; i < 1000; i++) {
        this.loading = true;

        const dt1 = new Date().getTime();
        if (i > 0) {
          // 5sec wait
          let dt2 = new Date().getTime();
          const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
          while (dt2 < dt1 + 5 * 1000) {
            await sleep(500);
            dt2 = new Date().getTime();
          }
        }

        await Promise.all([this.fetchSearchKeywordVolume()]);

        if (!this.loading) {
          break;
        }
      }
      this.initSelectedVolumeKeywords();
      await this.createGraph();
    },
    async fetchSearchKeywordVolume() {
      await requiredAuth<DomainPerformanceSearchKeywordVolumeResponse>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
          this.poiGroupID
        }/performance/search_keyword_volume`,
        this.storeFilter
      )
        .then((res) => {
          this.data = res?.data?.seriesList ?? [];
          this.fillMissingData();
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          if (e.response?.status === 503) {
            console.log("503エラー、5秒後にリトライ");
          } else {
            captureAndThrow("各種キーワードの検索ボリューム取得エラー", e);
          }
        });
    },
    /* 欠損しているデータを埋める */
    fillMissingData() {
      const monthArray: string[] = [];
      const result: DomainPerformanceSeries[] = [];

      // 欠損しているkeywordのデータがあれば0で埋めたデータを返す
      this.volumeKeywords.map((keyword) => {
        const d = this.data.find((d) => d.name === keyword);
        if (d) {
          result.push(d);
        } else {
          const filledData = monthArray.map((m) => {
            return { date: m, value: 0 };
          });
          result.push({ name: keyword, data: filledData });
        }
      });
      // データを書き換える
      this.data.splice(0, this.data.length, ...result);
    },
    getShortDate(date: string): string {
      return date?.slice(0, -3).replace("-", "/");
    },
    /* amCharts表示 */
    createGraph() {
      const eleId = "search-keyword-volume";
      // 高速でページ離脱するとAmcharts5のroot生成に失敗でエラーになるので中断する
      if (!document.getElementById(eleId)) {
        return;
      }
      if (this.isFirstRun) {
        // 二重呼び出し抑止の為このタイミングでwatch登録する
        this.isFirstRun = false;
        // 検索キーワードボリュームの集計キーワードの選択が変更されたらデータをセットする
        this.$watch("selected", this.onKeywordSelect, { deep: true, immediate: false });
      }

      const root: am5.Root = initChart(eleId);
      root.locale = am5locales_ja_JP;
      root.numberFormatter.set("numberFormat", "#,###");
      root.dateFormatter.set("dateFormat", "yyyy/MM");

      const chart: am5xy.XYChart = root.container.children.push(
        am5xy.XYChart.new(root, {
          id: "p6Chart",
          panX: false,
          panY: false,
          layout: root.verticalLayout,
        })
      );

      const legend: am5.Legend = chart.children.push(
        am5.Legend.new(root, {
          id: "p6Legend",
          nameField: "キーワード",
          centerX: am5.percent(50),
          x: am5.percent(50),
          y: am5.percent(95),
        })
      );
      legend.markers.template.setAll({
        marginRight: 35,
        width: 23,
      });
      legend.labels.template.setAll({
        marginLeft: -30,
        marginRight: -35,
        fontSize: 14.5,
      });

      // ツールチップ表示する為にcursorを加える
      const cursor: am5xy.XYCursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      const xRenderer: am5xy.AxisRendererX = am5xy.AxisRendererX.new(root, {
        minGridDistance: 40,
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      });
      xRenderer.grid.template.setAll({ location: 0.5 });
      xRenderer.labels.template.setAll({ location: 0.5, multiLocation: 0.5 });

      // X軸(年月)
      const categoryAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          id: "p6CategoryAxis",
          maxDeviation: 0.2,
          renderer: xRenderer,
          startLocation: 0,
          endLocation: 1,
          dx: 0,
          categoryField: "date",
        })
      );

      categoryAxis.get("renderer").labels.template.setAll({
        maxWidth: 52,
        // ラベルのフォントサイズ自動調整
        oversizedBehavior: "fit",
        // ラベルのフォントサイズ手動調整
        fontSize: 11,
        fill: am5.color("#333333"),
        paddingTop: 10,
      });

      // 当月含む過去18ヶ月の月の配列を生成
      const monthArray: { date: string }[] = [];
      for (let i = 0; i < this.monthsToDisplay; i++) {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        // 以下の形式は"2006-01"
        monthArray.push({
          date: `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, "0")}`,
        });
      }
      monthArray.reverse();
      categoryAxis.data.setAll(monthArray);

      // Y軸(件数)
      const valueAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          id: "p6ValueAxis",
          maxPrecision: 0,
          renderer: am5xy.AxisRendererY.new(root, {
            minGridDistance: 20,
          }),
        })
      );
      valueAxis.setAll({
        min: 0,
      });

      // amcharts4のtitleの替わりにlabelを使って表現する
      const ken: am5.Label = am5.Label.new(root, {
        text: "(件)",
        position: "absolute",
        x: -32,
        y: am5.percent(95),
      });
      valueAxis.children.unshift(ken);

      this.dataSet();
    },
    dataSet(): void {
      const chart: am5xy.XYChart = am5.registry.entitiesById.p6Chart as am5xy.XYChart;
      if (!chart || this.selected === null) {
        return;
      }
      // 直前の内容を消す

      chart.series.clear();
      const legend = am5.registry.entitiesById.p6Legend as am5.Legend;
      legend.children.clear();

      if (this.selected.length === 0) {
        return;
      }
      const root: am5.Root = chart._root;
      const selectedData = this.selected.map((name) => {
        const item: SelectedData = this.data.find((item) => item.name === name);
        item.name = name;
        // 日付整形する
        for (const d of item.data) {
          d.date = d.date.replace(/(\d+)-(\d+)-\d+/, "$1/$2");
        }
        return item;
      });

      for (let i = 0; i < selectedData.length; i++) {
        const lineSeries: am5xy.LineSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: selectedData[i].name,
            minBulletDistance: 10,
            xAxis: am5.registry.entitiesById
              .p6CategoryAxis as am5xy.CategoryAxis<am5xy.AxisRenderer>,
            yAxis: am5.registry.entitiesById.p6ValueAxis as am5xy.ValueAxis<am5xy.AxisRenderer>,
            valueYField: "value",
            categoryXField: "date",
            stroke: am5.color(this.lineColors[i]),
          })
        );
        lineSeries.strokes.template.setAll({
          strokeWidth: 2,
        });
        lineSeries.fills.template.setAll({
          visible: true,
        });

        // Legendにseriesを関連付ける
        legend.data.push(lineSeries);

        // ツールチップ
        const tooltip: am5.Tooltip = lineSeries.set(
          "tooltip",
          am5.Tooltip.new(root, {
            getFillFromSprite: false,
            getLabelFillFromSprite: false,
            keepTargetHover: false,
            pointerOrientation: "horizontal",
          })
        );
        tooltip.get("background").setAll({
          fill: am5.color(this.fillColors[i]),
          shadowColor: am5.color("#000000"),
          shadowBlur: 4,
          shadowOffsetY: 2,
          shadowOpacity: 0.5,
        });
        tooltip.label.setAll({
          html: `<div style='line-height: 1.5em; text-align: left; color:#333333'>
          {date}<br>
          {name} : {value}件</div>`,
        });
        lineSeries.data.setAll(selectedData[i].data);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
/* 選択されたキーワードボタンの設定 */
.selected-keyword {
  // v-btnの設定が 'uppercase' になっているのでリセット
  text-transform: unset !important;
}
/* キーワード選択の設定 */
.select-keywords {
  border: 2px dotted #b3b3b3;
  background-color: #fff !important;
}
</style>
