import { requiredAuth } from "@/helpers";
import { parallels } from "@/helpers/parallel-promise";
import type { OperationLogParams } from "@/routes/operation-log";
import type {
    ControllersBatchGetLocationsOutput,
    ControllersFoodMenusEnqueueTaskRequest,
    DomainFoodMenuGroupList,
    DomainFoodMenuFoodMenuApplicationTaskList,
    EntitiesFoodMenuGroup,
    EntitiesFoodMenuStore,
} from "@/types/ls-api";
import { getFoodMenuErrorMessage } from "@/helpers/error";

export type LocationAndAttributes = ControllersBatchGetLocationsOutput["locations"][number];
export const api = {
    getFoodMenuGroup,
    getFoodMenuGroups,
    postFoodMenuGroup,
    putFoodMenuGroup,
    putFoodMenuGroupSchedule,
    getFoodMenuStore,
    getFoodMenuStores,
    putFoodMenuStore,
    putFoodMenuStores,
    getFoodMenuApplicationTasks,
    applyFoodMenus,
    getLocationInParallel,
};
/** フードメニューグループを取得 */
async function getFoodMenuGroup(
    poiGroupId: number,
    foodMenuGroupId: number
): Promise<EntitiesFoodMenuGroup> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/foodMenu/groups/${foodMenuGroupId}`;
    const fmgres = await requiredAuth<EntitiesFoodMenuGroup>("get", url);
    return fmgres.data;
}
/** フードメニューグループ一覧を取得 */
async function getFoodMenuGroups(
    poiGroupId: number,
    oplogParams: OperationLogParams
): Promise<EntitiesFoodMenuGroup[]> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/foodMenu/groups`;
    const fmgs: EntitiesFoodMenuGroup[] = [];
    let pageToken = "";
    do {
        const fmgres = await requiredAuth<DomainFoodMenuGroupList>("get", url, {
            pageToken,
            ...oplogParams,
        });
        fmgs.push(...fmgres.data.list);
        pageToken = fmgres.data.nextPageToken ?? "";
    } while (pageToken !== "");
    return fmgs;
}
/** フードメニューグループを作成 */
async function postFoodMenuGroup(
    poiGroupId: number,
    title: string,
    oplogParams: OperationLogParams
): Promise<EntitiesFoodMenuGroup> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/foodMenu/groups`;
    const res = await requiredAuth<EntitiesFoodMenuGroup>("post", url, oplogParams, { title });
    return res.data;
}
/** フードメニューグループを保存 */
async function putFoodMenuGroup(
    poiGroupId: number,
    foodMenuGroupId: number,
    fmg: EntitiesFoodMenuGroup,
    oplogParams: OperationLogParams
): Promise<EntitiesFoodMenuGroup | string> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/foodMenu/groups/${foodMenuGroupId}`;
    const res = await requiredAuth<EntitiesFoodMenuGroup>("put", url, oplogParams, fmg)
        .then((res) => {
            return res.data;
        })
        .catch((e) => {
            console.error("[putFoodMenuGroup ERROR]", e.response);
            const errorMessage = getFoodMenuErrorMessage(
                e.response?.status,
                e.response?.data?.errorMessage
            );
            return errorMessage;
        });
    return res;
}
/** フードメニューグループの予定時刻を保存 */
async function putFoodMenuGroupSchedule(
    poiGroupId: number,
    foodMenuGroupId: number,
    applicationScheduledTime: string
): Promise<EntitiesFoodMenuGroup> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/foodMenu/groups/${foodMenuGroupId}/schedule`;
    const res = await requiredAuth<EntitiesFoodMenuGroup>("put", url, null, {
        applicationScheduledTime,
    });
    return res.data;
}
/** フードメニュー店舗設定を取得 */
async function getFoodMenuStore(poiGroupId: number, poiId: number): Promise<EntitiesFoodMenuStore> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/foodMenu/stores/${poiId}`;
    const fmsres = await requiredAuth<EntitiesFoodMenuStore>("get", url);
    return fmsres.data;
}
/** フードメニュー店舗設定一覧を取得 */
async function getFoodMenuStores(
    poiGroupId: number,
    foodMenuGroupId: number = null
): Promise<EntitiesFoodMenuStore[]> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/foodMenu/stores`;
    const fmss: EntitiesFoodMenuStore[] = [];
    let pageToken = "";
    do {
        const fmsres = await requiredAuth<DomainFoodMenuGroupList>("get", url, {
            foodMenuGroupID: foodMenuGroupId,
            pageToken,
        });
        fmss.push(...fmsres.data.list);
        pageToken = fmsres.data.nextPageToken ?? "";
    } while (pageToken !== "");
    return fmss;
}
/** フードメニュー店舗設定を保存 */
async function putFoodMenuStore(
    poiGroupId: number,
    poiId: number,
    foodMenuGroupId: number
): Promise<EntitiesFoodMenuStore> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/foodMenu/stores/${poiId}`;
    const res = await requiredAuth<EntitiesFoodMenuStore>("put", url, null, {
        foodMenuGroupID: foodMenuGroupId,
    });
    return res.data;
}
/** フードメニュー店舗設定を複数保存 */
async function putFoodMenuStores(
    poiGroupId: number,
    poiIds: number[],
    foodMenuGroupId: number
): Promise<EntitiesFoodMenuStore[]> {
    const results: EntitiesFoodMenuStore[] = [];
    const ps = parallels();
    for (const id of poiIds) {
        const p = putFoodMenuStore(poiGroupId, id, foodMenuGroupId).then((x) => results.push(x));
        ps.add(p);
        await ps.race(10);
    }
    await ps.all();
    return results;
}
/** フードメニュー反映タスク取得 */
async function getFoodMenuApplicationTasks(
    poiGroupId: number,
    poiId: number
): Promise<DomainFoodMenuFoodMenuApplicationTaskList> {
    const url = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/foodMenu/tasks`;
    const res = await requiredAuth<DomainFoodMenuFoodMenuApplicationTaskList>("get", url, {
        poiID: poiId,
    });
    return res.data;
}
/** フードメニュー反映タスク投入 */
async function applyFoodMenus(
    poiGroupId: number,
    poiIDs: number[],
    foodMenuGroupId: number
): Promise<ControllersFoodMenusEnqueueTaskRequest> {
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/foodMenu/groups/${foodMenuGroupId}/enqueueTask`;
    const res = await requiredAuth<{ poiIDs: number[] }>("post", url, {}, { poiIDs });
    return res.data;
}

/** location.metadataを取得 */
async function getLocationInParallel(
    poiGroupId: number,
    accountName: string,
    locationNames: string[],
    readMask: string,
    chunkSize: number,
    concurrency: number
): Promise<LocationAndAttributes[]> {
    // console.log(poiGroupId, accountName, locationNames);
    const chunks: string[][] = [];
    for (let i = 0; i < locationNames.length; i += chunkSize) {
        const chunk = locationNames.slice(i, i + chunkSize);
        chunks.push(chunk);
    }
    const url = `${
        import.meta.env.VITE_APP_API_BASE
    }v1/companies/${poiGroupId}/${accountName}/locations/batchGet`;
    const result: LocationAndAttributes[] = [];
    const ps = parallels();
    for (const chunk of chunks) {
        const promise = requiredAuth<ControllersBatchGetLocationsOutput>("post", url, null, {
            names: chunk,
            readMask,
        }).then((res) => {
            res.data.locations?.forEach((x) => result.push(x));
        });
        ps.add(promise);
        await ps.race(concurrency);
    }
    await ps.all();
    return result;
}
