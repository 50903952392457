<template>
  <c-grid
    ref="cgrid"
    class="cgrid"
    :frozen-col-count="2"
    :font="gridFont"
    :theme="customTheme"
    style="font-size: small"
    :data="histories"
    :disabled="loading"
  >
    <c-grid-button-column
      :icon="getStatusViewIcon"
      :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
      width="40"
      @click="statusView"
    ></c-grid-button-column>
    <c-grid-column
      :width="dateColumnWidth"
      caption="登録日時"
      :field="(item) => convertDateTime(item.createDateTime)"
      column-type="multilinetext"
      :column-style="{
        autoWrapText: true,
        lineHeight: '1.3em',
        textAlign: 'right',
        textBaseline: 'middle',
      }"
      :sort="sortColumn"
    ></c-grid-column>
    <c-grid-column
      width="210"
      caption="タイトル"
      field="title"
      column-type="multilinetext"
      :column-style="{
        autoWrapText: true,
        lineHeight: '1.3em',
        textBaseline: 'middle',
        padding: [0, 0, 0, 10],
      }"
      :sort="sortColumn"
    ></c-grid-column>
    <c-grid-column
      :width="dateColumnWidth"
      caption="掲載開始日時"
      :field="(item) => convertDateTime(item.posting, true)"
      column-type="multilinetext"
      :column-style="{
        autoWrapText: true,
        lineHeight: '1.3em',
        textAlign: 'right',
        textBaseline: 'middle',
        padding: [0, 0, 0, 10],
      }"
      :sort="sortColumn"
    ></c-grid-column>
    <c-grid-column
      :width="dateColumnWidth"
      caption="掲載終了日時"
      :field="(item) => convertDateTime(item.cancellationReservation, true)"
      column-type="multilinetext"
      :column-style="{
        autoWrapText: true,
        lineHeight: '1.3em',
        textAlign: 'right',
        textBaseline: 'middle',
        padding: [0, 10, 0, 0],
      }"
      :sort="sortColumn"
    ></c-grid-column>
    <c-grid-column
      width="85"
      caption="状態"
      :field="(item) => convertState(item.state)"
      :sort="sortColumn"
    ></c-grid-column>
    <c-grid-column
      width="90"
      caption="GBP種別"
      :field="(item) => getGBPType(item)"
      :sort="sortColumn"
    ></c-grid-column>
    <template v-if="isRequest">
      <!-- 承認待ちリスト表示 -->
      <c-grid-column
        width="200"
        caption="備考"
        :field="(item) => item?.judgementRequestRemark"
        column-type="multilinetext"
        :column-style="{
          autoWrapText: true,
          lineHeight: '1.3em',
          textBaseline: 'middle',
          textOverflow: 'ellipsis',
          lineClamp: 2,
          padding: [0, 0, 0, 10],
        }"
      ></c-grid-column>
      <template v-if="isApprove">
        <!-- 承認権限あり -->
        <c-grid-column
          width="170"
          caption="承認要求者"
          :field="(item) => getRequestUserName(item)"
          column-type="multilinetext"
          :column-style="{
            autoWrapText: true,
            lineHeight: '1.3em',
            textBaseline: 'middle',
            textOverflow: 'ellipsis',
            lineClamp: 2,
            padding: [0, 0, 0, 10],
          }"
        ></c-grid-column>
        <c-grid-button-column
          width="80"
          :icon="getEditIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !item.isEdit"
          @click="postReview"
        >
          レビュー
        </c-grid-button-column>
      </template>
      <template v-else>
        <!-- 承認要求者 -->
        <c-grid-button-column
          width="45"
          :icon="getEditIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !item.isEdit"
          @click="postEdit"
        >
          編集
        </c-grid-button-column>
        <c-grid-button-column
          width="45"
          :icon="getDeleteIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !item.isDelete"
          @click="postDelete"
        >
          削除
        </c-grid-button-column>
      </template>
    </template>
    <template v-else-if="isReject">
      <!-- 承認却下リスト表示 -->
      <c-grid-column
        width="170"
        caption="却下者"
        :field="(item) => getRejectUserName(item)"
        column-type="multilinetext"
        :column-style="{
          autoWrapText: true,
          lineHeight: '1.3em',
          textBaseline: 'middle',
          textOverflow: 'ellipsis',
          lineClamp: 2,
          padding: [0, 0, 0, 10],
        }"
      ></c-grid-column>
      <c-grid-column
        width="200"
        caption="備考"
        :field="(item) => item?.judgementRejectRemark"
        column-type="multilinetext"
        :column-style="{
          autoWrapText: true,
          lineHeight: '1.3em',
          textBaseline: 'middle',
          textOverflow: 'ellipsis',
          lineClamp: 2,
          padding: [0, 0, 0, 10],
        }"
      ></c-grid-column>
      <template v-if="isApprove">
        <!-- 承認権限あり -->
        <c-grid-button-column
          width="80"
          :icon="getEditIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !item.isEdit"
          @click="postReview"
        >
          再レビュー
        </c-grid-button-column>
      </template>
      <template v-else>
        <!-- 承認要求者 -->
        <c-grid-button-column
          width="45"
          :icon="getEditIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !item.isEdit"
          @click="postEdit"
        >
          編集
        </c-grid-button-column>
        <c-grid-button-column
          width="45"
          :icon="getDeleteIcon"
          :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
          :disabled="(item) => !item.isDelete"
          @click="postDelete"
        >
          削除
        </c-grid-button-column>
      </template>
    </template>
    <template v-else>
      <!-- 通常投稿履歴表示 -->
      <c-grid-column
        v-if="showMediaCount"
        width="85"
        :caption="'GBP完了数'"
        :field="
          (item) =>
            convertState(
              item.gbpState,
              item.gbpStoreCount,
              item.gbpPostCount,
              item.gbpErrorCount,
              false
            )
        "
        :column-style="{ textAlign: 'center' }"
        :sort="sortColumn"
      ></c-grid-column>
      <c-grid-column
        v-if="showMediaCount"
        width="100"
        caption="Y!プレ完了数"
        :field="
          (item) =>
            convertState(
              item.yahooState,
              item.yahooStoreCount,
              item.yahooPostCount,
              item.yahooErrorCount,
              false
            )
        "
        :column-style="{ textAlign: 'center' }"
        :sort="sortColumn"
      />
      <c-grid-column
        v-if="showMediaCount"
        width="150"
        caption="Y!プレ概要タブ開始日"
        :field="(item) => convertDateFormat(item.Yahoo?.pinnedStartDate)"
        :column-style="{ textAlign: 'center' }"
        :sort="sortColumn"
      />
      <c-grid-column
        v-if="showMediaCount"
        width="150"
        caption="Y!プレ概要タブ終了日"
        :field="(item) => convertDateFormat(item.Yahoo?.pinnedEndDate)"
        :column-style="{ textAlign: 'center' }"
        :sort="sortColumn"
      />
      <c-grid-column
        v-if="showMediaCount"
        width="85"
        caption="FB完了数"
        :field="
          (item) =>
            convertState(
              item.fbState,
              item.fbStoreCount,
              item.fbPostCount,
              item.fbErrorCount,
              false
            )
        "
        :column-style="{ textAlign: 'center' }"
        :sort="sortColumn"
      ></c-grid-column>
      <c-grid-column
        v-if="showMediaCount"
        width="85"
        caption="IG完了数"
        :field="
          (item) =>
            convertState(
              item.igState,
              item.igStoreCount,
              item.igPostCount,
              item.igErrorCount,
              false
            )
        "
        :column-style="{ textAlign: 'center' }"
        :sort="sortColumn"
      ></c-grid-column>
      <c-grid-column
        v-if="showMediaCount"
        width="135"
        caption="ホスティング完了数"
        :field="
          (item) =>
            convertState(
              item.hostingState,
              item.hostingStoreCount,
              item.hostingPostCount,
              item.hostingErrorCount,
              false
            )
        "
        :column-style="{ textAlign: 'center' }"
        :sort="sortColumn"
      ></c-grid-column>
      <c-grid-column
        :width="170"
        caption="最終更新ユーザー"
        :field="(item) => getUserName(item)"
        column-type="multilinetext"
        :column-style="{
          autoWrapText: true,
          lineHeight: '1.3em',
          textBaseline: 'middle',
          textOverflow: 'ellipsis',
          lineClamp: 2,
          padding: [0, 0, 0, 10],
        }"
        :sort="sortColumn"
      ></c-grid-column>
      <c-grid-button-column
        :width="canManage ? 45 : 80"
        :icon="getEditIcon"
        :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
        :disabled="(item) => !item.isEdit"
        @click="postEdit"
      >
        {{ canManage ? "編集" : "詳細を見る" }}
      </c-grid-button-column>
      <c-grid-button-column
        v-if="canManage"
        width="45"
        :icon="getDeleteIcon"
        :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
        :disabled="(item) => !item.isDelete"
        @click="postDelete"
      >
        削除
      </c-grid-button-column>
      <c-grid-button-column
        v-if="canManage"
        width="45"
        :icon="getRetryIcon"
        :column-style="{ textAlign: 'center', buttonBgColor: 'transparent' }"
        :disabled="(item) => !item.isRetry"
        @click="postRetry"
      >
        再実行
      </c-grid-button-column>
    </template>
  </c-grid>
  <v-2-post-details v-model="detailOpen" :post-data="detailPostData" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useIndexedDb } from "@/storepinia/idxdb";
import { convertDateTime } from "@/helpers/date";
import V2PostDetails from "./post-details.vue";
import {
  getGBPType,
  getUserName,
  getRequestUserName,
  getRejectUserName,
  convertState,
  convertDateFormat,
} from "./utils";
import rightImage from "@/assets/images/angle-right-solid.svg";
import pencilImage from "@/assets/images/pencil-solid.svg";
import trashImage from "@/assets/images/trash-can-solid.svg";
import retryImage from "@/assets/images/person-running-solid.svg";

import type { EntitiesV2HistoryPostData } from "@/types/ls-api";
import type { ListGrid } from "cheetah-grid";
import type { CustomSortState } from "@/components/shared/cheetah-grid-shared";

type IconProperties = {
  src: string;
  width: number;
};

export default defineComponent({
  components: { V2PostDetails },
  props: {
    items: {
      type: Array<EntitiesV2HistoryPostData>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dateColumnWidth: {
      type: Number,
      default: 160,
    },
    showMediaCount: {
      type: Boolean,
      default: false,
    },
    isRequest: {
      // 承認待ちリストの場合はtrue
      type: Boolean,
      default: false,
    },
    isReject: {
      // 承認却下リストの場合はtrue
      type: Boolean,
      default: false,
    },
    isApprove: {
      // 承認権限がある場合はtrue
      type: Boolean,
      default: false,
    },
  },
  emits: ["post-edit", "post-delete", "post-retry", "post-review"],
  data() {
    return {
      gridFont: "12.8px sans-serif",
      // cheetah-gridのカスタムテーマ設定（失敗ステータスの背景色を変更）
      customTheme: {
        checkbox: {
          borderColor: "#FF9800",
        },
        defaultBgColor({ col, row, grid }: { col: number; row: number; grid: any }): string {
          if (col < grid.frozenColCount || row < grid.frozenRowCount) {
            return "#f0f0f0";
          }
          const hdr = grid.header[col];
          const rec = grid.dataSource.source[row - 1];
          const failed = "#ffaaaa";
          const success = "#ffffff";

          switch (hdr.caption) {
            case "状態":
              return rec.state?.includes("FAILED") ? "#ff8888" : success;
            case "GBP完了数":
              return rec.gbpState?.includes("FAILED") ? failed : success;
            case "Y!プレ完了数":
              return rec.yahooState?.includes("FAILED") ? failed : success;
            case "FB完了数":
              return rec.fbState?.includes("FAILED") ? failed : success;
            case "IG完了数":
              return rec.igState?.includes("FAILED") ? failed : success;
            case "ホスティング完了数":
              return rec.hostingState?.includes("FAILED") ? failed : success;
          }
          return success;
        },
      },
      // 詳細画面に関するパラメータ
      detailOpen: false as boolean,
      detailPostData: null as EntitiesV2HistoryPostData | null,
      // 直前に行ったソート順と対象列のパラメータ
      customSortState: {
        col: 0,
        order: null,
      } as CustomSortState,
    };
  },
  computed: {
    canManage(): boolean {
      return useIndexedDb().canManagePost;
    },
    histories(): EntitiesV2HistoryPostData[] {
      const rows = [...this.$props.items];
      return this.sortRecord(rows, this.customSortState.col, this.customSortState.order);
    },
  },
  methods: {
    getGBPType,
    getUserName,
    getRequestUserName,
    getRejectUserName,
    convertDateTime,
    convertState,
    convertDateFormat,
    getStatusViewIcon(data: EntitiesV2HistoryPostData): IconProperties {
      if (data.fileNames?.length > 0) {
        return { src: rightImage, width: 10 };
      }
      return { src: "", width: 10 };
    },
    getEditIcon(data: EntitiesV2HistoryPostData): IconProperties | null {
      return data.isEdit ? { src: pencilImage, width: 25 } : null;
    },
    getDeleteIcon(data: EntitiesV2HistoryPostData): IconProperties | null {
      return data.isDelete ? { src: trashImage, width: 25 } : null;
    },
    getRetryIcon(data: EntitiesV2HistoryPostData): IconProperties | null {
      return data.isRetry ? { src: retryImage, width: 25 } : null;
    },
    postEdit(item: EntitiesV2HistoryPostData): void {
      this.$emit("post-edit", item);
    },
    postDelete(item: EntitiesV2HistoryPostData): void {
      this.$emit("post-delete", item);
    },
    postRetry(item: EntitiesV2HistoryPostData): void {
      this.$emit("post-retry", item);
    },
    postReview(item: EntitiesV2HistoryPostData): void {
      this.$emit("post-review", item);
    },
    // 行の展開ボタン（＞）をクリックされたら呼び出される
    statusView(item: EntitiesV2HistoryPostData): void {
      if (item.fileNames?.length > 0) {
        // ファイルがある場合は詳細画面を表示する
        this.detailPostData = item;
        this.detailOpen = true;
      }
    },
    updateHistories(): void {
      (this.$refs.cgrid as ListGrid<EntitiesV2HistoryPostData>)?.invalidate();
    },
    // cheetah-gridヘッダ部をクリックしたときのイベント
    sortColumn(order: string | null, col: number, grid: ListGrid<EntitiesV2HistoryPostData>): void {
      if (col !== this.customSortState.col) {
        // 直前のと違う列をソートしようとした場合はorderを初期化する
        this.customSortState.order = "desc";
        this.customSortState.col = col;
      } else {
        switch (this.customSortState.order) {
          case null:
            this.customSortState.order = "desc";
            break;
          case "desc":
            this.customSortState.order = "asc";
            break;
          case "asc":
            this.customSortState.order = null;
            break;
        }
      }
      // ↑↓マーク制御
      grid.sortState.order = this.customSortState.order;
    },
    // クリックされたカラムでソート処理を実行
    sortRecord(
      rows: EntitiesV2HistoryPostData[],
      col: number,
      order: string | null = "asc"
    ): EntitiesV2HistoryPostData[] {
      if (col > 0) {
        // カラムの並び順
        const keys: string[] = [
          "",
          "createDateTime",
          "title",
          "posting",
          "cancellationReservation",
          "state",
          "gbpType",
          "gbpState",
          "yahooState",
          "pinnedStartDate",
          "pinnedEndDate",
          "fbState",
          "igState",
          "hostingState",
          "user",
        ];
        // ソートのキーを決める。昇りでも降りでもない場合は初期の並び順である createDateTime(key[1]) でソートする
        const key = order === null ? keys[1] : keys[col];
        // ソート順を決める。ただし登録日時はソート順を逆にする（最新の投稿を一番先頭に持ってきたいため）
        let orderVal = order === "asc" ? -1 : 1;
        if (key == "createDateTime") {
          orderVal = -1 * orderVal;
        }
        rows.sort((a, b) => {
          const aDash = this.getSortItem(key, a);
          const bDash = this.getSortItem(key, b);
          return aDash < bDash ? -1 * orderVal : orderVal;
        });
      }
      return rows;
    },
    getSortItem(key: string, item: EntitiesV2HistoryPostData): string {
      switch (key) {
        case "createDateTime":
          return this.convertDateTime(item.createDateTime);
        case "title":
          return item.title;
        case "posting":
          return this.convertDateTime(item.posting);
        case "cancellationReservation":
          return this.convertDateTime(item.cancellationReservation);
        case "state":
          return this.convertState(item.state);
        case "gbpType":
          return this.getGBPType(item);
        case "gbpState":
          return this.convertState(
            item.gbpState,
            item.gbpStoreCount,
            item.gbpPostCount,
            item.gbpErrorCount,
            false
          );
        case "yahooState":
          return this.convertState(
            item.yahooState,
            item.yahooStoreCount,
            item.yahooPostCount,
            item.yahooErrorCount,
            false
          );
        case "pinnedStartDate":
          return this.convertDateFormat(item.Yahoo?.pinnedStartDate);
        case "pinnedEndDate":
          return this.convertDateFormat(item.Yahoo?.pinnedEndDate);
        case "fbState":
          return this.convertState(
            item.fbState,
            item.fbStoreCount,
            item.fbPostCount,
            item.fbErrorCount,
            false
          );
        case "igState":
          return this.convertState(
            item.igState,
            item.igStoreCount,
            item.igPostCount,
            item.igErrorCount,
            false
          );
        case "hostingState":
          return this.convertState(
            item.hostingState,
            item.hostingStoreCount,
            item.hostingPostCount,
            item.hostingErrorCount,
            false
          );
        case "user":
          return getUserName(item);
      }
      return "";
    },
  },
});
</script>

<style lang="scss" scoped></style>
