export const allowedExtensions = ".jpg,.jpeg,.png,.mov,.mp4";

/**
 * アップロードする画像ファイルを表すクラス
 */
export class MediaFile {
    static counter = 0;
    key: number;
    file: File;
    mediaFormat: MediaFormat;
    isVideo: boolean;
    width: number;
    height: number;
    src: string;
    imageError = "";
    static async of(file: File): Promise<MediaFile> {
        const o = new MediaFile();
        o.key = MediaFile.counter++;
        o.file = file;
        o.mediaFormat = MediaFormat.ofFile(file);

        if (!o.mediaFormat) {
            throw Error("メディアのタイプが判別できません");
        }
        o.isVideo = o.mediaFormat === MediaFormat.VIDEO;
        if (o.isVideo) {
            o.width = null;
            o.height = null;
            o.src = null;
            return o;
        }
        // 画像の場合は画像サイズを計算する
        const size = await getSize(file);
        if (size.error) {
            o.width = null;
            o.height = null;
            o.src = null;
            o.imageError = size.error;
            return o;
        }
        o.width = size.width;
        o.height = size.height;
        o.src = await getSrc(file);
        return o;
    }
    equals(o: MediaFile): boolean {
        return (
            this.file.name === o.file.name &&
            this.file.size === o.file.size &&
            this.file.lastModified === o.file.lastModified
        );
    }
    async load(): Promise<ArrayBuffer> {
        return await getArrayBuffer(this.file);
    }
    /**
     * 引数のカテゴリとして追加が可能かを判定する
     */
    canMakeAs(ci: CategoryInfo): boolean {
        if (ci.canMake === false) {
            return false;
        }
        if (ci.canUseMediaFormat(this.mediaFormat) === false) {
            return false;
        }
        if (ci.category === "COVER") {
            // COVER はアスペクト比が 16:9 (1.777778) である必要がある.
            // ただしある程度の範囲は許容されている。パターンを調べた結果下のようになった
            // 800:479 OK, 800:480 NG,  800:360 OK, 800:355 NG
            const ratio = this.width / this.height;
            const canUseAsCover = 1.68 < ratio && ratio < 2.23;
            if (canUseAsCover === false) {
                return false;
            }
        }
        return true;
    }
    getMakeableCategoryInfos(): CategoryInfo[] {
        return CategoryInfo.values.filter((ci) => this.canMakeAs(ci));
    }
}

/**
 * メディアフォーマット
 */
export class MediaFormat {
    readonly name: string;
    readonly exts: string[];
    constructor(name: string, accepts: string[]) {
        this.name = name;
        this.exts = accepts;
    }
    static ofFile(file: File): MediaFormat | null {
        const ext = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
        for (const mt of MediaFormat.values) {
            if (mt.exts.includes(ext)) {
                return mt;
            }
        }
        return null;
    }
    static VIDEO = new MediaFormat("VIDEO", [".mov", ".mp4"]);
    static PHOTO = new MediaFormat("PHOTO", [".png", ".jpg", ".jpeg"]);
    static values = [MediaFormat.VIDEO, MediaFormat.PHOTO];
}
const [VIDEO, PHOTO] = [MediaFormat.VIDEO, MediaFormat.PHOTO];

/**
 * 画像のカテゴリ
 */
export class CategoryInfo {
    readonly category: string;
    readonly title: string;
    readonly mediaFormats: MediaFormat[];
    readonly canDuplicate: boolean;
    readonly canMake: boolean;
    readonly canChangeTo: boolean;
    readonly canChangeFrom: boolean;
    private constructor(
        category: string,
        title: string,
        mediaFormats: MediaFormat[],
        canDuplicate: boolean,
        canMake: boolean,
        canChangeTo: boolean,
        canChangeFrom: boolean
    ) {
        this.category = category;
        this.title = title;
        this.mediaFormats = mediaFormats; // COVER, PROFILE は動画は不可
        this.canDuplicate = canDuplicate; // COVER, PROFILE は重複不可能
        this.canMake = canMake; // サポートしないカテゴリは作成させない
        this.canChangeTo = canChangeTo; // サポートしないカテゴリに変更させない
        this.canChangeFrom = canChangeFrom; // サポートしないカテゴリでも変更は認める
    }
    canUseMediaFormat(mediaFormat: MediaFormat): boolean {
        let canUse = false;
        for (let i = 0; i < this.mediaFormats.length; i++) {
            if (this.mediaFormats[i].name === mediaFormat.name) {
                canUse = true;
                break;
            }
        }
        return canUse;
    }
    static compare(a: string, b: string): number {
        const va = a === "PROFILE" ? -2 : a === "COVER" ? -1 : 0;
        const vb = b === "PROFILE" ? -2 : b === "COVER" ? -1 : 0;
        return va - vb;
    }
    static of(s: string): CategoryInfo {
        for (const ci of CategoryInfo.values) {
            if (ci.category === s) {
                return ci;
            }
        }
        return null;
    }
    // https://developers.google.com/my-business/reference/rest/v4/accounts.locations.media#MediaItem.Category
    // コメントに * をつけた共通のカテゴリだけローカルサーチでは設定できる
    static values = [
        new CategoryInfo("COVER", "カバー(COVER)", [PHOTO], false, true, false, false), // *
        new CategoryInfo("PROFILE", "ロゴ(PROFILE)", [PHOTO], false, true, false, false), // *
        new CategoryInfo("LOGO", "ロゴ？(LOGO)", [], false, false, false, false),
        new CategoryInfo("EXTERIOR", "外観(EXTERIOR)", [PHOTO, VIDEO], true, true, true, true), // *
        new CategoryInfo("INTERIOR", "店内(INTERIOR)", [PHOTO, VIDEO], true, true, true, true), // *
        new CategoryInfo("PRODUCT", "商品(PRODUCT)", [PHOTO, VIDEO], true, false, false, true),
        new CategoryInfo("AT_WORK", "職場(AT_WORK)", [PHOTO, VIDEO], true, false, false, true),
        new CategoryInfo(
            "FOOD_AND_DRINK",
            "食品と飲料(FOOD_AND_DRINK)",
            [PHOTO, VIDEO],
            true,
            false,
            false,
            true
        ),
        new CategoryInfo("MENU", "メニュー(MENU)", [PHOTO, VIDEO], true, false, false, true),
        new CategoryInfo(
            "COMMON_AREA",
            "共有エリア(COMMON_AREA)",
            [PHOTO, VIDEO],
            true,
            false,
            false,
            true
        ),
        new CategoryInfo("ROOMS", "部屋(ROOMS)", [PHOTO, VIDEO], true, false, false, true),
        new CategoryInfo("TEAMS", "チーム(TEAMS)", [PHOTO, VIDEO], true, true, true, true), // *
        new CategoryInfo(
            "ADDITIONAL",
            "未分類(ADDITIONAL)",
            [PHOTO, VIDEO],
            true,
            true,
            true,
            true
        ), // *
        new CategoryInfo(
            "CATEGORY_UNSPECIFIED",
            "カテゴリ未特定(CATEGORY_UNSPECIFIED)",
            [PHOTO, VIDEO],
            true,
            false,
            false,
            true
        ),
    ];
}

/**
 * Fileの画像サイズを取得する
 */
const getSize = async (file: File): Promise<{ width: number; height: number; error?: string }> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const size = {
                width: img.naturalWidth,
                height: img.naturalHeight,
            };
            URL.revokeObjectURL(img.src);
            resolve(size);
        };
        img.onerror = (error) => {
            console.error("[getSize] onerror", error);
            resolve({ width: 0, height: 0, error: "画像ファイルが破損しています" });
        };
        img.src = URL.createObjectURL(file);
    });
};
/**
 * Fileの内容を取得する
 */
const getSrc = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result as string);
        };
        reader.onerror = (error) => {
            console.error("[getSrc] onerror");
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};
/**
 * Fileの内容を取得する
 */
const getArrayBuffer = async (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result as ArrayBuffer);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsArrayBuffer(file);
    });
};
