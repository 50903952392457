<template>
  <!-- mobile -->
  <div v-if="isMobile">
    <!-- ヘッダ部 -->
    <mobile-header
      :search-word="searchWord"
      :search-range="searchRange"
      :show-histories="showHistories()"
      :async-loading="asyncLoading || asyncLoadingApprove"
      @filter="filterHistories"
      @clear="clearSearchWord"
      @export="exportFile"
      @reload="reload"
      @update:search-word="searchWord = $event"
      @update:search-range="searchRange = $event"
    />
    <!-- 投稿表示部 -->
    <histories-cards
      ref="historiesCards"
      :items="histories"
      :loading="loading"
      @post-edit="postEdit"
      @post-delete="postDelete"
      @post-retry="postRetry"
    />
    <confirm-modal
      :open-confirm="isModalActive"
      :target="modalProps.target"
      :process-name="modalProps.processName"
      :warning="modalProps.warning"
      :date="modalProps.date"
      :title="modalProps.title"
      :row="modalProps.row"
      :is-manual-approve-select="modalProps.isManualApproveSelect"
      :is-approve-request="modalProps.isApproveRequest"
      :default-remark="modalProps.defaultRemark"
      @submit="(item: EntitiesV2HistoryPostData, uuidList: string[], remark: string) => modalProps.method(item, uuidList, remark)"
      @close-modal="isModalActive = false"
    />

    <reload-confirm-modal
      :open-reload-confirm="isReloadModalActive"
      :is-mobile="isMobile"
      @reload-post-histories="reload"
    />
  </div>
  <!-- PC -->
  <div v-else class="v2histories-contains">
    <!-- ヘッダ部 -->
    <histories-header
      :search-word="searchWord"
      :search-range="searchRange"
      :show-histories="showHistories()"
      :async-loading="asyncLoading || asyncLoadingApprove"
      @filter="filters"
      @clear="clearSearchWord"
      @export="exportFile"
      @reload="reload"
      @update:search-word="searchWord = $event"
      @update:search-range="searchRange = $event"
    />
    <confirm-modal
      :open-confirm="isModalActive"
      :target="modalProps.target"
      :process-name="modalProps.processName"
      :warning="modalProps.warning"
      :date="modalProps.date"
      :title="modalProps.title"
      :row="modalProps.row"
      :is-manual-approve-select="modalProps.isManualApproveSelect"
      :is-approve-request="modalProps.isApproveRequest"
      :default-remark="modalProps.defaultRemark"
      @submit="(item: EntitiesV2HistoryPostData, uuidList: string[], remark) => modalProps.method(item, uuidList, remark)"
      @close-modal="isModalActive = false"
    />
    <reload-confirm-modal
      :open-reload-confirm="isReloadModalActive"
      :is-mobile="isMobile"
      @reload-post-histories="reload"
    />
    <!-- 読込中 -->
    <div v-if="loading" class="v2histories-list d-flex justify-center pa-1 align-center mb-1">
      <v-progress-circular indeterminate color="primary" :size="100"></v-progress-circular>
    </div>
    <template v-else>
      <!-- 承認機能有効状態での投稿表示部 -->
      <template v-if="approveEnabled()">
        <!-- タブ -->
        <div class="tabs-container">
          <div class="store-tabs">
            <o-button class="tab" :variant="tabIndex !== 0 ? 'light' : ''" @click="changeTab(0)">
              すべて
            </o-button>
            <o-button class="tab" :variant="tabIndex !== 1 ? 'light' : ''" @click="changeTab(1)">
              承認待ち {{ requests.length > 0 ? `(${requests.length})` : "" }}
            </o-button>
            <o-button class="tab" :variant="tabIndex !== 2 ? 'light' : ''" @click="changeTab(2)">
              承認却下 {{ rejects.length > 0 ? `(${rejects.length})` : "" }}
            </o-button>
            <!-- 右端に「メディア別投稿完了数を表示/非表示」ボタンを追加 -->
            <o-button variant="text" class="media-toggle" @click="toggleMediaCount">
              <v-icon :icon="showMediaCount ? 'mdi-eye' : 'mdi-eye-off'" />
              メディア別投稿完了数を表示/非表示
            </o-button>
          </div>
        </div>
        <!-- 投稿一覧/すべて(承認要求者の編集・削除は承認された状態からの変更なので承認して貰う必要があるが、リトライは承認された状態にするためのものなので再度承認して貰う必要はないと判断) -->
        <div class="v2histories-list">
          <histories-table
            v-if="tabIndex === 0"
            ref="historiesTable"
            :items="histories"
            :date-column-width="dateColumnWidth"
            :show-media-count="showMediaCount"
            :loading="loading"
            @post-edit="(item) => (isRequestAuthorization ? requestPostEdit(item) : postEdit(item))"
            @post-delete="
              (item) => (isRequestAuthorization ? requestPostDelete(item) : postDelete(item))
            "
            @post-retry="postRetry"
          />
          <!-- 投稿一覧/承認待ち -->
          <histories-table
            v-else-if="tabIndex === 1"
            ref="historiesRequestTable"
            :items="requests"
            :date-column-width="dateColumnWidth"
            :show-media-count="showMediaCount"
            :loading="loading"
            :is-approve="isApproveAuthorization"
            :is-request="true"
            @post-edit="requestPostEdit"
            @post-delete="requestPostDelete"
            @post-review="requestPostReview"
          />
          <!-- 投稿一覧/承認却下 -->
          <histories-table
            v-else-if="tabIndex === 2"
            ref="historiesRejectTable"
            :items="rejects"
            :date-column-width="dateColumnWidth"
            :show-media-count="showMediaCount"
            :loading="loading"
            :is-approve="isApproveAuthorization"
            :is-reject="true"
            @post-edit="rejectPostEdit"
            @post-delete="rejectPostDelete"
            @post-review="rejectPostReview"
          />
        </div>
      </template>
      <!-- 承認機能無効状態での投稿表示部 -->
      <div v-else-if="!approveEnabled()" class="v2histories-list">
        <histories-table
          ref="historiesTable"
          :items="histories"
          :date-column-width="dateColumnWidth"
          :show-media-count="showMediaCount"
          :loading="loading"
          @post-edit="postEdit"
          @post-delete="postDelete"
          @post-retry="postRetry"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import { requiredAuth } from "@/helpers";
import { convertDateTime } from "@/helpers/date";
import { useSnackbar } from "@/storepinia/snackbar";

import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import type {
  ControllersHistoriesOutput,
  ControllersHistoryDeleteInput,
  ControllersHistoryDeleteOutput,
  ControllersHistoryDetailsOutput,
  ControllersRetryOutput,
  ControllersRequestHistoriesOutput,
  EntitiesV2HistoryPostData,
} from "@/types/ls-api";

import wordDictionary from "@/word-dictionary";
import HistoriesHeader from "./histories-header.vue";
import MobileHeader from "./mobile-header.vue";
import HistoriesTable from "./histories-table.vue";
import HistoriesCards from "./histories-cards.vue";
import ReloadConfirmModal from "../v2-posts/reload-confirm-modal.vue";

import { useIndexedDb, getter, action } from "@/storepinia/idxdb";
import { getOperationLogParams } from "@/routes/operation-log";
import { determineDeleteActionType, determineRetryActionType } from "@/helpers/post-status";
import { getGBPType, getUserName, convertState } from "./utils";
import ConfirmModal from "./confirm-modal.vue";
import { useRoute, useRouter } from "vue-router";

type ModalProps = {
  date?: string;
  title?: string;
  warning?: string;
  processName?: string;
  target?: string;
  row?: EntitiesV2HistoryPostData;
  // 承認要求権限の場合trueをセット
  isApproveRequest?: boolean;
  // 承認者の手動設定が有効な場合trueをセット
  isManualApproveSelect?: boolean;
  // 承認者の手動設定が有効な場合、承認者選択モードをセット(auto/manual)
  approveUserMode?: string;
  // 承認要求の備考をシステム側でデフォルト値としてセットする場合に使用する(削除依頼は承認者が削除したいのかが判別できない為、備考に削除依頼とセットする)
  defaultRemark?: string;
  method?: (item: EntitiesV2HistoryPostData, uuidList: string[], remark: string) => Promise<void>;
};

@Component({
  components: {
    HistoriesHeader,
    MobileHeader,
    HistoriesTable,
    HistoriesCards,
    ReloadConfirmModal,
    ConfirmModal,
  },
})
class V2PostHistories extends Vue {
  route = useRoute();
  router = useRouter();
  company = getter().company;
  stores = getter().stores;
  user = getter().user;
  isMobile = getter().isMobile;
  // 新規投稿権限がある場合はtrue
  isCreateAuthorization = getter().canPostCreate;
  // 編集権限がある場合はtrue
  isEditAuthorization = getter().canPostEdit;
  // 削除権限がある場合はtrue
  isDeleteAuthorization = getter().canPostDelete;
  // 承認権限がある場合はtrue
  isApproveAuthorization = getter().canPostApprove;
  // 承認要求権限の場合はtrue
  isRequestAuthorization = getter().canPostRequest;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;
  convertDateTime = convertDateTime;
  getGBPType = getGBPType;
  getUserName = getUserName;
  convertState = convertState;

  loading: boolean = false;
  asyncLoading: boolean = false;
  asyncLoadingApprove: boolean = false;
  isDestroyed: boolean = false;
  poiGroupId: number;
  dateColumnWidth: number = 160;
  tabIndex: number = 0;
  showMediaCount = getter().isShowMediaCount;

  modalProps: ModalProps = {
    date: "",
    title: "",
    warning: "",
    processName: "",
    target: "",
    row: {} as EntitiesV2HistoryPostData,
    isApproveRequest: false,
    isManualApproveSelect: false,
    approveUserMode: "auto",
    defaultRemark: "",
    method: async () => {},
  };
  isModalActive = false;
  reloadModalProps = {};
  isReloadModalActive = false;
  unknownStatusPost = false;
  // 承認要求時に手動で承認者を選択できる場合はtrue
  v2PostApprovalManual = false;
  // 承認時に手動で承認者を選択できる場合にtrueを返す
  get isManualApproveSelect(): boolean {
    return this.company.options?.includes("v2-post-approval-manual");
  }

  private historiesLimit = 100;
  histories: EntitiesV2HistoryPostData[] = [];
  rowHistories: EntitiesV2HistoryPostData[] = [];

  requests: EntitiesV2HistoryPostData[] = [];
  rowRequests: EntitiesV2HistoryPostData[] = [];

  rejects: EntitiesV2HistoryPostData[] = [];
  rowRejects: EntitiesV2HistoryPostData[] = [];

  async changeTab(index: number): Promise<void> {
    this.tabIndex = index;
  }

  handleResize(): void {
    this.dateColumnWidth = window.innerWidth <= 1830 ? 100 : 160;
  }

  approveEnabled(): boolean {
    return this.isApproveAuthorization || this.isRequestAuthorization;
  }

  async created(): Promise<void> {
    this.isDestroyed = false;
    this.poiGroupId = this.company.poiGroupID;
    this.v2PostApprovalManual = this.isManualApproveSelect;
    // 承認者の手動設定が有効な場合は予めモーダルで承認者一覧を取得しておく(要求権限を持っている場合のみ)
    this.modalProps.isManualApproveSelect = this.isRequestAuthorization
      ? this.isManualApproveSelect
      : false;

    if (await this.contractConfirmation()) {
      await this.fetch();
    }
  }

  mounted(): void {
    if (!this.isMobile) {
      window.addEventListener("resize", this.handleResize);
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      this.handleResize();
    }
  }

  beforeUnmount(): void {
    this.isDestroyed = true;
    if (!this.isMobile) {
      document.getElementsByTagName("html")[0].style.overflow = "initial";
      window.removeEventListener("resize", this.handleResize);
    }
  }

  // 契約状況の確認
  private async contractConfirmation(): Promise<boolean> {
    const stores = this.stores.stores?.filter((row) =>
      row.options?.includes(wordDictionary.stores.options.post)
    );
    if (stores.length == 0) {
      // 投稿可能な店舗が1件もなければ未契約画面へ遷移
      this.router.push({ name: "InquiriesForm", query: { from: this.route.path } });
      return false;
    }
    // 投稿可能な店舗数に応じて一度に取得する投稿数を調節する(一度に投稿できる店舗数が多いと、１投稿に含まれる指示ファイル数が分割されて作成されるため)
    if (stores.length > 3000) {
      this.historiesLimit = 80;
    } else if (stores.length > 6000) {
      this.historiesLimit = 60;
    } else if (stores.length > 9000) {
      this.historiesLimit = 40;
    }
    return true;
  }

  // グリッドの内容だけを取得し直す
  async reload(): Promise<void> {
    this.isReloadModalActive = false;
    await this.fetch();
  }

  async fetch(pageToken: string = ""): Promise<void> {
    if (this.isDestroyed) {
      return;
    }
    if (!pageToken) {
      // 非同期読み込みを実行中に再度読み直しを要求されたらキャンセルする
      if (this.asyncLoading || this.asyncLoadingApprove) {
        return;
      }
      this.rowHistories = [];
      this.histories = [];
      this.rowRequests = [];
      this.requests = [];
      this.rowRejects = [];
      this.rejects = [];
      this.loading = true;
      this.asyncLoading = true;
    }
    if (this.approveEnabled()) {
      this.asyncLoadingApprove = true;
      if (this.isApproveAuthorization) {
        await this.fetchApproveHistories();
      }
      if (this.isRequestAuthorization) {
        await this.fetchRequestHistories();
      }
    }
    await this.fetchPostHistories(pageToken);
  }

  async fetchPostHistories(pageToken: string = ""): Promise<void> {
    try {
      const result = await requiredAuth<ControllersHistoriesOutput>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post/histories`,
        { pageToken: pageToken, limit: this.historiesLimit }
      );
      // すべての投稿を表示対象とする
      this.rowHistories = this.rowHistories.concat(result.data?.results);

      this.filterHistories();
      this.loading = false; // 1ページ目が読み込み終わったらローディングを非表示にする
      if (result.data?.nextPageToken?.length > 0 && !this.isMobile) {
        // PC版かつ次ページがある場合は次ページを読み込む
        await this.fetchPostHistories(result.data.nextPageToken);
      } else {
        this.asyncLoading = false;
      }
    } catch (e) {
      console.log("fetch exception catch", e);
    }
  }

  // 承認権限での 承認要求リスト＋承認却下リスト取得
  async fetchApproveHistories(pageToken: string = ""): Promise<void> {
    try {
      const result = await requiredAuth<ControllersRequestHistoriesOutput>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.poiGroupId
        }/post/approve/histories`,
        { pageToken: pageToken, limit: this.historiesLimit }
      );
      this.rowRejects = this.rowRejects.concat(
        result.data?.results?.filter((row) => row.state === "JUDGEMENTREJECT")
      );
      this.rowRequests = this.rowRequests.concat(
        result.data?.results?.filter((row) => row.state === "JUDGEMENTREQUEST")
      );

      this.filterApproves();
      if (result.data?.nextPageToken?.length > 0) {
        await this.fetchApproveHistories(result.data.nextPageToken);
      } else {
        this.asyncLoadingApprove = false;
      }
    } catch (e) {
      console.error("fetchApproveHistories exception catch", e);
    }
  }

  // 承認要求権限での 承認要求リスト＋承認却下リスト取得
  async fetchRequestHistories(pageToken: string = ""): Promise<void> {
    try {
      const result = await requiredAuth<ControllersRequestHistoriesOutput>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.poiGroupId
        }/post/request/histories`,
        { pageToken: pageToken, limit: this.historiesLimit }
      );
      this.rowRejects = this.rowRejects.concat(
        result.data?.results?.filter((row) => row.state === "JUDGEMENTREJECT")
      );
      this.rowRequests = this.rowRequests.concat(
        result.data?.results?.filter((row) => row.state === "JUDGEMENTREQUEST")
      );

      this.filterApproves();
      if (result.data?.nextPageToken?.length > 0) {
        await this.fetchRequestHistories(result.data.nextPageToken);
      } else {
        this.asyncLoadingApprove = false;
      }
    } catch (e) {
      console.error("fetchRequestHistories exception catch", e);
    }
  }

  async filters(): Promise<void> {
    await this.filterHistories();
    await this.filterApproves();
  }
  // 検索キーワード
  searchRange = {
    from: dayjs().add(-1, "year").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  };
  searchWord: string = "";
  // 検索期間・検索キーワードを適用してフィルターリストを更新する
  async filterHistories(): Promise<void> {
    this.histories = [...this.filterData(this.rowHistories)];
    // モバイルとPC版で子コンポーネントを発火させる
    (this.$refs.historiesTable as InstanceType<typeof HistoriesTable>)?.updateHistories();
  }
  async clearSearchWord(): Promise<void> {
    this.searchWord = "";
    await this.filters();
  }
  async filterApproves(): Promise<void> {
    this.requests = [...this.filterData(this.rowRequests)];
    (this.$refs.requestsTable as InstanceType<typeof HistoriesTable>)?.updateHistories();

    this.rejects = [...this.filterData(this.rowRejects)];
    (this.$refs.rejectsTable as InstanceType<typeof HistoriesTable>)?.updateHistories();
  }

  filterData(target: EntitiesV2HistoryPostData[]): EntitiesV2HistoryPostData[] {
    const filterHistories = target.filter(
      (row) =>
        (!this.searchRange.from || row.createDateTime >= this.searchRange.from) &&
        (!this.searchRange.to || row.createDateTime <= this.searchRange.to + "T23:59:59Z") &&
        (!this.searchWord || this.contains(row, this.searchWord))
    );
    return filterHistories;
  }

  // valuesにkeyが含まれている場合にtrueを返す
  private containsKeys(key: string, ...values: string[]): boolean {
    for (const value of values) {
      if (value?.includes(key)) {
        return true;
      }
    }
    return false;
  }
  // 投稿データにkeyが含まれている場合はtrueを返す
  contains(item: EntitiesV2HistoryPostData, key: string): boolean {
    if (
      this.containsKeys(
        key,
        item?.title,
        this.convertState(item?.state),
        this.convertState(item?.gbpState),
        this.convertState(item?.yahooState),
        this.convertState(item?.fbState),
        this.convertState(item?.igState),
        this.convertDateTime(item?.createDateTime),
        this.convertDateTime(item?.posting),
        this.convertDateTime(item?.cancellationReservation),
        item?.userName,
        item?.user,
        item?.updateUser,
        item?.lastUpdateUserName
      )
    ) {
      return true;
    }
    if (item?.gbpState != "NOTUSE" && item?.GMB) {
      if (key.toLowerCase() == "gmb" || key.toLowerCase() == "gbp") {
        return true;
      }
      if (
        item?.GMB?.info &&
        this.containsKeys(
          key,
          item?.GMB?.info?.title,
          item?.GMB?.info?.button,
          item?.GMB?.info?.buttonUrl
        )
      ) {
        return true;
      }
      if (item?.GMB?.benefits) {
        if (
          this.containsKeys(
            key,
            item?.GMB?.benefits?.title,
            item?.GMB?.benefits?.start,
            item?.GMB?.benefits?.end
          )
        ) {
          return true;
        }
        if (
          item?.GMB?.benefits?.details &&
          this.containsKeys(
            key,
            item?.GMB?.benefits?.details?.detail,
            item?.GMB?.benefits?.details?.link,
            item?.GMB?.benefits?.details?.couponCode,
            item?.GMB?.benefits?.details?.termsOfService
          )
        ) {
          return true;
        }
      }
      if (
        item?.GMB?.covid19 &&
        this.containsKeys(
          key,
          item?.GMB?.covid19?.title,
          item?.GMB?.covid19?.button,
          item?.GMB?.covid19?.buttonUrl
        )
      ) {
        return true;
      }
      if (item?.GMB?.event) {
        if (
          this.containsKeys(
            key,
            item?.GMB?.event?.title,
            item?.GMB?.event?.start,
            item?.GMB?.event?.startTime,
            item?.GMB?.event?.end,
            item?.GMB?.event?.endTime
          )
        ) {
          return true;
        }
        if (
          item?.GMB?.event?.details &&
          this.containsKeys(
            key,
            item?.GMB?.event?.details?.detail,
            item?.GMB?.event?.details?.button,
            item?.GMB?.event?.details?.buttonUrl
          )
        ) {
          return true;
        }
      }
      if (
        item?.GMB?.product &&
        this.containsKeys(
          key,
          item?.GMB?.product?.title,
          item?.GMB?.product?.price,
          item?.GMB?.product?.maxPrice,
          item?.GMB?.product?.category,
          item?.GMB?.product?.explain,
          item?.GMB?.product?.button,
          item?.GMB?.product?.buttonUrl
        )
      ) {
        return true;
      }
    }
    if (item?.fbState != "NOTUSE" && item?.Facebook) {
      if (key.toLowerCase() == "facebook") {
        return true;
      }
      if (this.containsKeys(key, item?.Facebook?.text, item?.Facebook?.link)) {
        return true;
      }
    }
    if (item?.igState != "NOTUSE" && item?.Instagram) {
      if (key.toLowerCase() == "instagram") {
        return true;
      }
      if (this.containsKeys(key, item?.Instagram?.text, item?.Instagram?.imageUrl)) {
        return true;
      }
    }
    if (item?.yahooState != "NOTUSE" && item?.Yahoo) {
      if (key.toLowerCase() == "yahoo") {
        return true;
      }
      if (this.containsKeys(key, item?.Yahoo?.title, item?.Yahoo?.text)) {
        return true;
      }
    }
    if (item?.hostingState != "NOTUSE" && item?.Hosting) {
      if (key.toLowerCase() == "hosting") {
        return true;
      }
      if (this.containsKeys(key, item?.Hosting?.title, item?.Hosting?.text)) {
        return true;
      }
    }
    if (item?.fileNames?.length > 0 && this.containsKeys(key, ...(item?.fileNames ?? []))) {
      return true;
    }
    return false;
  }
  // 表示されている投稿件数を返す
  showHistories(): number {
    return this.histories.length;
  }
  exportFile(): void {
    // Sheet.js を用いて xlsx ファイルを作成する
    // Array of arrays
    const aoa = [
      [
        "登録日時",
        "タイトル",
        "掲載開始日時",
        "掲載終了日時",
        "GBP種別",
        "状態",
        "GBPエラー数",
        "GBP完了数",
        "GBP店舗数",
        "Yahoo!プレイスエラー数",
        "Yahoo!プレイス完了数",
        "Yahoo!プレイス店舗数",
        "FBエラー数",
        "FB完了数",
        "FB店舗数",
        "IGエラー数",
        "IG完了数",
        "IG店舗数",
        "ホスティングエラー数",
        "ホスティング完了数",
        "ホスティング店舗数",
        "最終更新ユーザー",
      ],
    ];
    // フィルタされた内容をXLSXエクスポート
    for (const row of this.histories) {
      aoa.push([
        this.convertDateTime(row.createDateTime),
        row.title,
        this.convertDateTime(row.posting),
        this.convertDateTime(row.cancellationReservation),
        this.getGBPType(row),
        this.convertState(row.state),
        row.gbpErrorCount.toString(),
        row.gbpPostCount.toString(),
        row.gbpStoreCount.toString(),
        row.yahooErrorCount.toString(),
        row.yahooPostCount.toString(),
        row.yahooStoreCount.toString(),
        row.fbErrorCount.toString(),
        row.fbPostCount.toString(),
        row.fbStoreCount.toString(),
        row.igErrorCount.toString(),
        row.igPostCount.toString(),
        row.igStoreCount.toString(),
        row.hostingErrorCount.toString(),
        row.hostingPostCount.toString(),
        row.hostingStoreCount.toString(),
        this.getUserName(row),
      ]);
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const buf: ArrayBuffer = XLSX.write(wb, {
      type: "array",
      bookType: "xlsx",
      bookSST: true,
    });
    const companyName = useIndexedDb().company.name;
    const dateString = dayjs().format("YYYYMMDD");
    saveAs(
      new Blob([buf], { type: "application/octet-stream" }),
      `${wordDictionary.service.name}-投稿一覧エクスポート-${companyName}-${dateString}.xlsx`
    );
  }
  // 編集ボタンをクリックされたら呼び出される
  postEdit(item: EntitiesV2HistoryPostData): void {
    useIndexedDb().setPostRow(item);
    if (item?.xlsxMode) {
      this.router.push({
        name: "V2PostFile",
        query: {
          filename: item.fileName,
        },
        params: {
          poiGroupId: `${this.poiGroupId}`,
        },
      });
    } else {
      // postEdit 既存のv2投稿編集画面へ遷移させる
      this.router.push({
        name: "V2PostsEdit",
        params: { poiGroupId: `${this.poiGroupId}`, mode: "edit" },
      });
    }
  }
  // 削除ボタンをクリックされたら呼び出される
  postDelete(item: EntitiesV2HistoryPostData): void {
    this.showConfirm(item, "投稿", "削除", this.delete);
  }
  // 再実行ボタンをクリックされたら呼び出される
  async postRetry(item: EntitiesV2HistoryPostData): Promise<void> {
    if (item.cancellationReservation) {
      const d = new Date(item.cancellationReservation);
      if (new Date().getTime() >= d.getTime()) {
        this.addSnackbarMessages({
          text: "掲載終了日時を過ぎているため再実行できません。投稿を編集するか削除してください。",
          color: "danger",
        });
        return;
      }
    }

    // GBPへの反映を確認できなかった投稿があればワーニングを表示する
    this.unknownStatusPost = false;
    await this.checkUnknownStatus(item);
    const unknownMessage = this.unknownStatusPost
      ? "GBP側への投稿の反映を確認できなかった店舗があります。GBP管理画面で投稿を確認いただき、投稿に失敗していれば再実行してください。"
      : "";
    this.showConfirm(item, "投稿", "再実行", this.retry, false, "", unknownMessage);
  }

  // 承認待ちリストの投稿承認要求を再度編集する(承認要求者本人が再度編集)
  requestPostEdit(item: EntitiesV2HistoryPostData): void {
    useIndexedDb().setPostRow(item);
    if (item?.xlsxMode) {
      this.router.push({
        name: "V2PostFile",
        query: {
          filename: item.fileName,
          mode: "request",
        },
        params: { poiGroupId: `${this.poiGroupId}` },
      });
    } else {
      // postEdit 既存のv2投稿編集画面へ遷移させる
      this.router.push({
        name: "V2PostsEdit",
        params: { poiGroupId: `${this.poiGroupId}`, mode: "request" },
      });
    }
  }
  // 承認待ちリストの投稿承認要求を削除する(承認要求者本人が削除する)
  async requestPostDelete(item: EntitiesV2HistoryPostData): Promise<void> {
    /**
     * 承認要求が必要なのは、GBP/FB/IGの状態を変えるときだけ
     * GBP/FB/IGの状態を変えない変更は承認が不要
     */
    switch (item.state) {
      case "JUDGEMENTREQUEST":
        this.showConfirm(item, "承認要求", "削除", this.delete);
        break;
      case "JUDGEMENTREJECT":
        this.showConfirm(item, "却下された承認要求", "削除", this.delete);
        break;
      case "INCOMPLETE":
        // 一時保存(新規)はまだ投稿されていない状態なので承認要求不要
        this.showConfirm(item, "下書き状態の投稿", "削除", this.delete);
        break;
      default:
        this.showConfirm(
          item,
          "投稿",
          "削除依頼",
          this.delete,
          true,
          "削除依頼です（システムによる自動入力）"
        );
        break;
    }
  }
  // 承認待ちリストでのレビューボタンクリック
  requestPostReview(item: EntitiesV2HistoryPostData): void {
    useIndexedDb().setPostRow(item);
    if (item?.xlsxMode) {
      // ファイル一括投稿
      this.router.push({
        name: "V2PostFile",
        query: {
          filename: item.fileName,
          mode: "review",
          aspectNoCheck: `${item?.aspectNoCheck}`,
        },
        params: { poiGroupId: `${this.poiGroupId}` },
      });
    } else {
      // 通常投稿
      this.router.push({
        name: "V2PostsEdit",
        params: { poiGroupId: `${this.poiGroupId}`, mode: "review" },
      });
    }
  }
  // 承認却下リストの却下済み投稿を再度編集する(承認要求者本人が再度編集)
  rejectPostEdit(item: EntitiesV2HistoryPostData): void {
    useIndexedDb().setPostRow(item);
    if (item?.xlsxMode) {
      this.router.push({
        name: "V2PostFile",
        query: {
          filename: item.fileName,
          mode: "request",
          aspectNoCheck: `${item?.aspectNoCheck}`,
        },
        params: { poiGroupId: `${this.poiGroupId}` },
      });
    } else {
      // postEdit 既存のv2投稿編集画面へ遷移させる
      this.router.push({
        name: "V2PostsEdit",
        params: { poiGroupId: `${this.poiGroupId}`, mode: "request" },
      });
    }
  }
  // 承認却下リストの却下済み投稿を削除する(承認要求者本人が削除する)
  rejectPostDelete(item: EntitiesV2HistoryPostData): void {
    this.showConfirm(item, "却下された承認要求", "削除", this.delete);
  }
  // 承認却下リストでの再レビューボタンクリック(間違って却下したなど)
  rejectPostReview(item: EntitiesV2HistoryPostData): void {
    useIndexedDb().setPostRow(item);
    if (item?.xlsxMode) {
      // ファイル一括投稿
      this.router.push({
        name: "V2PostFile",
        query: {
          filename: item.fileName,
          mode: "review",
          aspectNoCheck: `${item?.aspectNoCheck}`,
        },
        params: { poiGroupId: `${this.poiGroupId}` },
      });
    } else {
      // 通常投稿
      this.router.push({
        name: "V2PostsEdit",
        params: { poiGroupId: `${this.poiGroupId}`, mode: "review" },
      });
    }
  }

  private async checkUnknownStatus(item: EntitiesV2HistoryPostData): Promise<void> {
    const statusList = item.fileNames?.map(async (item) => {
      const pathList = item.split("/");
      const unknownStatus = await this.hasUnknownStatus(pathList[3], pathList[4], pathList[5]);
      if (unknownStatus) {
        this.unknownStatusPost = true;
      }
    });
    if (statusList?.length > 0) {
      await Promise.all(statusList);
    }
  }

  private async hasUnknownStatus(
    userUUID: string,
    uuid: string,
    fileName: string
  ): Promise<boolean> {
    const unknownMessages: string[] = [
      "GBP側への投稿の反映を確認できなかったため、投稿に失敗した可能性があります。",
      "GBP側でエラーが発生した為、投稿に失敗しました。システムまでお問い合わせください",
    ];
    var hasUnknown = false;
    try {
      const result = await requiredAuth<ControllersHistoryDetailsOutput>(
        "get",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
          this.poiGroupId
        }/post/histories/details/${userUUID}/${uuid}/${fileName}`
      );
      if (result?.data?.file?.includes("gmb_") && result?.data?.details.length > 0) {
        for (const detail of result.data.details) {
          for (const unknownMessage of unknownMessages) {
            if (detail.message.indexOf(unknownMessage) === 0) {
              hasUnknown = true;
              break;
            }
          }
          if (hasUnknown) {
            break;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }

    return hasUnknown;
  }

  // 確認ダイアログの表示
  showConfirm(
    item: EntitiesV2HistoryPostData,
    target: string,
    processName: string,
    method: (item: EntitiesV2HistoryPostData, uuidList: string[], remark: string) => Promise<void>,
    isApproveRequest: boolean = false,
    defaultRemark: string = "",
    warning?: string
  ): void {
    this.modalProps = {
      date: this.convertDateTime(item.createDateTime),
      title: item.title,
      processName,
      target,
      row: item,
      method: method,
      warning,
      isManualApproveSelect: this.isManualApproveSelect,
      isApproveRequest: isApproveRequest,
      defaultRemark: defaultRemark,
    };
    this.isModalActive = true;
  }
  showReloadConfirm(): void {
    this.reloadModalProps = {
      method: this.reload,
    };
    this.isReloadModalActive = true;
  }
  // リトライ要求を送信
  async retry(item: EntitiesV2HistoryPostData, uuidList: string[]): Promise<void> {
    this.isModalActive = false;
    // v2-post/5/history/xxxx/yyyy.json を / でパースしてxxxxとyyyy.jsonを取り出す
    try {
      const fs = item.fileName.split("/");
      const oplogParam = getOperationLogParams(
        this.route,
        determineRetryActionType(item),
        "v2_post"
      );
      const res = await requiredAuth<ControllersRetryOutput>(
        "put",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post/histories/retry/${
          fs[3]
        }/${fs[4]}`,
        { status: item.state, ...oplogParam }
      );
      if (res.status == 205) {
        this.showReloadConfirm();
      } else {
        this.addSnackbarMessages({
          text: "再実行を開始しました",
          color: "success",
        });
        this.fetch();
      }
    } catch (e) {
      console.log(e);
      this.addSnackbarMessages({
        text: `再実行に失敗しました ${e}`,
        color: "danger",
      });
    }
  }
  // 削除要求を送信
  async delete(
    item: EntitiesV2HistoryPostData,
    requestWantsUserList: string[],
    requestRemark: string = ""
  ): Promise<void> {
    this.isModalActive = false;
    // v2-post/5/history/xxxx/yyyy.json を / でパースしてxxxxとyyyy.jsonを取り出す
    try {
      const fs = item.fileName.split("/");
      const data: ControllersHistoryDeleteInput = {
        judgementWantUserNames: requestWantsUserList,
        judgementRequestRemark: requestRemark,
      };
      const actionType = await determineDeleteActionType(item, this.isRequestAuthorization);
      const oplogParam = getOperationLogParams(this.route, actionType, "v2_post");
      const res = await requiredAuth<ControllersHistoryDeleteOutput>(
        "delete",
        `${import.meta.env.VITE_APP_API_BASE}v2/companies/${this.poiGroupId}/post/histories/${
          fs[3]
        }/${fs[4]}`,
        { status: item.state, ...oplogParam },
        data
      );
      if (res.status == 205) {
        this.showReloadConfirm();
      } else {
        this.addSnackbarMessages({
          text: "削除を開始しました",
          color: "success",
        });
        this.fetch();
      }
    } catch (e) {
      console.log(e);
      this.addSnackbarMessages({
        text: `削除に失敗しました ${e}`,
        color: "danger",
      });
    }
  }

  closeModal(): void {
    this.isModalActive = false;
  }

  toggleMediaCount(): void {
    this.showMediaCount = !this.showMediaCount;
    action().setIsShowMediaCount(this.showMediaCount);
  }
}
export default toNative(V2PostHistories);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

%fill-content {
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

%base-content {
  @extend %fill-content;
  display: block;
}

#contents {
  @extend %base-content;
}

.content-wrapper {
  @extend %base-content;
}

.v2histories-contains {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: calc(100vh - 92px);
}

.v2Histories-header {
  display: block;
}

.tabs-container {
  height: 40px;
}

.store-tabs {
  display: flex;
  height: 40px;

  button {
    display: block;
  }

  .media-toggle {
    margin-left: auto;
    color: #6e6e6e;
    font-weight: 400;
  }
}

.v2histories-list {
  height: 100%;
  display: flex;
}
</style>
