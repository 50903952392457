<template>
  <div>
    <div>
      <span class="text-h6 font-weight-bold">Apple Business Connect (Apple Maps) 店舗情報</span>
      <v-btn
        class="ml-4 mt-n2"
        data-testid="hint-btn"
        size="small"
        :color="cautionColor"
        text="各項目の注意点について"
        prepend-icon="fas fa-exclamation-triangle"
        @click="showTopInfo = !showTopInfo"
      />
    </div>
    <v-alert
      v-model="showTopInfo"
      data-testid="hint"
      border="start"
      :border-color="cautionColor"
      colored-border
      closable
      class="m-3"
      style="font-size: small"
    >
      ・電話番号・住所などの基本情報は
      <router-link :to="{ name: 'StoresSpreadForm' }">基本情報画面</router-link>
      から、WebサイトURLは
      <router-link :to="{ name: 'MediaLink' }">メディアリンク画面</router-link>
      から更新できます。
      <br />
      <template v-for="(message, index) in caution" :key="index">
        ・{{ message }}
        <br />
      </template>
    </v-alert>
    <div class="d-flex align-center mb-2">
      <auto-complete-card
        v-if="!canShowAllowedStoresOnly"
        v-model="filter.areaIds"
        :items="areaItems"
        label="グループを選択して下さい"
        unit="グループ"
        show-all-select
        :chip-count="6"
        hint=""
        :persistent-hint="false"
        hide-details
        single-line
        density="compact"
        :clearable="false"
        data-testid="area-selector"
        class="mr-1"
        style="max-width: 250px"
        @update:model-value="updateFilter"
      />
      <v-text-field
        v-model="filter.searchWord"
        label="検索キーワード"
        variant="underlined"
        density="compact"
        single-line
        hide-details
        clearable
        prepend-inner-icon="mdi-magnify"
        color="primary"
        class="mr-2 w-20"
        style="width: 200px; max-width: 200px"
        @keypress.enter="updateFilter"
        @click:clear="
          filter.searchWord = '';
          updateFilter();
        "
      />
      <v-btn size="small" class="primary me-auto" @click="updateFilter()">絞り込み</v-btn>
      <v-checkbox
        v-if="canManageInfo"
        v-model="filter.onlyDirtyRows"
        class="me-1"
        color="primary"
        hide-details
        data-testid="only-dirty-rows"
        @update:model-value="updateFilter"
      >
        <template #label>変更行のみ表示</template>
      </v-checkbox>
      <v-btn
        v-if="canManageInfo"
        :disabled="!isDirty || isLoading"
        size="small"
        class="primary ml-5"
        data-testid="submit-btn"
        @click="submit"
      >
        変更内容を反映
      </v-btn>
      <!-- レイアウトの関係で閲覧のみの場合はこちらにエクスポートボタンを持ってきている -->
      <v-btn
        v-else
        :disabled="isLoading"
        size="small"
        class="primary me-3"
        prepend-icon="mdi-download"
        text="XLSXエクスポート"
        data-testid="export-btn"
        @click="exportXlsx"
      />
    </div>
    <div v-if="canManageInfo" class="d-flex align-center mb-2">
      <v-btn
        v-if="prevErrorMessage.length > 0"
        size="small"
        class="primary me-1"
        data-testid="show-prev-error-btn"
        @click="
          dialog.title = '前回反映時のエラー';
          dialog.show = true;
        "
      >
        前回反映時のエラーを表示
      </v-btn>
      <input ref="importInput" type="file" hidden @change="importXlsx" />
      <v-btn
        :disabled="isLoading"
        size="small"
        class="primary ml-auto me-1"
        prepend-icon="mdi-upload"
        text="XLSXインポート"
        data-testid="import-btn"
        @click="($refs.importInput as HTMLInputElement).click()"
      />
      <v-btn
        :disabled="isLoading"
        size="small"
        class="primary me-1"
        prepend-icon="mdi-download"
        text="XLSXエクスポート"
        data-testid="export-btn"
        @click="exportXlsx"
      />
    </div>
    <div style="height: calc(100vh - 240px)" class="parent-container">
      <div v-if="isLoading" class="custom-progress-circular-container">
        <v-progress-circular indeterminate size="80" :width="4" color="primary" />
      </div>
      <div class="custom-grid">
        <c-grid
          ref="grid0"
          class="cgrid"
          font="12.8px sans-serif"
          :frozen-col-count="3"
          :theme="customTheme"
          style="font-size: small"
          :data="dataSource"
          @resize="grid.updateSize()"
        >
          <c-grid-column
            caption="店舗ID"
            :field="(item: Row) => item.poiID"
            :column-style="columnStyle"
          />
          <c-grid-column
            caption="店舗コード"
            field="storeCode"
            :width="100"
            :column-style="columnStyle"
          />
          <c-grid-column
            caption="ビジネス名"
            field="storeName"
            :width="210"
            :column-style="columnStyle"
          />
          <c-grid-column
            caption="メインカテゴリ"
            :field="(item: Row) => item.primaryCategory?.name"
            :width="100"
            :column-style="bgStyle('primaryCategory')"
            :action="setPrimaryCategories"
          />
          <c-grid-column
            caption="追加カテゴリ"
            :field="(item: Row) => item.additionalCategories.map((c) => c.name).join(DELIMITER)"
            :width="100"
            :column-style="bgStyle('additionalCategories')"
            :action="setAdditionalCategories"
          />
          <c-grid-column
            caption="ビジネス情報"
            field="description"
            :width="210"
            :column-style="bgStyle('description')"
            :action="(item: Row) => {descriptionSetDialog.row = item; descriptionSetDialog.show = true;}"
          />
          <c-grid-input-column
            caption="緯度"
            field="latitude"
            :width="100"
            :column-style="bgStyle('latitude')"
            :input-validator="latlonValidator"
            :readonly="(item: Row) => item.isReadOnly('latitude') || !canManageInfo"
          />
          <c-grid-input-column
            caption="経度"
            field="longitude"
            :width="100"
            :column-style="bgStyle('longitude')"
            :input-validator="latlonValidator"
            :readonly="(item: Row) => item.isReadOnly('longitude') || !canManageInfo"
          />
          <!-- 以下の項目はこの画面では更新できない -->
          <c-grid-column
            caption="電話番号"
            field="primaryPhone"
            :width="120"
            :column-style="bgStyle('primaryPhone')"
          />
          <c-grid-column
            caption="追加電話番号"
            :field="(item: Row) => item.additionalPhones?.join(DELIMITER)"
            :width="210"
            :column-style="bgStyle('additionalPhones')"
          />
          <c-grid-column
            caption="住所"
            field="address"
            :width="210"
            :column-style="bgStyle('address')"
          />
          <c-grid-column
            caption="ステータス"
            :field="(item: Row) => locationStatusDict[item.status]"
            :width="100"
            :column-style="bgStyle('status')"
          />
        </c-grid>
      </div>
      <!-- カテゴリ設定ダイアログ -->
      <categories-set-dialog
        :is-open="categoriesSetDialog.show"
        :poi-i-d="categoriesSetDialog.poiID"
        :is-primary="categoriesSetDialog.isPrimary"
        :categories="categoriesSetDialog.categories"
        :selected-categories="categoriesSetDialog.selectedCategories"
        @cancel="categoriesSetDialog.show = false"
        @submit="setCategories"
      />
      <!-- ビジネス情報入力ダイアログ -->
      <text-set-dialog
        :is-open="descriptionSetDialog.show"
        :row="descriptionSetDialog.row"
        :field="descriptionSetDialog.field"
        :max-count="descriptionSetDialog.maxCount"
        :validate-rules="descriptionSetDialog.rules"
        @update:text="descriptionSetDialog.row.description = $event"
        @close="descriptionSetDialog.show = false"
      />
    </div>
    <!-- 反映ダイアログ -->
    <ProgressDialog
      v-model="dialog.show"
      :title="dialog.title"
      :message="dialog.message"
      :percentage="dialog.percentage"
      submit-button="OK"
      :submit-button-disabled="dialog.submitButtonDisabled"
      max-width="600"
      @submit="dialog.show = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import * as cg from "cheetah-grid";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import wordDictionary from "@/word-dictionary";
import { useIndexedDb } from "@/storepinia/idxdb";
import { useSnackbar } from "@/storepinia/snackbar";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import {
  createDataUpdateGridTheme,
  dataUpdateColumnStyle,
} from "@/components/shared/cheetah-grid-shared";
import { arrayBufferToStringsArrays, read } from "@/helpers/xlsxtools";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";
import { api as abcapi, BATCH_GET_LIMIT } from "@/helpers/api/apple";
import { getAppleLocationID, getErrorMessageList } from "@/helpers/apple";
import { validateMaxLength, validateDecimalPoint, VuetifyValidator } from "@/helpers/validator";
import { getOperationLogParams } from "@/routes/operation-log";
import { AppleCategoryMaster, columns, Row, locationStatusDict, DELIMITER } from "./model";
import type { ApplePatchData, Columnx } from "./model";
import CategoriesSetDialog from "./categories-set-dialog.vue";
import TextSetDialog from "./text-set-dialog.vue";
import ProgressDialog from "@/components/root/contents/media-link/progress-dialog.vue";

import type { ListGrid } from "cheetah-grid";
import type { AxiosError } from "axios";
import type {
  EntitiesAppleCategory,
  EntitiesAppleLocation,
  EntitiesErrorResponse,
} from "@/types/ls-api";
import { getAppleErrorMessage } from "@/helpers/error";
import { useRoute, useRouter } from "vue-router";

class FilterDataSource extends cg.data.FilterDataSource<Row> {}
type Err = { poiId: number; storeName: string; message: string };

export default defineComponent({
  components: { AutoCompleteCard, CategoriesSetDialog, TextSetDialog, ProgressDialog },
  data() {
    const customTheme = createDataUpdateGridTheme();
    return {
      $route: useRoute(),
      $router: useRouter(),
      DELIMITER,
      cautionColor: "#ffe08a",
      isLoading: false as boolean,
      categoryMaster: null as AppleCategoryMaster,
      locationStatusDict,
      areaItems: [] as SelectItem[],
      rows: [] as Row[],
      dataSource: null as FilterDataSource,
      errs: [] as Err[],
      isDirtyTrigger: ref(0), // isDirtyを強制的に再計算させるためのトリガー
      filter: {
        searchWord: "",
        areaIds: [] as number[],
        onlyDirtyRows: false,
        onlyLinked: true,
      },
      customTheme,
      columnStyle: dataUpdateColumnStyle,
      prevErrorMessage: "",
      showTopInfo: false,
      dialog: {
        show: false,
        percentage: 0,
        title: "",
        message: "",
        submitButtonDisabled: true,
      },
      categoriesSetDialog: {
        show: false,
        poiID: 0,
        isPrimary: false,
        categories: [] as EntitiesAppleCategory[],
        selectedCategories: [] as EntitiesAppleCategory[],
      },
      descriptionSetDialog: {
        show: false,
        row: null as Row,
        field: "description",
        maxCount: 500,
        rules: [VuetifyValidator.maxLength(500)],
      },
      descriptionValidator: (value: string) => {
        return validateMaxLength(value, 500);
      },
      latlonValidator: (value: string) => {
        return validateDecimalPoint(value, 5, 7);
      },
      caution: [
        "メインカテゴリはApple Business Connectのブランドのカテゴリが適用されるため変更できません。",
        `追加カテゴリは複数選択可能です。XLSXインポート時は ${DELIMITER} で区切って記載してください。`,
        "ビジネス情報は500字以内で記入してください。Google Business Profileと字数制限が異なるのでご注意ください。",
        "緯度・経度は小数点以下の桁数が７桁未満の場合、ゼロ埋めされます。",
      ],
    };
  },
  computed: {
    poiGroupId: function () {
      return parseInt(this.$route.params.poiGroupId as string, 10);
    },
    company: function () {
      return useIndexedDb().company;
    },
    stores: function () {
      return useIndexedDb().stores?.stores.filter((s) => s.enabled) ?? [];
    },
    canManageInfo: function () {
      // 基本情報の編集権限があればOK
      return useIndexedDb().appleEnabled && useIndexedDb().canManageStoreLocations;
    },
    canShowAllowedStoresOnly: function () {
      return useIndexedDb().canShowAllowedStoresOnly;
    },
    grid: function (): ListGrid<Row> {
      return this.$refs.grid0 as ListGrid<Row>;
    },
    isDirty: function (): boolean {
      this.isDirtyTrigger; // 依存関係に追加することで変更を検知
      for (const c of columns) {
        if (!c.abcLocation) continue;
        // isDirtyは更新契機が限られるのでbefore/afterを比べる必要がある
        if (this.rows.some((r) => r.hasFieldChanged(c.field as string))) {
          return true;
        }
      }
      return false;
    },
  },
  async created() {
    await this.fetchCategories();
  },
  async mounted() {
    // グループプルダウン作成
    ({ areas: this.areaItems } = makeSelectItems(
      useIndexedDb().areaStores,
      useIndexedDb().stores?.stores,
      false
    ));
    this.grid.updateSize();
    this.grid.invalidate();
    try {
      await this.fetch();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      const locations: { [locationID: string]: EntitiesAppleLocation } = {};
      const locationIds = this.stores
        .filter((s) => s.appleBusinessConnect?.locationId != null)
        .map((s) => s.appleBusinessConnect?.locationId);
      for (let i = 0; i < locationIds.length; i += BATCH_GET_LIMIT) {
        try {
          const res = await abcapi.batchGet(
            this.poiGroupId,
            locationIds.slice(i, i + BATCH_GET_LIMIT)
          );
          const errorMessageList = getErrorMessageList(res.error);
          if (errorMessageList.length > 0) {
            useSnackbar().addSnackbarMessages({
              text: `Appleの情報取得に失敗しました: ${errorMessageList.join("<br>")}`,
              color: "danger",
            });
            return;
          }
          res.data.data.forEach((location) => {
            locations[getAppleLocationID(location)] = location;
          });
        } catch (e) {
          const error = e as AxiosError<any>;
          useSnackbar().addSnackbarMessages({
            text: `Appleの情報取得に失敗しました: ${error.response?.data?.errorMessage}`,
            color: "danger",
          });
          return;
        }
      }
      // テーブルに表示するデータを作成
      const rows: Row[] = [];
      for (const s of this.stores) {
        const row = new Row(s, this.categoryMaster);
        row.set(locations[row.appleLocationID]);
        rows.push(row);
      }
      this.rows = rows;
      this.dataSource = new FilterDataSource(cg.data.DataSource.ofArray(rows), this.makeFilter());
    },
    async fetchCategories() {
      this.isLoading = true;
      try {
        const res = await abcapi.listCategories(this.poiGroupId);
        this.categoryMaster = new AppleCategoryMaster(res);
        this.categoriesSetDialog.categories = this.categoryMaster.categories;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    makeFilter(): (row: Row) => boolean {
      return (row: Row): boolean => {
        if (
          this.filter.areaIds.length > 0 &&
          !this.filter.areaIds.some((value) => row.areas.includes(value))
        ) {
          return false;
        }
        const word = [
          row.poiID,
          row.storeCode,
          row.storeName,
          row.description,
          row.primaryCategory,
          row.additionalCategories,
          row.primaryPhone,
          row.additionalPhones,
          row.address,
          row.status,
        ].toString();
        if (this.filter.searchWord && !word.includes(this.filter.searchWord)) {
          return false;
        }
        if (this.filter.onlyDirtyRows && !row.isDirty) {
          return false;
        }
        if (this.filter.onlyLinked && row.appleLocationID == "") {
          return false;
        }
        return true;
      };
    },
    async updateFilter() {
      // データが無い場合は何もしない(fetch時に入力条件でフィルタされる)
      if (this.dataSource == null) return;
      // ダーディフラグを更新
      const rows = this.dataSource.dataSource;
      for (let i = 0; i < rows.length; i++) {
        const row = await rows.get(i);
        row.updateIsDirty();
      }
      this.isDirtyTrigger++;
      // フィルターをセットして再描画
      this.dataSource.filter = this.makeFilter();
      this.grid.invalidate();
    },
    resetFilter() {
      this.filter.searchWord = "";
      this.filter.areaIds = [];
      this.filter.onlyDirtyRows = false;
      this.filter.onlyLinked = true;
    },
    /** 入力可能なカラムの style */
    bgStyle: function (key: string): (rec: Row) => { bgColor: string } {
      return function (row: Row): { bgColor: string } {
        if (!row) return null;
        return {
          bgColor: row.isReadOnly(key) ? "#f0f0f0" : row.hasFieldChanged(key) ? "#AFF" : "",
        };
      };
    },
    setPrimaryCategories(item: Row) {
      if (item.isReadOnly("primaryCategory") || !this.canManageInfo) return;
      this.openCategoriesDialog(item, true);
    },
    setAdditionalCategories(item: Row) {
      if (item.isReadOnly("additionalCategories") || !this.canManageInfo) return;
      this.openCategoriesDialog(item, false);
    },
    openCategoriesDialog(item: Row, isPrimary: boolean) {
      this.categoriesSetDialog.poiID = item.poiID;
      this.categoriesSetDialog.isPrimary = isPrimary;
      const categoriesList = isPrimary ? [item.primaryCategory] : item.additionalCategories;
      this.categoriesSetDialog.selectedCategories.splice(
        0,
        this.categoriesSetDialog.selectedCategories.length
      );
      categoriesList.forEach((c) => {
        const category = this.categoryMaster.find(c.qualifiedId);
        if (category != null) {
          this.categoriesSetDialog.selectedCategories.push(category);
        }
      });
      this.categoriesSetDialog.show = true;
    },
    setCategories(poiID: number, categories: string[], isPrimary: boolean) {
      this.categoriesSetDialog.show = false;
      this.rows.find((r) => r.poiID === poiID).setCategories(categories, isPrimary);
      this.grid.invalidate();
    },
    async submit() {
      this.resetFilter();
      this.errs = [];
      await this.$nextTick();
      this.dialog.percentage = 0;
      this.dialog.show = true;
      this.dialog.submitButtonDisabled = true;
      this.dialog.title = "変更内容を反映中";
      this.dialog.message = "";

      const patchDataList: ApplePatchData[] = [];
      for (const row of this.rows) {
        const patchData = row.createPatchData();
        if (patchData.mask.length === 0) continue;
        patchDataList.push(patchData);
      }

      await this.patchProcess(patchDataList);
      this.dialog.title = "反映が完了しました";
      this.dialog.message = `${patchDataList.length} / ${patchDataList.length}`;
      if (this.errs.length > 0) {
        this.dialog.title += " (エラーあり)";
        this.dialog.message +=
          "<br>以下の店舗はエラーがあり反映できませんでした。<br>エラー内容をご確認の上、修正と再反映をお試しください。<hr>";
        this.errs.forEach((e) => {
          this.dialog.message += `<br><b>${e.poiId}</b> ${e.storeName}<br>${e.message}`;
        });
        this.prevErrorMessage = this.dialog.message;
      } else {
        this.prevErrorMessage = "";
      }
      this.dialog.submitButtonDisabled = false;
    },
    async patchProcess(patchDataList: ApplePatchData[]) {
      let count = 0;
      const processing: { [key: string]: string } = patchDataList.reduce((acc, patchData) => {
        acc[patchData.poiId] = patchData.storeName;
        return acc;
      }, {});
      const oplogParams = getOperationLogParams(this.$route, "apple-patch");
      for (const patchData of patchDataList) {
        count++;
        this.dialog.percentage = Math.floor((count / patchDataList.length) * 100);
        this.dialog.message =
          `${count} / ${patchDataList.length}<br>以下の店舗を反映中<br>` +
          Object.values(processing).join("<br/>");
        delete processing[patchData.poiId];
        try {
          const newLocation = await abcapi.patchAppleLocation(
            this.poiGroupId,
            patchData.poiId,
            patchData.location,
            patchData.mask,
            oplogParams
          );
          // 正常終了時はローカルデータを更新
          this.rows.find((r) => r.poiID === patchData.poiId).set(newLocation);
          this.grid.invalidate();
        } catch (e) {
          console.error(e);
          this.errs.push({
            poiId: patchData.poiId,
            storeName: patchData.storeName,
            message: getAppleErrorMessage(e as AxiosError<EntitiesErrorResponse>),
          });
        }
      }
    },
    async exportXlsx() {
      const aoa: string[][] = []; // Array of arrays
      // ヘッダー行追加
      const header: string[] = [];
      for (const column of columns) {
        header.push(column.caption as string);
      }
      aoa.push(header);
      // ヘッダー行追加
      const rows = this.dataSource.dataSource;
      for (let i = 0; i < rows.length; i++) {
        const row = await rows.get(i);
        // note: 2024/10時点ではphase1なので、空の行(=紐づけ無し)はスキップ
        if (this.filter.onlyLinked && row.appleLocationID === "") {
          continue;
        }
        const rec: string[] = [];
        for (const column of columns) {
          rec.push(row.get(column));
        }
        aoa.push(rec);
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, "AppleBusinessConnect");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const datetime = dayjs().format("YYYYMMDD");
      saveAs(
        new Blob([buf], { type: "application/octet-stream" }),
        `${wordDictionary.service.name}-AppleBusinessConnectエクスポート-${this.company.name}-${datetime}.xlsx`
      );
    },
    async importXlsx() {
      const target = event.target as HTMLInputElement;
      const file: File = target.files[0];
      if (!file) {
        return;
      }
      target.value = "";
      const buf = await read(file);
      const aoa = arrayBufferToStringsArrays(buf)[0];
      for (let i = 0; i < this.dataSource.dataSource.length; i++) {
        const row = await this.dataSource.dataSource.get(i);
        const array = aoa.find((a) => a[0] === row.poiID.toString());
        if (!array) continue;
        for (let j = 0; j < columns.length; j++) {
          const column = columns[j];
          if (column.readOnly) continue;
          const value = array[j];
          if (column.abcLocation === "categories") {
            const [categories, errorMessage] = this.validateCategories(value, column);
            if (errorMessage !== "") {
              this.showErrorToast(`poiID=${array[0]}の${errorMessage}`);
              return;
            }
            row.setCategories(categories, column.field === "primaryCategory");
          } else {
            if (column.field === "description") {
              const errorMessage = this.descriptionValidator(value);
              if (errorMessage !== "") {
                this.showErrorToast(`poiID=${array[0]}の${column.caption}は${errorMessage}`);
                return;
              }
            }
            if (column.field === "latitude" || column.field === "longitude") {
              const errorMessage = this.latlonValidator(value);
              if (errorMessage !== "") {
                this.showErrorToast(`poiID=${array[0]}の${column.caption}は${errorMessage}`);
                return;
              }
            }
            row[column.field as string] = value;
          }
        }
      }
      this.updateFilter();
      this.grid.invalidate();
      useSnackbar().addSnackbarMessages({ text: "インポートしました", color: "info" });
    },
    validateCategories(value: string, column: Columnx): [string[], string] {
      const categoriesValue = value.split(DELIMITER);
      const categories: string[] = [];
      for (const categoryValue of categoriesValue) {
        if (categoryValue === "") continue;
        const category = this.categoryMaster.findByName(categoryValue);
        if (category == null) {
          return [categories, `${column.caption}に不正な値が入っています`];
        }
        categories.push(category.qualifiedId);
      }
      if (column.field === "primaryCategory") {
        if (categories.length === 0) {
          return [categories, `${column.caption}は必須です`];
        } else if (categories.length > 1) {
          return [categories, `${column.caption}は1つのみ選択可能です`];
        }
      }
      return [categories, ""];
    },
    showErrorToast(message: string) {
      useSnackbar().addSnackbarMessages({ text: message, color: "danger" });
    },
  },
});
</script>

<style lang="scss" scoped>
.custom-grid {
  box-sizing: border-box;
  height: calc(100vh - 220px);
  min-width: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: -70px;
}
.parent-container {
  position: relative;
}
.custom-progress-circular-container {
  position: absolute;
  z-index: var(--z-index-loading);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
</style>
