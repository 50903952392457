import { Component, Vue, toNative } from "vue-facing-decorator";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import type { DomainYahooPlaceMediaHistoryGetResponse } from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { getter, useIndexedDb } from "@/storepinia/idxdb";
import { useSnackbar } from "@/storepinia/snackbar";
import { api as yapi } from "@/helpers/api/yahoo";
import { YpCategoryInfo } from "../YpMediaColumn";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";

type BigQueryStoreYahooMediaHistory = DomainYahooPlaceMediaHistoryGetResponse["histories"][number];

type Parameter = { poiIDs: number[]; areaIDs: number[]; offset: number; limit: number };

@Component({})
class YpMediaHistories extends Vue {
    $route = useRoute();
    $router = useRouter();
    dict: Record<string, unknown> = wordDictionary.reports;
    poiGroupId: number;
    canShowAllowedStoresOnly = getter().canShowAllowedStoresOnly;

    async created(): Promise<void> {
        this.poiGroupId = parseInt(useRoute().params.poiGroupId as string, 10);
        if (this.canShowAllowedStoresOnly) {
            this.updateHistories(this.queryParameter());
        }
        // グループプルダウン作成
        const stores = (useIndexedDb().stores?.stores ?? []).filter(
            (s) => s.enabled && s.yahooplace?.placeSeq
        );
        ({ areas: this.areaItems } = makeSelectItems(useIndexedDb().areaStores, stores, false));
    }

    goBack(): void {
        this.$router.push({ name: "YpMediaEdit" });
    }

    // グループ選択プルダウン
    areaItems = [] as SelectItem[];
    selectedAreas = [] as number[];

    addSnackbarMessages = useSnackbar().addSnackbarMessages;
    selectAreas(): void {
        if (this.selectedAreas.length === 0) {
            this.addSnackbarMessages({
                text: "エリアを選択してください",
                color: "danger",
            });
            return;
        }
        this.updateHistories(this.queryParameter());
    }

    get areaSelectorPlaceholder(): "グループを選択" {
        return "グループを選択";
    }

    queryParameter(): Parameter {
        if (this.canShowAllowedStoresOnly) {
            const poiIDs = this.stores.stores.map((s) => s.poiID);
            return { poiIDs: poiIDs ?? [], areaIDs: [], offset: 0, limit: 10 };
        }
        return { poiIDs: [], areaIDs: this.selectedAreas ?? [], offset: 0, limit: 10 };
    }

    // 表
    stores = getter().stores;

    histories: BigQueryStoreYahooMediaHistory[] = [];
    total: number = 0;
    perPage: number = 10;
    currentPage: number = 1;
    loading: boolean = false;

    pageChange(page: number): void {
        this.updateHistories({
            ...this.queryParameter(),
            offset: this.perPage * (page - 1),
            limit: this.perPage,
        });
    }

    // API処理
    async updateHistories(params: Parameter) {
        this.loading = true;

        // フェッチ
        const resHistories = await yapi.getYahooMediaHistories(
            this.poiGroupId,
            params.areaIDs,
            params.poiIDs,
            params.offset,
            params.limit
        );

        //  表示用データを付加
        this.histories = resHistories.histories.map((h) => {
            const storeName = this.stores?.stores.find((s) => s.poiID === h.poiId)?.name ?? "";
            const nextCategoryName = this.toCategoryName(h.nextCategory);
            const prevCategoryName = this.toCategoryName(h.prevCategory);

            const act = [
                { action: "create", actionName: "作成", changes: `${nextCategoryName}` },
                {
                    action: "patch",
                    actionName: "カテゴリ変更",
                    changes: `${nextCategoryName} → ${prevCategoryName}`,
                },
                { action: "delete", actionName: "削除", changes: "" },
            ].find((a) => a.action === h.action) ?? { actionName: h.action, changes: "" };

            return {
                ...h,
                ...act,
                storeName,
                friendlyCreatedAt: dayjs(h.createdAt).format("YYYY/MM/DD HH:mm"),
            };
        });
        this.total = resHistories.total;

        this.loading = false;
    }

    toCategoryName(cat: string): string {
        return YpCategoryInfo.of(cat)?.title ?? cat;
    }
}
export default toNative(YpMediaHistories);
