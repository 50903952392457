<template>
  <VeeForm
    v-slot="{ handleSubmit }"
    :validation-schema="schema"
    :initial-values="initialValues"
    as="div"
    @invalid-submit="onInvalidSubmit"
  >
    <form style="margin: 30px" @submit.prevent="handleSubmit($event, onSubmit)">
      <h1 class="title">
        {{ submitted ? "お問い合わせありがとうございました" : inquiry.targetName + "は未契約です" }}
      </h1>
      <p>{{ submitted ? "担当者よりメール・電話でご連絡させていただきます。" : targetInfo }}</p>
      <div :style="{ visibility: submitted ? 'hidden' : 'visible' }">
        <p>
          契約方法や価格については下からお問い合わせください。担当者よりメール・電話でご連絡させていただきます。
        </p>
        <div class="form-information">{{ dict.col_required }}</div>
      </div>

      <o-field class="required-highlight" :label="dict.type.title">
        <o-select
          v-model="inquiry.type"
          placeholder="種別を選択してください。"
          :data-vv-as="dict.type.title"
          :disabled="submitted"
        >
          <option v-for="type in inquiryTypes" :key="type" :value="type">{{ type }}</option>
        </o-select>
      </o-field>

      <o-field class="required-highlight" :label="dict.title.title">
        <Field
          type="text"
          name="inquiryTitle"
          maxlength="64"
          placeholder="件名を入力してください。"
          :disabled="submitted"
          class="input"
        ></Field>
      </o-field>
      <ErrorMessage class="error-message" name="inquiryTitle" />

      <o-field class="required-highlight" :label="dict.message.title">
        <Field
          as="textarea"
          name="inquiryMessage"
          placeholder="お問い合わせの内容を入力してください。"
          maxlength="2560"
          :disabled="submitted"
          class="input textarea"
        ></Field>
      </o-field>
      <ErrorMessage class="error-message" name="inquiryMessage" />

      <div>
        <button v-if="!submitted" class="button is-primary">{{ dict.button_register }}</button>
      </div>
      <div v-if="isLoading" class="progress-circular-container">
        <v-progress-circular
          :size="80"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </form>
  </VeeForm>
</template>

<script lang="ts">
import { requiredAuth } from "@/helpers";
import { captureAndThrow } from "@/helpers/error";
import { Component, Vue, toNative } from "vue-facing-decorator";
import type { StorageInquiry } from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { useSnackbar } from "@/storepinia/snackbar";
import { getter } from "@/storepinia/idxdb";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useRoute, useRouter } from "vue-router";

@Component({
  components: { VeeForm, Field, ErrorMessage },
})
class InquiriesForm extends Vue {
  $route = useRoute();
  $router = useRouter();
  company = getter().company;
  user = getter().user;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  inquiry: Partial<StorageInquiry> = {
    fromUrl: "",
    targetName: "",
    type: "",
    title: "",
    message: "",
    companyName: "",
    userName: "",
    mailAddress: "",
  };

  isLoading: boolean = false;
  dict = wordDictionary.inquiry_form;

  inquiryTypes = this.dict.inquiryTypes;
  targetInfo = "";
  submitted = false;
  schema = yup.object({
    inquiryTitle: yup.string().required("必須の項目です"),
    inquiryMessage: yup.string().required("必須の項目です"),
  });
  initialValues = {
    inquiryTitle: "",
  };

  async created(): Promise<void> {
    this.inquiry.fromUrl = this.$route.query.from as string;
    this.inquiry.targetName = "本機能";
    this.inquiry.type = this.inquiryTypes[0]; // 「未契約の機能についてのお問い合わせ」を初期表示
    this.inquiry.companyName = this.company.name;
    this.inquiry.userName = this.user.familyName + this.user.firstName;
    this.inquiry.mailAddress = this.user.mailAddress;

    this.checkTarget(
      (this.$route.query.api as string) ?? "",
      (this.$route.query.from as string) ?? ""
    );
    this.initialValues.inquiryTitle = this.inquiry.title;
  }

  checkTarget(api: string, from: string): void {
    if (from.includes("v2posts")) {
      this.inquiry.targetName = "投稿機能";
      this.targetInfo =
        "こちらはGoogle Business Profileへの予約投稿や一括投稿をするオプション機能です。";
    } else if (api.includes("reviews") || from.includes("reviews")) {
      this.inquiry.targetName = "クチコミ分析機能";
      this.targetInfo =
        "こちらはGoogle Business Profileのクチコミの感情分析や一括返信をするオプション機能です。";
    } else if (api.includes("citation")) {
      this.inquiry.targetName = "サイテーション機能";
      this.targetInfo =
        "こちらはGoogle Business Profileの情報をFacebookなどに同期するオプション機能です。";
    } else if (api.includes("structure") || from.includes("local-edit")) {
      this.inquiry.targetName = "内部施策機能";
      this.targetInfo =
        "こちらは貴社サイトの構造化やメタ情報の埋め込みを行い、SEO内部施策をするオプション機能です。";
    } else {
      this.inquiry.title = "未契約の機能について";
      return;
    }
    this.inquiry.title = this.inquiry.targetName + "について";
  }

  async onSubmit(values): Promise<void> {
    this.isLoading = true;
    const poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
    this.inquiry.title = values.inquiryTitle;
    this.inquiry.message = values.inquiryMessage;

    await requiredAuth<any>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v1/companies/${poiGroupId}/inquiries`,
      null,
      this.inquiry
    )
      .then(async (res) => {
        this.addSnackbarMessages({
          text: "問い合わせを送信しました。",
          color: "success",
        });
        this.submitted = true;
      })
      .catch((e) => captureAndThrow(this.dict.error_register, e))
      .finally(() => (this.isLoading = false));
  }

  onInvalidSubmit() {
    this.addSnackbarMessages({
      text: "入力に誤りがあります。内容を確認してください。",
      color: "danger",
      options: { top: false },
    });
    return;
  }
}
export default toNative(InquiriesForm);
</script>

<style lang="scss" scoped>
@use "@/components/style/color.scss" as color;

.form-information {
  text-align: right;
  &::before {
    content: "*";
    font-size: 21px;
    color: color.$main-orange;
  }
}

.required-highlight :deep(.label::after) {
  content: " *";
  font-size: 21px;
  color: color.$main-orange;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
</style>
