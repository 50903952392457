import { requiredAuth } from "@/helpers";
import { Component, Vue, toNative } from "vue-facing-decorator";
import type { EntitiesRival, EntitiesStore, EntitiesStoresResponse } from "@/types/ls-api";
import wordDictionary from "@/word-dictionary";
import { useSnackbar } from "@/storepinia/snackbar";
import { action } from "@/storepinia/idxdb";
import { getOperationLogParams } from "@/routes/operation-log";
import type { FormContext } from "vee-validate";
import { useRoute, useRouter } from "vue-router";

@Component({})
class Form extends Vue {
    declare $refs: { observer: FormContext };
    setStores = action().setStores;
    addSnackbarMessages = useSnackbar().addSnackbarMessages;

    $route = useRoute();
    $router = useRouter();
    poiGroupId: number;
    poiId: number;
    isLoading: boolean = false;
    dialogVisible: boolean = false;
    dict = wordDictionary.store_form;
    name = "";
    rival: EntitiesRival = { rivalID: 0, name: "", keyword: "" };
    previousKeyword = "";

    async created(): Promise<void> {
        this.isLoading = true;

        try {
            this.poiGroupId = parseInt(this.$route.params.poiGroupId as string, 10);
            this.poiId = parseInt(this.$route.params.poiId as string, 10);

            const resStore = await requiredAuth<EntitiesStoresResponse>(
                "get",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores/${
                    this.poiId
                }`
            );

            if (resStore == null || resStore.data == null) {
                this.addSnackbarMessages({
                    text: "店舗設定の取得に失敗しました。店舗設定の一覧を再読み込みしてください。",
                    color: "danger",
                    options: {
                        top: false,
                    },
                });

                this.$router.push({ name: "Stores" });
                return;
            }
            const store = resStore.data.stores[0];
            this.name = store.name;
            if (store?.rivals != null && store?.rivals.length !== 0) {
                this.rival = store.rivals[0];
                this.previousRivalName = this.rival.name;
                this.previousKeyword = this.rival.keyword;
            }
        } catch (e) {
            console.error(e);
            throw Error("店舗情報が見つかりませんでした");
        } finally {
            this.isLoading = false;
        }
    }

    async editedKeyword(): Promise<boolean> {
        const { name, keyword } = this.rival;
        if ((name && !keyword) || !this.previousKeyword) {
            return false;
        }
        return name ? this.previousKeyword !== keyword : this.previousKeyword !== keyword;
    }

    async showConfirmDialog(): Promise<void> {
        this.dialogVisible = true;
    }

    async submit(): Promise<void> {
        const result = await this.$refs.observer.validate();
        if (!result.valid) {
            this.addSnackbarMessages({
                text: "入力に誤りがあります。内容を確認してください。",
                color: "danger",
                options: {
                    top: false,
                },
            });
            return;
        }

        this.isLoading = true;
        await this.upsert();
    }

    async upsert(): Promise<void> {
        try {
            // 競合店の検索キーワードが変更された場合はRivalIdを採番し直す
            if (this.previousKeyword !== this.rival.keyword) {
                this.rival.rivalID = 0;
            }
            const rivals: EntitiesRival[] = [this.rival];
            const param = { rivals };

            const response = await requiredAuth<EntitiesStore>(
                "put",
                `${import.meta.env.VITE_APP_API_BASE}v1/companies/${this.poiGroupId}/stores/${
                    this.poiId
                }/rivals`,
                getOperationLogParams(this.$route, "rivals-update"),
                param
            );
            if (response == null || response.data == null) {
                // 権限不足
            } else {
                this.addSnackbarMessages({
                    text: "店舗設定を更新しました",
                    color: "success",
                });
            }
            this.setStores(this.poiGroupId);
            this.$router.push({ name: "Stores" });
        } catch (e) {
            console.error(e);
            throw Error(this.dict.error_update);
        } finally {
            this.isLoading = false;
        }
    }

    goBack(): void {
        this.$router.push({ name: "Stores" });
    }
}
export default toNative(Form);
