import type { definitions } from "@/types/swagger";
import type { MediaFile } from "../gmbmedia/MediaColumn";
import type { EntitiesYahooImage } from "@/types/ls-api";

export type MediaItem = EntitiesYahooImage & { category: YpMediaCategory };
export type Manipulation = "NONE" | "CHANGE_CATEGORY" | "ADD_IMAGE" | "DELETE";
export class YpMediaColumn {
    static counter: number = 0;
    key: number;
    manipulation: Manipulation;
    category: YpMediaCategory;
    initialCategory: YpMediaCategory;
    deleteCheckbox: boolean = false;
    src: string;
    mediaFile: MediaFile | null;
    mediaItem: MediaItem | null;
    static ofMediaFile(mf: MediaFile, category: YpMediaCategory): YpMediaColumn {
        const ci = YpCategoryInfo.of(category);
        if (!ci) {
            return null;
        }
        const mc = new YpMediaColumn();
        mc.key = YpMediaColumn.counter++;
        mc.manipulation = "ADD_IMAGE";
        mc.category = category;
        mc.src = mf.src;
        mc.mediaFile = mf;
        return mc;
    }
    static ofMediaItem(mi: MediaItem): YpMediaColumn {
        const ci = YpCategoryInfo.of(mi.category);
        if (!ci) {
            return null;
        }
        const mc = new YpMediaColumn();
        mc.key = YpMediaColumn.counter++;
        mc.manipulation = "NONE";
        mc.category = ci.category;
        mc.initialCategory = ci.category;
        mc.src = mi.thumbnail;
        mc.mediaItem = mi;
        return mc;
    }
    applyChangeCategory(): void {
        if (this.mediaItem) {
            if (this.initialCategory === this.category) {
                this.manipulation = "NONE";
            } else {
                this.manipulation = "CHANGE_CATEGORY";
            }
        }
        this.deleteCheckbox = false;
    }
    applyDeleteCheckbox(): void {
        if (this.deleteCheckbox) {
            this.manipulation = "DELETE";
            this.category = this.initialCategory;
        } else {
            this.applyChangeCategory();
        }
    }
    getThumbnail(): string {
        if (this.mediaFile) {
            return this.mediaFile.file.name;
        }
        return this.mediaItem.thumbnail;
    }
}

type BusinessImage = definitions["entities.YahooBusinessImage"];

export function businessImageToMediaColumns(bi: BusinessImage): YpMediaColumn[] {
    if (!bi) return [];
    const columns: YpMediaColumn[] = [];
    bi.cover?.forEach((mi) => {
        columns.push(YpMediaColumn.ofMediaItem({ ...mi, category: "COVER" }));
    });
    bi.secondary?.forEach((mi) => {
        columns.push(YpMediaColumn.ofMediaItem({ ...mi, category: "SECONDARY" }));
    });
    bi.inside?.forEach((mi) => {
        columns.push(YpMediaColumn.ofMediaItem({ ...mi, category: "INSIDE" }));
    });
    bi.outside?.forEach((mi) => {
        columns.push(YpMediaColumn.ofMediaItem({ ...mi, category: "OUTSIDE" }));
    });
    bi.etc?.forEach((mi) => {
        columns.push(YpMediaColumn.ofMediaItem({ ...mi, category: "ETC" }));
    });
    return columns;
}
export function mediaColumnsToBusinessImage(mcs: YpMediaColumn[]): BusinessImage {
    const bi: BusinessImage = {
        cover: [],
        secondary: [],
        inside: [],
        outside: [],
        etc: [],
    };
    mcs.forEach((mc) => {
        // 削除されたものは追加しない
        if (mc.manipulation === "DELETE") return;
        switch (mc.category) {
            case "COVER":
                bi.cover.push(mc.mediaItem);
                break;
            case "SECONDARY":
                bi.secondary.push(mc.mediaItem);
                break;
            case "INSIDE":
                bi.inside.push(mc.mediaItem);
                break;
            case "OUTSIDE":
                bi.outside.push(mc.mediaItem);
                break;
            case "ETC":
                bi.etc.push(mc.mediaItem);
                break;
        }
    });
    return bi;
}

export type YpMediaCategory = "COVER" | "SECONDARY" | "INSIDE" | "OUTSIDE" | "ETC";

/**
 * 画像のカテゴリ
 */
export class YpCategoryInfo {
    readonly category: YpMediaCategory;
    readonly title: string;
    readonly canDuplicate: boolean;
    private constructor(category: YpMediaCategory, title: string, canDuplicate: boolean) {
        this.category = category;
        this.title = title;
        this.canDuplicate = canDuplicate; // COVER は重複不可能
    }
    static compare(a: YpMediaCategory, b: YpMediaCategory): number {
        const v = { COVER: 0, SECONDARY: 1, INSIDE: 2, OUTSIDE: 3, ETC: 4 };
        return v[a] - v[b];
    }
    static of(s: string): YpCategoryInfo {
        for (const ci of YpCategoryInfo.values) {
            if (ci.category === s) {
                return ci;
            }
        }
        return null;
    }
    static values = [
        new YpCategoryInfo("COVER", "カバー", false),
        new YpCategoryInfo("SECONDARY", "製品・サービス", true),
        new YpCategoryInfo("INSIDE", "内観", true),
        new YpCategoryInfo("OUTSIDE", "外観", true),
        new YpCategoryInfo("ETC", "その他", true),
    ];
}
