<template>
  <div>
    <v-card>
      <v-row class="mt-1 mx-1">
        <v-col>
          <div class="text-h6 font-weight-bold">多言語店舗名登録</div>
        </v-col>
        <v-col align="end">
          <v-btn
            color="primary"
            variant="outlined"
            class="mr-5"
            :disabled="isLoading"
            @click="templateDownload"
          >
            <v-icon>fas fa-file-excel</v-icon>
            テンプレートダウンロード
          </v-btn>
          <input ref="importInput" type="file" hidden accept=".xlsx" @change="upload" />
          <v-btn
            :disabled="isLoading"
            color="primary"
            @click="($refs.importInput as HTMLInputElement).click()"
          >
            <v-icon>fas fa-plus</v-icon>
            ファイルアップロード
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="ml-4 mb-4">
            多言語での店舗名登録タスクの登録を行うページです。
            <br />
            企業一覧で特定の企業にログインしてからお使いください。
            <br />
            結果が出るまでに時間がかかりますが、特に通知は行いません。
            リロードすると最新の情報が表示されます。
            <br />
            成功可否は結果ファイルをダウンロードして確認してください。
          </p>
        </v-col>
      </v-row>
    </v-card>
    <v-data-table :items="items" :headers="headers" class="my-4">
      <template #item="{ item }">
        <tr>
          <td>{{ utcToJst(item.createdAt) }}</td>
          <td>{{ utcToJst(item.endedAt) }}</td>
          <td>
            <v-btn
              v-if="item.outputFilePath != ''"
              :disabled="isLoading"
              color="primary"
              @click="outputDownload(item.outputFilePath)"
            >
              <v-icon>fas fa-download</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div v-if="isLoading" class="progress-circular-container">
      <v-progress-circular :size="80" :width="4" color="primary" indeterminate />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { convertDateTime, sortByDateProperty } from "@/helpers/date";
import { uploadFileToUrl } from "@/helpers/file-upload";
import { requiredAuth } from "@/helpers";
import { useSnackbar } from "@/storepinia/snackbar";

import type {
  StorageResponse,
  DomainUpdateMultilingualInfoItem,
  ControllersUpdateMultilingualInfoUploadResponse,
  ControllersUpdateMultilingualInfoDownloadRequest,
  ControllersExecuteMultilingualBatchRequest,
} from "@/types/ls-api";
import type { SnackbarToast } from "@/components/shared/snackbar/snackbar-shared";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  data: () => ({
    $route: useRoute(),
    $router: useRouter(),
    headers: [
      // ファイル名はbackendリネームしているので表示しない
      { title: "登録日時", key: "createdAt" },
      { title: "終了日時", key: "endedAt" },
      { title: "結果ダウンロード", key: "outputFilePath" },
    ],
    items: [] as DomainUpdateMultilingualInfoItem[],
    isLoading: false as boolean,
  }),
  computed: {
    poiGroupID() {
      return this.$route.params.poiGroupId;
    },
  },
  async created() {
    await this.fetchItems();
  },
  methods: {
    addSnackbar: function (message: SnackbarToast): void {
      useSnackbar().addSnackbarMessages(message);
    },
    utcToJst(date: string): string {
      return convertDateTime(date);
    },
    generateApiEndpoint(method: string): string {
      const base = `${import.meta.env.VITE_APP_API_BASE}v1/companies/${
        this.poiGroupID
      }/updateMultilingualInfo`;
      return method ? `${base}/${method}` : base;
    },
    displayError(message: string): void {
      this.addSnackbar({
        text: message,
        color: "danger",
      });
    },
    displayMessage(message: string): void {
      this.addSnackbar({
        text: message,
        color: "success",
      });
    },
    async fetchItems() {
      this.isLoading = true;
      // ファイル一覧取得処理を実装する
      await requiredAuth<Array<DomainUpdateMultilingualInfoItem>>(
        "get",
        this.generateApiEndpoint("")
      )
        .then((res) => {
          if (!res?.data) {
            throw "レスポンスにファイル一覧が含まれなかった";
          }
          this.items = res.data;
          // デフォルトは作成日時の降順でソート
          this.items.sort(sortByDateProperty("createdAt", "desc"));
        })
        .catch((err) => {
          console.error(err);
          this.displayError("ファイル一覧の取得に失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /* テンプレートダウンロード処理 */
    async templateDownload() {
      this.isLoading = true;
      await requiredAuth<string>("get", this.generateApiEndpoint("template"))
        .then((res) => {
          if (!res?.data) {
            throw "レスポンスにダウンロード用のURLが含まれなかった";
          }
          const link = document.createElement("a");
          link.href = res.data;
          link.click();
          this.displayMessage("テンプレートファイルをダウンロードしました");
        })
        .catch((err) => {
          console.error(err);
          this.displayError("テンプレートのダウンロードに失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /* 結果ダウンロード処理 */
    async outputDownload(filePath: string) {
      this.isLoading = true;
      const req: ControllersUpdateMultilingualInfoDownloadRequest = {
        key: filePath,
      };
      await requiredAuth<string>("post", this.generateApiEndpoint("download"), {}, req)
        .then((res) => {
          if (!res?.data) {
            throw "レスポンスにダウンロード用のURLが含まれなかった";
          }
          const link = document.createElement("a");
          link.href = res.data;
          link.click();
          this.displayMessage("結果ファイルをダウンロードしました");
        })
        .catch((err) => {
          console.error(err);
          this.displayError("結果のダウンロードに失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /* 処理ファイルアップロード処理 */
    async upload(event: Event) {
      const target = event.target as HTMLInputElement;
      const file: File = target.files[0];
      if (!file) {
        return;
      }
      this.isLoading = true;
      var key: string = "";
      var preSignedURL: string = "";

      // inputファイルのアップロード用のURLを取得
      await requiredAuth<ControllersUpdateMultilingualInfoUploadResponse>(
        "post",
        this.generateApiEndpoint("upload")
      )
        .then((res) => {
          if (!res?.data) {
            throw "レスポンスにアップロード用のURLが含まれなかった";
          }
          key = res.data.key;
          preSignedURL = res.data.url;
        })
        .catch((err) => {
          console.error(err);
          this.displayError("ファイルのアップロードに失敗しました");
          this.isLoading = false;
          return;
        });
      // ファイルをアップロード
      await uploadFileToUrl(file, preSignedURL)
        .then((res) => {
          if (!res) {
            throw "ファイルのアップロードに失敗しました";
          }
        })
        .catch(() => {
          this.displayError("ファイルのアップロードに失敗しました");
          this.isLoading = false;
          return;
        });
      // バッチ処理実行要求
      const eReq: ControllersExecuteMultilingualBatchRequest = {
        key: key,
      };
      await requiredAuth<StorageResponse>("post", this.generateApiEndpoint("execute"), {}, eReq)
        .then(() => {
          this.displayMessage("ファイルの処理を開始しました");
        })
        .catch((err) => {
          console.error(err);
          this.displayError("ファイルの処理に失敗しました");
          return;
        })
        .finally(() => {
          this.isLoading = false;
        });
      // 一覧更新
      await this.fetchItems();
    },
  },
});
</script>

<style scoped></style>
