<template>
  <!-- ヘッダ -->
  <v-card>
    <v-card-text><p class="font-weight-bold text-h6">店舗画像一括更新</p></v-card-text>
  </v-card>
  <!-- 店舗選択 -->
  <v-card class="my-2 pb-2" elevation="0">
    <v-card-title :class="titleClass(isStoreSelected)">
      <span v-if="isStoreSelected">
        <v-icon size="small" :color="checkedColor">{{ checkedIcon }}</v-icon>
      </span>
      <span v-else>
        <v-icon size="small" :color="unCheckedColor">{{ unCheckedIcon }}</v-icon>
      </span>
      1. アップロードする店舗の選択
    </v-card-title>
    <!-- 店舗選択 -->
    <v-responsive
      v-if="!isCompleted"
      class="overflow-y-auto select-stores-area mx-2 p-1"
      max-height="280"
    >
      <!-- FIXME: Phase2になったらmultipleに設定して、v-modelを配列の方にしてください -->
      <v-chip-group v-model="selectedStore">
        <v-chip
          v-for="store in stores"
          :key="store.poiID"
          size="large"
          :value="store"
          :variant="isSelected(store.poiID) ? 'flat' : 'outlined'"
          :color="isSelected(store.poiID) ? checkedColor : '#757575'"
          :class="isSelected(store.poiID) ? 'selected-store-chip' : 'unselected-store-chip'"
        >
          <span v-if="isSelected(store.poiID)">
            <v-icon start color="white">fas fa-check</v-icon>
          </span>
          <span v-else><v-icon start color="#D6D6D6">fas fa-check</v-icon></span>
          {{ store.name }}
        </v-chip>
      </v-chip-group>
    </v-responsive>
    <!-- 結果確認時に選択済み店舗を表示 -->
    <div v-else class="mb-2 pl-3">
      <v-chip
        v-for="store in selectedStores"
        :key="store.poiID"
        size="large"
        variant="flat"
        :color="checkedColor"
        class="ml-1 selected-store-chip"
      >
        <v-icon start color="white">fas fa-check</v-icon>
        {{ store.name }}
      </v-chip>
    </div>
  </v-card>
  <!-- 画像選択 -->
  <v-card class="my-2" elevation="0">
    <v-card-title :class="titleClass(isMediaSelected)">
      <span v-if="isMediaSelected">
        <v-icon size="small" :color="checkedColor">{{ checkedIcon }}</v-icon>
      </span>
      <span v-else>
        <v-icon size="small" :color="unCheckedColor">{{ unCheckedIcon }}</v-icon>
      </span>
      2. アップロードする画像の選択
    </v-card-title>
    <v-row v-if="!isCompleted" no-gutters>
      <!-- 最大アップロード数は10枚まで -->
      <v-col v-if="mediaFiles.length < 10" cols="6" class="p-2">
        <input
          ref="uploadMedia"
          type="file"
          hidden
          multiple
          :accept="allowedExtensions"
          @change="addItem"
        />
        <v-sheet
          class="add-media d-flex justify-center align-center"
          @click="($refs.uploadMedia as HTMLInputElement).click()"
        >
          <v-btn size="30" rounded variant="flat" color="#ACB8CF">
            <v-icon color="white">fas fa-plus</v-icon>
          </v-btn>
        </v-sheet>
      </v-col>
      <v-col v-for="f of mediaFiles" :key="f.key" class="d-flex p-2" cols="6">
        <!-- ビデオの表示 -->
        <div v-if="f.isVideo" class="media-area text-end">
          <v-btn
            size="35"
            rounded
            variant="flat"
            color="black"
            class="m-1"
            @click.prevent="deleteItem(f)"
          >
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
          <video :src="f.videoPreviewURL" :poster="f.thumbnailURL" controls />
        </div>
        <!-- 画像の表示 -->
        <v-img
          v-else
          :src="f.thumbnailURL"
          aspect-ratio="1"
          class="media-area"
          @click="f.overlay = !f.overlay"
        >
          <v-overlay v-model="f.overlay" class="align-center justify-center" contained>
            <v-btn size="40" rounded variant="flat" color="black" @click.prevent="deleteItem(f)">
              <v-icon>far fa-trash-alt</v-icon>
            </v-btn>
          </v-overlay>
          <template #placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-card>
  <!-- アップロードボタン -->
  <v-card v-if="!isCompleted" align="center" class="p-1 my-2" elevation="0">
    <v-btn
      color="#E95454"
      size="x-large"
      class="font-weight-bold"
      block
      text="画像アップロード"
      :disabled="!isValidate || isLoading"
      @click.prevent="upload"
    />
  </v-card>
  <!-- アップロード結果 -->
  <v-card v-if="isCompleted" class="my-2" elevation="0">
    <v-card-title>3. アップロード結果</v-card-title>
    <v-row no-gutters>
      <!-- 成功したものと失敗したものに分けて表示する -->
      <v-col v-if="failedFiles.length > 0" cols="12">
        <v-card-title class="failed-result-title">
          <v-icon size="1rem" :color="failedColor">fas fa-exclamation-triangle</v-icon>
          {{ failedFiles.length }}点の画像アップロードに失敗しました
        </v-card-title>
      </v-col>
      <v-col v-for="f of failedFiles" :key="f.key" class="d-flex p-2" cols="6">
        <v-img
          :src="f.thumbnailURL"
          aspect-ratio="1"
          class="failed-media"
          @click="f.overlay = !f.overlay"
        >
          <v-overlay v-model="f.overlay" class="text-center error-overlay" contained>
            <div class="retry-button">
              <v-btn
                v-if="f.canRetry"
                size="40"
                rounded
                variant="flat"
                color="#ACB8CF"
                @click.prevent="retry(f)"
              >
                <v-icon color="white">fas fa-rotate</v-icon>
              </v-btn>
            </div>
            <v-card class="error-message-sheet pb-1" rounded="0">
              <div class="message-title">
                <v-icon size="1rem">fas fa-exclamation-triangle</v-icon>
                Error
              </div>
              <div class="error-message-body overflow-auto">
                {{ f.errorMessage }}
              </div>
            </v-card>
          </v-overlay>
          <template #placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col v-if="succeedFiles.length > 0" cols="12">
        <v-card-title class="succeed-result-title">
          <v-icon size="1rem" :color="checkedColor">fas fa-check</v-icon>
          {{ succeedFiles.length }}点の画像アップロードが成功しました
        </v-card-title>
      </v-col>
      <v-col v-for="f of succeedFiles" :key="f.key" class="d-flex p-2" cols="6">
        <v-img :src="f.thumbnailURL" aspect-ratio="1" class="succeed-media">
          <template #placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-card>
  <!-- 別の画像をアップロード -->
  <v-card v-if="isCompleted" align="center" class="p-1 my-2" elevation="0">
    <v-btn
      color="#5EA2DE"
      size="x-large"
      class="font-weight-bold"
      block
      text="別の画像をアップロードする"
      @click.prevent="resetImages"
    />
  </v-card>
  <!-- ローディング表示 -->
  <v-overlay :model-value="isLoading" persistent class="align-center justify-center">
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useIndexedDb } from "@/storepinia/idxdb";
import { useSnackbar } from "@/storepinia/snackbar";
import { api as storesAPI } from "@/helpers/api/stores";
import { api as gmbMediaAPI } from "@/helpers/api/gmb-media";
import { getVideoThumbnail, getDummyImageUrl } from "@/helpers/video";
import { TOAST_CRITICAL_DURATION } from "@/const";
import { getOperationLogParams } from "@/routes/operation-log";

import type { OperationLogParams } from "@/routes/operation-log";
import type { EntitiesStore } from "@/types/ls-api";
import { MediaFile, allowedExtensions } from "../MediaColumn";
import { parseGMBErrorDetails } from "../error";
import { useRoute, useRouter } from "vue-router";

/**
 * モバイル版一括登録用のMediaFile
 */
class UploadMediaFile extends MediaFile {
  // 画像・動画のサムネイルURL
  thumbnailURL: string = "";
  // 動画のプレビューURL
  videoPreviewURL: string = "";
  // アップロード先URL
  uploadURL: string = "";
  // 画面制御用のフラグ
  overlay: boolean = false;
  // 画像アップロードエラーメッセージ
  errorMessage: string = "";
  // retry可能かどうか(致命的なエラーが発生した場合はfalseにする)
  canRetry: boolean = true;
  // 店舗ごとのアップロード成否を記録するオブジェクト
  // キーは店舗ID、値はアップロードが成功したかどうかのブール値
  uploadResults: Record<number, boolean> = {};

  static async of(file: File): Promise<UploadMediaFile> {
    // 継承元の処理を呼び出す
    const mediaFile = await MediaFile.of(file);
    // UploadMediaFileに変換する
    const uploadMediaFile = new UploadMediaFile();
    Object.assign(uploadMediaFile, mediaFile);
    // サムネイルを取得する
    await uploadMediaFile.getThumbnailURL();
    return uploadMediaFile;
  }

  async getThumbnailURL(): Promise<void> {
    if (!this.file) {
      return;
    }
    if (this.isVideo) {
      // 動画のサムネイルを取得
      const thumbnailURL = await getVideoThumbnail(this.file);
      if (thumbnailURL) {
        this.thumbnailURL = thumbnailURL;
      } else {
        // サムネイルが取得できない場合はダミー画像を表示
        this.thumbnailURL = getDummyImageUrl(
          "ブラウザが\n未対応の動画形式です\n\nサムネイルが\n表示されませんが\nアップロード可能です"
        );
        this.isVideo = false;
      }
      this.videoPreviewURL = URL.createObjectURL(this.file);
    } else {
      // 画像の場合はそのまま表示
      this.thumbnailURL = this.src;
    }
  }

  // 引数の店舗を成功としてマークする
  markStoreAsSucceed(poiID: number): void {
    this.uploadResults[poiID] = true;
  }

  // 引数の店舗すべてを成功としてマークする
  markAllStoresAsSucceed(stores: EntitiesStore[]): void {
    stores.forEach((store) => {
      // 既に失敗している店舗はスキップ
      if (this.isFailedStore(store.poiID)) {
        return;
      }
      this.markStoreAsSucceed(store.poiID);
    });
  }

  // 引数の店舗を失敗としてマークする
  markStoreAsFailed(poiID: number): void {
    this.uploadResults[poiID] = false;
  }

  // 引数の店舗すべてを失敗としてマークする
  markAllStoresAsFailed(stores: EntitiesStore[]): void {
    stores.forEach((store) => {
      // 既に成功している店舗はスキップ
      if (this.isSucceedStore(store.poiID)) {
        return;
      }
      this.markStoreAsFailed(store.poiID);
    });
  }

  // このファイルを回復不能としてマークする
  markFileAsUnrecoverable(stores: EntitiesStore[]): void {
    this.canRetry = false;
    this.markAllStoresAsFailed(stores);
  }

  // 引数の店舗が成功しているかどうか
  isSucceedStore(poiID: number): boolean {
    // 値が入っていてかつtrueの場合は成功とみなす
    return this.uploadResults[poiID] === true;
  }

  // 引数の店舗が失敗しているかどうか
  isFailedStore(poiID: number): boolean {
    // 値が入っていてかつfalseの場合は失敗とみなす
    return this.uploadResults[poiID] === false && this.uploadResults[poiID] != null;
  }

  // 失敗した店舗があるかどうか
  hasFailedStores(stores: EntitiesStore[]): boolean {
    return stores.some((store) => this.isFailedStore(store.poiID));
  }
}

export default defineComponent({
  name: "GBPMediaUpload",
  data() {
    return {
      $route: useRoute(),
      $router: useRouter(),
      checkedColor: "#40CDB0",
      failedColor: "#E95454",
      unCheckedColor: "#C6C6C6",
      checkedIcon: "fas fa-check",
      unCheckedIcon: "far fa-circle-check",
      stores: [] as EntitiesStore[],
      // FIXME: phase2になったら配列の方を使うように修正してください
      selectedStore: null as EntitiesStore | null,
      selectedStores: [] as EntitiesStore[],
      mediaFiles: [] as UploadMediaFile[],
      // ローディングフラグ
      isLoading: false as boolean,
      // アップロード完了フラグ(ローディングにかかわらず画面制御するためのフラグ)
      isCompleted: false as boolean,
    };
  },
  computed: {
    poiGroupID(): number {
      return parseInt(this.$route.params.poiGroupId as string);
    },
    isStoreSelected(): boolean {
      // 店舗選択されていればtrue
      return this.selectedStores.length > 0;
    },
    isMediaSelected(): boolean {
      // 画像が1つでもアップロードされていればtrue
      return this.mediaFiles.length > 0;
    },
    isValidate(): boolean {
      // 全体のvalidate flag
      return this.isStoreSelected && this.isMediaSelected;
    },
    allowedExtensions(): string {
      return allowedExtensions;
    },
    succeedFiles(): UploadMediaFile[] {
      // 処理が終了した時点でアップロードに成功しているファイルを返す
      if (!this.isCompleted) {
        return [];
      }
      return this.mediaFiles.filter((mf) => !mf.hasFailedStores(this.selectedStores));
    },
    failedFiles(): UploadMediaFile[] {
      if (!this.isCompleted) {
        return [];
      }
      return this.mediaFiles.filter((mf) => mf.hasFailedStores(this.selectedStores));
    },
  },
  watch: {
    // FIXME: phase2で複数店舗に対応するので、それまでの暫定対応
    selectedStore: {
      handler(store: EntitiesStore | null) {
        if (!store) {
          this.selectedStores = [];
          return;
        }
        this.selectedStores = [store];
      },
      immediate: true,
    },
  },
  async created() {
    await this.getStores();
    // 対象が1店舗しかないなら自動選択
    if (this.stores.length == 1) {
      this.selectedStore = this.stores[0];
      // this.selectedStores = this.stores;
    }
  },
  methods: {
    titleClass(isValidate: boolean): string {
      return isValidate ? "checked-title" : "";
    },
    isSelected(poiID: number): boolean {
      return this.selectedStores.some((s) => s.poiID === poiID);
    },
    displayErrorMessage(message: string): void {
      useSnackbar().addSnackbarMessages({
        text: message,
        color: "danger",
        timeout: TOAST_CRITICAL_DURATION,
      });
    },
    scrollToTop(): void {
      window.scrollTo(0, 0);
    },
    getOplogParams(actionType: string): OperationLogParams {
      return getOperationLogParams(this.$route, actionType);
    },
    async getStores(): Promise<void> {
      let stores: EntitiesStore[] = [];
      stores = useIndexedDb().stores.stores;
      if (stores.length == 0) {
        // ローカルDBに店舗情報がない場合はAPIから取得
        const user = useIndexedDb().user;
        this.isLoading = true;
        try {
          stores = await storesAPI.listStores(this.poiGroupID, user.uuID);
        } catch (e) {
          console.error(e);
          this.displayErrorMessage("店舗情報の取得に失敗しました");
        } finally {
          this.isLoading = false;
        }
      }
      this.stores = stores.filter((store) => store.enabled);
    },
    async addItem(event: Event): Promise<void> {
      this.isLoading = true;
      const target = event.target as HTMLInputElement;
      if (this.mediaFiles.length + target.files.length > 10) {
        this.displayErrorMessage("画像は1回につき10枚までアップロード可能です");
        this.isLoading = false;
        return;
      }
      for (const file of target.files) {
        if (!file) {
          continue;
        }
        const newmf = await UploadMediaFile.of(file);
        if (newmf.imageError !== "") {
          this.displayErrorMessage(
            `${newmf.file.name}のアップロードに失敗しました: ${newmf.imageError}`
          );
          return;
        }
        let mf = this.mediaFiles.find((mf) => mf.equals(newmf));
        if (!mf) {
          this.mediaFiles.push(newmf);
          mf = newmf;
        }
      }
      this.isLoading = false;
    },
    async deleteItem(file: UploadMediaFile): Promise<void> {
      this.isLoading = true;
      this.mediaFiles = this.mediaFiles.filter((mf) => mf !== file);
      this.isLoading = false;
    },
    handleUploadError(error: any, file: UploadMediaFile, poiID: number): void {
      console.error(error);
      const errorInfo = parseGMBErrorDetails(error);
      file.errorMessage = errorInfo.getErrorMessage();
      file.markStoreAsFailed(poiID);
      // 制限に達していたときはエラーメッセージを表示
      if (errorInfo.isRateLimitExceeded) {
        this.displayErrorMessage(file.errorMessage);
      }
      // 回復不能なエラーの場合はリトライ不可フラグを立てる
      if (errorInfo.isRateLimitExceeded || errorInfo.isForbidden) {
        file.markFileAsUnrecoverable(this.selectedStores);
      }
    },
    async upload(): Promise<void> {
      this.isLoading = true;
      // overlayフラグは初期化
      this.mediaFiles.forEach((mf) => (mf.overlay = false));

      let hasCriticalError = false;
      filesLoop: for (const file of this.mediaFiles) {
        if (hasCriticalError) {
          // 他の画像で429エラーなどが発生している場合は失敗としてマークしてスキップ
          file.errorMessage = "他の画像のアップロード中にすぐに解決できないエラーが発生しました";
          file.markFileAsUnrecoverable(this.selectedStores);
          continue;
        }
        for (const store of this.selectedStores) {
          try {
            await this.uploadMedia(file, store.poiID);
          } catch (e) {
            this.handleUploadError(e, file, store.poiID);
            // リトライ不可なエラーが発生している場合は他の店舗のアップロードをスキップ
            hasCriticalError = !file.canRetry;
            if (hasCriticalError) {
              continue filesLoop;
            }
          }
        }
        // 成功としてマーク
        file.markAllStoresAsSucceed(this.selectedStores);
      }
      this.isCompleted = true;
      this.scrollToTop();
      this.isLoading = false;
    },
    async retry(file: UploadMediaFile): Promise<void> {
      this.isLoading = true;
      file.overlay = false;
      for (const store of this.selectedStores) {
        if (!file.isFailedStore(store.poiID)) {
          continue;
        }
        try {
          await this.uploadMedia(file, store.poiID);
          file.markStoreAsSucceed(store.poiID);
        } catch (e) {
          this.handleUploadError(e, file, store.poiID);
          if (!file.canRetry) {
            break;
          }
        }
      }
      this.isLoading = false;
    },
    resetImages(): void {
      this.isLoading = true;
      this.mediaFiles = [];
      this.succeedFiles = [];
      this.failedFiles = [];
      this.isCompleted = false;
      this.scrollToTop();
      this.isLoading = false;
    },
    async uploadMedia(file: UploadMediaFile, poiID: number): Promise<void> {
      if (file.uploadURL == "") {
        file.uploadURL = await gmbMediaAPI.uploadMedia(
          this.poiGroupID,
          file,
          this.getOplogParams("image-put")
        );
      }
      // note: モバイル版では"未分類"固定でアップロードする
      const categories = file.getMakeableCategoryInfos();
      const category = categories.find((ci) => ci.category === "ADDITIONAL");
      if (!category) {
        throw new Error(
          `${file.file.name} は、カテゴリ「${category.title}」にアップロードできません`
        );
      }
      await gmbMediaAPI.putMedia(
        this.poiGroupID,
        poiID,
        {
          mediaFormat: file.mediaFormat.name,
          sourceUrl: file.uploadURL,
          locationAssociation: { category: category.category },
        },
        this.getOplogParams("image-put")
      );
    },
  },
});
</script>

<style lang="scss" scoped>
$succeed-color: #40cdb0;
$failed-color: #e95454;
$background-color: #f5f5f5;

.checked-title {
  color: $succeed-color;
}

.select-stores-area {
  background-color: $background-color;
}

.unselected-store-chip {
  color: #757575;
}

.selected-store-chip {
  // darkモードだと黒字になってしまうので明示的に白抜きで表示する
  color: #fff !important;
  font-weight: bold;
}

.media-area {
  background-color: #d9d9d9;
  aspect-ratio: 1 / 1;
}

.add-media {
  background-color: $background-color;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.error-overlay {
  :deep(.v-overlay__content) {
    width: 100%;
    height: 100%;
  }
  .retry-button {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error-message-sheet {
    height: 50%;
    background: #e95454cc;
    color: white;

    .message-title {
      font-size: 1rem;
    }
    .error-message-body {
      font-size: 0.8rem;
      height: 100%;
    }
  }
}

.result-title {
  font-size: 1rem !important;
  letter-spacing: 0.0125em !important;
  font-weight: bold;
}

.failed-result-title {
  @extend .result-title;
  color: $failed-color;
}

.succeed-result-title {
  @extend .result-title;
  color: $succeed-color;
}

.failed-media {
  @extend .media-area;
  border: 3px solid $failed-color;
}

.succeed-media {
  @extend .media-area;
  border: 3px solid $succeed-color;
}
</style>
