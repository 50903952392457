import wordDictionary from "@/word-dictionary";

import type { EntitiesV2HistoryPostData } from "@/types/ls-api";

/**
 * 投稿ユーザー名を取得
 */
export function getUserName(data: EntitiesV2HistoryPostData): string {
    return data?.updateUser?.length > 0 ? data.updateUser : data?.user;
}

export function getRequestUserName(data: EntitiesV2HistoryPostData): string {
    return data?.judgmentRequestUser?.length > 0 ? data.judgmentRequestUser : "";
}

export function getApproveUserName(data: EntitiesV2HistoryPostData): string {
    return data?.judgmentApproveUser?.length > 0 ? data.judgmentApproveUser : "";
}

export function getRejectUserName(data: EntitiesV2HistoryPostData): string {
    return data?.judgmentRejectUser?.length > 0 ? data.judgmentRejectUser : "";
}

/**
 * 英語の投稿状態を日本語に変換
 */
export function convertState(
    state: string,
    storeCount: number = 0,
    postCount: number = 0,
    errorCount: number = 0,
    mainState: boolean = true
): string {
    if (state in wordDictionary.v2post.histories.status) {
        if (storeCount > 0) {
            return `${postCount}/${storeCount}`;
        } else if (!mainState && (state === "JUDGEMENTREQUEST" || state === "JUDGEMENTREJECT")) {
            return wordDictionary.v2post.histories.status.NOTUSE;
        }
        return wordDictionary.v2post.histories.status[state];
    }
    return wordDictionary.v2post.histories.status.UNKNOWN;
}

/**
 * GBPの投稿種別を日本語に変換
 */
export function getGBPType(data: EntitiesV2HistoryPostData): string {
    if (data?.xlsxMode) {
        return convertGBPType(data?.xlsxGMB);
    }
    if (data?.GMB?.info) {
        return convertGBPType(data?.GMB?.info?.mode);
    }
    if (data?.GMB?.event) {
        return convertGBPType(data?.GMB?.event?.mode);
    }
    if (data?.GMB?.benefits) {
        return convertGBPType(data?.GMB?.benefits?.mode);
    }
    if (data?.GMB?.product) {
        return convertGBPType(data?.GMB?.product?.mode);
    }
    if (data?.GMB?.covid19) {
        return convertGBPType(data?.GMB?.covid19?.mode);
    }
    return "-";
}

function convertGBPType(value: string): string {
    if (value in wordDictionary.v2post.histories.gbpType) {
        return wordDictionary.v2post.histories.gbpType[value];
    }
    return wordDictionary.v2post.histories.gbpType.default;
}

/**
 * yyyy-mm-dd -> yyyy/mm/dd に変更
 */
export function convertDateFormat(date: string): string {
    if (date) {
        return date.replace(/-/g, "/");
    }
    return "-";
}
