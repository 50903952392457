<template>
  <div>
    <div class="post-form">
      <p class="post-title-0st">投稿を作成・編集</p>
      <p class="post-title-1st">1. 投稿名の設定</p>
      <p class="post-desc text-indent-1">投稿に名称を設定してください。(80文字まで)</p>
      <div class="post-title-row">
        <v-row justify="start">
          <v-col class="post-title" cols="1">投稿名</v-col>
          <v-col cols="5">
            <v-text-field
              v-model="postName"
              density="compact"
              variant="outlined"
              :rules="[rules.maxLength(80)]"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <p class="post-title-1st">2. {{ selectStoresDict.Step2Title }}</p>
      <p class="post-desc text-indent-1">投稿対象をお選びください。</p>
      <p class="post-desc text-indent-1">
        {{ selectStoresDict.Step2Description }}
      </p>
      <p class="post-title-2nd text-indent-1">A. 全ての店舗に投稿</p>
      <p class="post-desc text-indent-2">チェックを入れると全ての店舗に投稿します。</p>
      <p>
        <v-checkbox v-model="allSelect" class="post-check" hide-details color="primary">
          <template #label>
            <span class="text-black">全ての店舗を対象とした投稿を作成する</span>
          </template>
        </v-checkbox>
      </p>
      <div v-show="!allSelect">
        <p class="post-title-2nd text-indent-1">B. {{ selectStoresDict.BTitle }}</p>
        <div v-show="!canShowAllowedStoresOnly">
          <p class="post-title-3rd text-indent-2">グループを選択して投稿</p>
          <p class="post-desc text-indent-2">
            投稿するグループをお選びください。選択したグループに属する店舗は自動的に選択されます。
          </p>
          <auto-complete-card
            v-model="selectedAreas"
            :items="areasRows"
            label="グループを選択"
            unit="グループ"
            show-all-select
            class="ml-12"
          />
        </div>
        <div>
          <p class="post-title-3rd text-indent-2">店舗を選択して投稿</p>
          <p class="post-desc text-indent-2">投稿する店舗をお選びください。</p>
          <auto-complete-card
            v-model="selectedStores"
            :items="storesRows"
            label="店舗を選択"
            unit="店舗"
            class="ml-12"
          />
        </div>
      </div>
      <p class="post-title-1st">3. 投稿メディア選択</p>
      <p class="post-desc text-indent-1">投稿するメディアを選択してください。</p>
      <p class="post-desc text-indent-1">選択したメディア毎にシートが生成されます</p>
      <p class="post-title-2nd text-indent-1">A. GBP 投稿対象の選択</p>
      <v-radio-group v-model="gbp" row class="post-radio" color="primary">
        <v-radio value="" label="無効"></v-radio>
        <v-radio v-for="(t, key) of gmbPostTypes" :key="key" :value="t.value" :label="t.label" />
      </v-radio-group>
      <p class="post-title-2nd text-indent-1">B. Facebook 投稿の選択</p>
      <v-switch
        v-model="fb"
        :label="fb ? 'on' : 'off'"
        color="orange"
        class="post-switch"
      ></v-switch>
      <p class="post-title-2nd text-indent-1">C. Instagram 投稿の選択</p>
      <v-switch
        v-model="ig"
        :label="ig ? 'on' : 'off'"
        color="orange"
        class="post-switch"
      ></v-switch>
      <p class="post-title-2nd text-indent-1">D. Yahoo!プレイス 投稿対象の選択</p>
      <v-radio-group v-model="yahoo" row class="post-radio" color="primary">
        <v-radio value="" label="無効"></v-radio>
        <v-radio v-for="(t, key) of yahooPostTypes" :key="key" :value="t.value" :label="t.label" />
      </v-radio-group>
      <p class="post-title-2nd text-indent-1">E. ホスティング連動の選択</p>
      <v-switch
        v-model="hosting"
        :label="hosting ? 'on' : 'off'"
        color="orange"
        class="post-switch"
      ></v-switch>
    </div>
    <div>
      <v-row justify="center" class="input-row submit-buttons">
        <v-col cols="auto">
          <v-btn class="followed file-post-button" @click="navigateToHistories">履歴に戻る</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn class="primary post-button" @click.prevent="confirm">ダウンロード</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="downloadLoading" class="progress-circular-container">
      <v-progress-circular
        :size="80"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import { VuetifyValidator } from "@/helpers";
import type {
  ControllersTemplateCreateInput,
  ControllersTemplateCreateOutput,
  EntitiesStore,
  EntitiesYahooFeedType,
} from "@/types/ls-api";
import { requiredAuth } from "@/helpers/request";
import wordDictionary, { postStoresSelectDict } from "@/word-dictionary";
import { useSnackbar } from "@/storepinia/snackbar";
import type { AxiosResponse, AxiosError } from "axios";
import type { GMBPostType } from "@/models/v2-file-selection";
import type { PostStoresSelectDict } from "@/word-dictionary";
import { getter } from "@/storepinia/idxdb";
import { type SelectItem, makeSelectItems } from "@/helpers/select-items";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import { useRoute, useRouter } from "vue-router";

@Component({ components: { AutoCompleteCard } })
class V2PostTemplate extends Vue {
  $route = useRoute();
  $router = useRouter();
  company = getter().company;
  stores = getter().stores;
  areaStores = getter().areaStores;
  facebookEnabled = getter().facebookEnabled;
  instagramEnabled = getter().instagramEnabled;
  canShowAllowedAreasOnly = getter().canShowAllowedAreasOnly;
  canShowAllowedStoresOnly = getter().canShowAllowedStoresOnly;
  addSnackbarMessages = useSnackbar().addSnackbarMessages;

  storesLoading = false;
  downloadLoading = false;
  poiGroupId: number;

  rules: typeof VuetifyValidator = VuetifyValidator;

  // 投稿名
  postName: string = "";
  // グループ・店舗選択
  allSelect = false;
  areasRows: SelectItem[] = [];
  selectedAreas: number[] = [];
  storesRows: SelectItem[] = [];
  selectedStores: number[] = [];
  storeCount = 0;
  // 投稿先メディア選択
  gmbPostTypes: { label: string; value: GMBPostType }[] = [
    { value: "info", label: "最新情報" },
    { value: "event", label: "イベント" },
    { value: "benefits", label: "特典" },
    { value: "product", label: "商品" },
    { value: "covid19", label: "Covid19" },
  ];
  gbp: string = "";
  fb: boolean = false;
  ig: boolean = false;
  yahooPostTypes: { value: EntitiesYahooFeedType; label: string }[] = [
    { value: "NOTICE", label: "お知らせ" },
    { value: "DAYOFF", label: "営業状況" },
    { value: "CAMPAIGN", label: "キャンペーン" },
    { value: "ETC", label: "その他" },
  ];
  yahoo: EntitiesYahooFeedType = "";
  hosting: boolean = false;

  get selectStoresDict(): PostStoresSelectDict {
    return postStoresSelectDict(this.canShowAllowedStoresOnly);
  }

  async created(): Promise<void> {
    this.storesLoading = true;
    this.poiGroupId = this.company.poiGroupID;
    // GBPコンソールが使えない場合は、商品投稿できない
    if (!this.company.canUseGbpConsole) {
      this.gmbPostTypes = this.gmbPostTypes.filter((t) => t.value !== "product");
    }
    await this.fetch();
    this.storesLoading = false;
  }

  async fetch(): Promise<void> {
    this.allSelect = false;
    const post = wordDictionary.stores.options.post;
    // 店舗リストから「有効・GMBStoreCodeあり・オプション契約あり」の店舗のみに絞り込む
    let stores = JSON.parse(JSON.stringify(this.stores.stores ?? [])) as EntitiesStore[];
    stores = stores.filter(
      (s) => s.enabled && s.gmbStoreCode?.length > 0 && s.options?.includes(post)
    );
    // プルダウンを作成する
    ({ areas: this.areasRows, stores: this.storesRows } = makeSelectItems(
      this.areaStores,
      stores,
      true
    ));
    // 投稿可能な店舗が1件もなければ未契約画面へ遷移
    if (this.storesRows.filter((s) => s?.id).length === 0) {
      this.$router.push({ name: "InquiriesForm", query: { from: this.$route.path } });
      return;
    }
    // 重複を除外した店舗数を計算する
    const storeIds: { [id: string]: any } = {};
    this.storesRows.filter((s) => s?.id).forEach((s) => (storeIds[s.id] = true));
    this.storeCount = Object.keys(storeIds).length;
  }

  async confirm(): Promise<void> {
    const params: ControllersTemplateCreateInput = {};
    params.title = this.postName ?? "";
    if (!this.allSelect) {
      params.areas = this.selectedAreas;
      params.pois = this.selectedStores;
    }
    params.gbpType = this.gbp ? this.gbp : "";
    params.fbType = this.fb ? "organic" : "";
    params.igType = this.ig ? "organic" : "";
    params.yahooType = this.yahoo;
    params.hostingType = this.hosting ? "normal" : "";

    this.downloadLoading = true;
    await this.downloadTemplate(params);
    this.downloadLoading = false;
  }

  async downloadTemplate(params: ControllersTemplateCreateInput): Promise<void> {
    if (params.title.length > 80) {
      this.addSnackbarMessages({
        text: "投稿名は80文字以内にしてください",
        color: "warning",
      });

      return;
    }

    if (!this.allSelect && params.areas.length === 0 && params.pois.length === 0) {
      this.addSnackbarMessages({
        text: "投稿するグループ/店舗を選択してください",

        color: "warning",
      });
      return;
    }

    if (
      !params.gbpType &&
      !params.fbType &&
      !params.igType &&
      !params.yahooType &&
      !params.hostingType
    ) {
      this.addSnackbarMessages({
        text: "投稿メディアを選択してください",
        color: "warning",
      });
      return;
    }

    await requiredAuth<ControllersTemplateCreateOutput>(
      "post",
      `${import.meta.env.VITE_APP_API_BASE}v2/companies/${
        this.company.poiGroupID
      }/post/template/create`,
      null,
      params
    )
      .then((res: AxiosResponse) => {
        if (!res?.data?.url) {
          throw "レスポンスにダウンロード用のURLが含まれなかった";
        }
        const link = document.createElement("a");
        link.href = res.data.url;
        link.click();
        this.addSnackbarMessages({
          text: "テンプレートファイルをダウンロードしました",
          color: "success",
        });
        this.$router.push({ name: "V2PostFile" });
      })
      .catch((ex) => {
        console.error(ex);

        let message = "テンプレートのダウンロードに失敗しました";
        if (ex as AxiosError) {
          message = ex.response.data.errorMessage;
        }

        this.addSnackbarMessages({
          text: message,
          color: "danger",
        });
      });
  }

  navigateToHistories() {
    this.$router.push({ name: "V2PostHistories" });
  }
}
export default toNative(V2PostTemplate);
</script>

<style lang="scss" scoped>
.post-form {
  background-color: white;
  // タイムラインを固定表示するためにコンテンツ部分をウィンドウの高さにする
  height: calc(100vh - 80x);
  overflow-y: scroll;
  overflow-x: hidden;
}

.post-title-0st {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 20px;
}

.post-title-1st {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 10px;
}

.post-title-2nd {
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 10px;
}

.post-title-3rd {
  font-size: 0.8rem;
  font-weight: bold;
  margin: 10px 10px 10px 20px;
  padding-top: 10px;
}

.post-subtitle {
  @extend .post-title-3rd;

  margin-top: 0;
  margin-left: 0;
  padding-top: 0;
}

.post-desc {
  font-size: 0.8rem;
  margin: 0 0 0 20px;
}

.post-check {
  border: 1px solid #b9b9b9;
  margin-left: 40px;
  padding: 10px 0 10px 10px;
  width: 360px;
}

.post-title-row {
  margin-left: 40px;
}

.post-title {
  font-weight: bold;
  padding-top: 20px;
}

.text-indent-1 {
  text-indent: 1em;
}

.text-indent-2 {
  text-indent: 2em;
}

.text-black {
  color: #000;
}

.post-radio {
  margin-left: 40px;
}

.post-switch {
  margin-left: 40px;
}
</style>
