<template>
  <div class="common-error">
    <div class="code">
      {{ code }}
    </div>
    <!-- eslint-disable-next-line -->
    <div class="message" v-html="sanitize(message)"></div>
    <router-link v-if="back === 'true'" :to="{ name: 'UserLogin' }" class="link-button">
      <button class="button is-primary mr-1" type="button">ログイン画面へ</button>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute, useRouter, type RouteLocationNormalized } from "vue-router";
import sanitizeHtml from "sanitize-html";
export default defineComponent({
  data: () => {
    return {
      $route: useRoute(),
      $router: useRouter(),
      code: "",
      message: "ERROR",
      back: "false",
    };
  },
  created() {
    const route = this.$route as RouteLocationNormalized;
    this.code = (route.query.code as string) ?? "";
    this.message = (route.query.message as string) ?? "";
    this.back = (route.query.back as string) ?? "";
  },
  methods: {
    sanitize(text: string): string {
      return sanitizeHtml(text, {
        allowedTags: ["br"],
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.common-error {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f6f7;
  & > .code {
    background-color: inherit;
    font-size: 400%;
    font-weight: bold;
  }
  & > .message {
    text-align: center;
    background-color: inherit;
    font-size: 200%;
  }
  .link-button {
    margin-top: 60px;
  }
}
</style>
