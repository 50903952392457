import { v4 as uuidv4 } from "uuid";
import Admin from "@/components/admin";
import Beta from "@/components/admin/contents/beta/beta.vue";
import Companies from "@/components/admin/contents/companies";
import CompaniesForm from "@/components/admin/contents/companies/form";
import FacebookDeletion from "@/components/admin/contents/facebook-deletion/FacebookDeletion.vue";
import HealthCheck from "@/components/admin/contents/health-check";
import HealthCheckLog from "@/components/admin/contents/health-check-log";
import AdminStores from "@/components/admin/contents/stores";
import NotFound from "@/components/errors/not-found";
import Error from "@/components/errors/Error.vue";
import FoodMenuGroupEdit from "@/components/root/contents/food-menu/food-menu-group-edit.vue";
import FoodMenuGroupList from "@/components/root/contents/food-menu/food-menu-group-list.vue";
import QandaList from "@/components/root/contents/qanda/qanda-list.vue";
import Root from "@/components/root";
import SetupGbpToken from "@/components/setup-gbp-token.vue";
import Areas from "@/components/root/contents/areas";
import AreasForm from "@/components/root/contents/areas/form";
import ReportFiles from "@/components/root/contents/dashboard/report-files";
import GmbMediaEdit from "@/components/root/contents/gmbmedia/GmbMediaEdit.vue";
import GMBMediaHistories from "@/components/root/contents/gmbmedia/histories";
import GBPMediaUpload from "@/components/root/contents/gmbmedia/mobile/upload.vue";
import YpMediaEdit from "@/components/root/contents/ypmedia/YpMediaEdit.vue";
import YpMediaHistories from "@/components/root/contents/ypmedia/histories";
import InquiriesForm from "@/components/root/contents/inquiries/inquiries-form.vue";
import Performance from "@/components/root/contents/performance/performance-all.vue";
import Stores from "@/components/root/contents/stores";
import StoresForm from "@/components/root/contents/stores/form";
import StoresSpreadForm from "@/components/root/contents/stores/spread-form";
import MediaLink from "@/components/root/contents/media-link/media-link.vue";
import AppleInfo from "@/components/root/contents/apple-info/apple-info.vue";
import StoreUpdates from "@/components/root/contents/store-updates/StoreUpdates.vue";
import StoreKeywords from "@/components/admin/contents/stores/store-keywords/StoreKeywords.vue";
import Users from "@/components/root/contents/users";
import UsersForm from "@/components/root/contents/users/form";
import UserLogin from "@/components/user-login";
import { createRouter, createWebHashHistory } from "vue-router";
import type { RouteRecordRaw, RouteLocationNormalized } from "vue-router";
// 新UI投稿
import V2PostHistories from "@/components/root/contents/v2-posts-histories/post-histories.vue";
import V2Posts from "@/components/root/contents/v2-posts/posts.vue";
// サイテーション設定画面
import CitationSettings from "@/components/admin/contents/citation-settings/CitationSettings.vue";
import CitationSettingsYplace from "@/components/admin/contents/citation-settings/CitationSettingsYplace.vue";
import CitationSettingsApple from "@/components/admin/contents/citation-settings/CitationSettingsApple.vue";

import wordDictionary from "../word-dictionary";
import { currentTheme } from "@/components/shared/theme";
import type { FeatureToggle } from "./FeatureToggle";
import { getFeatureToggle } from "./FeatureToggle";

import ReviewAnalysis from "@/components/root/contents/dashboard/review/review-analysis.vue"; // 新クチコミダッシュボード 分析
import ReviewReply from "@/components/root/contents/dashboard/review/review-reply.vue"; // 新クチコミダッシュボード 検索/返信
import ServiceItems from "@/components/admin/contents/beta/service-items"; // サービス一覧 表示・編集
import V2PostTemplate from "@/components/root/contents/v2-post-template/v2-post-template.vue"; // v2投稿テンプレート作成
import V2PostFile from "@/components/root/contents/v2-post-file/v2-post-file.vue"; // v2投稿ファイル
import CustomRolesList from "@/components/root/contents/custom-roles/list.vue";
import CustomRolesForm from "@/components/root/contents/custom-roles/form.vue";
import UpdateMultilingualInfo from "@/components/admin/contents/beta/update-multilingual-info/update-multilingual-info.vue";

import { useSnackbar } from "@/storepinia/snackbar";
import { useIndexedDb } from "@/storepinia/idxdb";
import * as am5 from "@amcharts/amcharts5";

const PRODUCT_NAME = wordDictionary.service.fullName;

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/not-found",
        name: "NotFound",
        component: NotFound,
        meta: { title: "ページが存在しません" },
    },
    {
        path: "/error",
        name: "CommonError",
        component: Error,
        meta: { title: "エラー" },
    },
    {
        path: "/login",
        component: UserLogin,
        name: "UserLogin",
        props: true,
        beforeEnter: async (to, from, next) => {
            sessionStorage.IdToken = null;
            sessionStorage.AccessToken = null;
            sessionStorage.RefreshToken = null;
            next();
        },
        meta: { uiType: "login", title: "ログイン" },
    },
    {
        // 企業初期設定画面（GBP OAuth認可画面）
        // path を companies 下においていないのは、サイドメニューとトップバーを使わないため
        path: "/setup-gbp-token/:poiGroupId",
        name: "SetupGbpToken",
        component: SetupGbpToken,
        meta: { title: "企業初期設定画面" },
    },
    {
        path: "/companies",
        component: Root,
        beforeEnter: async (to, from, next) => {
            document.title = `${PRODUCT_NAME}`;
            // 企業の初期設定が行われていなければ、企業初期設定画面に飛ばす
            // （ただしユーザ管理系画面だけは初期設定していなくても使える）
            if (["Users", "UsersNew", "OwnersNew", "UsersEdit"].includes(to.name.toString())) {
                next();
                return;
            }
            const company = useIndexedDb().company;
            if (
                company.useCustomerGbpToken &&
                (!company.customerGbpTokenInfo?.hasCustomerGbpToken || !company.gmbAccount)
            ) {
                next({ path: `/setup-gbp-token/${company.poiGroupID}` });
                return;
            }
            next();
        },
        children: [
            {
                path: ":poiGroupId/report/summary",
                component: Performance,
                name: "Performance",
                props: (route) => ({
                    poiGroupId: route.params.poiGroupId,
                    reportType: "ALL",
                }),
                meta: { title: "パフォーマンス" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveSummary) {
                        next({
                            name: "ReviewAnalysis",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },
            {
                // クチコミ分析
                path: ":poiGroupId/review/review-analysis",
                component: ReviewAnalysis,
                name: "ReviewAnalysis",
                props: true,
                meta: { uiType: "review_analysis", title: "クチコミ" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveReview) {
                        next({
                            name: "StoresSpreadForm",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },
            {
                // クチコミ検索／返信
                path: ":poiGroupId/review/review-reply",
                component: ReviewReply,
                name: "ReviewReply",
                props: true,
                meta: { uiType: "search_reply", title: "クチコミ返信" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveReview) {
                        if (useIndexedDb().isMobile) {
                            useIndexedDb().isDrawerOpened = false;
                        }
                        next({
                            name: "Error",
                            params: { poiGroupId: to.params.poiGroupId },
                            query: { message: "ご利用いただける機能がありません", back: "true" },
                        });
                        return;
                    }
                    // クチコミアラートメールから遷移した時に付与されるクエリストリングを削除
                    const paths = to.fullPath.split("?");
                    if (paths.length > 1 && paths[1].startsWith("poiID=")) {
                        to.fullPath = paths[0];
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/users",
                component: Users,
                name: "Users",
                meta: { title: "ユーザー管理" },
            },
            {
                path: ":poiGroupId/users/new",
                component: UsersForm,
                name: "UsersNew",
                props: { default: true, onlyOwner: false, isNew: true },
                meta: { title: "ユーザー登録" },
            },
            {
                path: ":poiGroupId/owners/new",
                component: UsersForm,
                name: "OwnersNew",
                props: { default: true, onlyOwner: true, isNew: true },
                meta: { title: "オーナー登録" },
            },
            {
                path: ":poiGroupId/users/:userId/edit",
                component: UsersForm,
                name: "UsersEdit",
                // ユーザーでログインしてる場合は「プロファイル管理」になる処理を↓でしている
                // client/src/components/root/contents/users/form/script.ts
                meta: { title: "ユーザー編集" },
            },
            {
                path: ":poiGroupId/stores/edit",
                component: StoresSpreadForm,
                name: "StoresSpreadForm",
                props: (route) => ({
                    failedType: route.query.failedType as string,
                    failedStores: route.query.failedStores as string,
                }),
                meta: { uiType: "stores_update", title: "基本情報" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveStoreLocation) {
                        next({
                            name: "StoreUpdates",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },

            {
                path: ":poiGroupId/stores/:poiId/edit",
                component: StoresForm,
                name: "StoresEdit",
                meta: { uiType: "stores_edit", title: "店舗設定管理(編集)" },
            },
            {
                path: ":poiGroupId/media-link",
                component: MediaLink,
                name: "MediaLink",
                meta: { title: "メディアリンク", uiType: "media-link" },
            },
            {
                path: ":poiGroupId/apple-info",
                component: AppleInfo,
                name: "AppleInfo",
                meta: { title: "Apple Business Connect(Apple Maps)店舗情報", uiType: "apple_info" },
            },
            {
                path: ":poiGroupId/areas",
                component: Areas,
                name: "Areas",
                meta: { title: "グループ管理" },
            },
            {
                path: ":poiGroupId/areas/new",
                component: AreasForm,
                name: "AreasNew",
                props: true,
                meta: { title: "グループ登録" },
            },
            {
                path: ":poiGroupId/areas/:areaId/edit",
                component: AreasForm,
                name: "AreasEdit",
                props: true,
                meta: { title: "グループ編集" },
            },
            {
                path: ":poiGroupId/stores",
                component: Stores,
                name: "Stores",
                props: true,
                meta: { title: "店舗設定管理(一覧)" },
            },
            {
                path: ":poiGroupId/stores/:poiId/edit",
                component: StoresForm,
                name: "StoresEdit",
                meta: { title: "店舗設定管理(編集)" },
            },
            {
                path: ":poiGroupId/v2posts/histories",
                component: V2PostHistories,
                name: "V2PostHistories",
                meta: { uiType: "stores_post_v2", title: "投稿一覧" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactivePost) {
                        next({
                            name: "FoodMenuGroupList",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/v2posts",
                component: V2Posts,
                name: "V2Posts",
                props: (route) => ({
                    // SP版のオーナーは投稿画面を初期表示する
                    poiGroupId: route.params.poiGroupId,
                    mode: "new",
                }),
                meta: { uiType: "stores_post_v2", title: "投稿作成" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactivePost) {
                        if (useIndexedDb().isMobile) {
                            next({
                                name: "ReviewReply",
                                params: { poiGroupId: to.params.poiGroupId },
                            });
                        } else {
                            next({
                                name: "FoodMenuGroupList",
                                params: { poiGroupId: to.params.poiGroupId },
                            });
                        }
                        return;
                    } else if (!useIndexedDb().canManagePost) {
                        next({
                            name: "V2PostHistories",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/v2posts/:mode",
                component: V2Posts,
                name: "V2PostsEdit",
                props: true,
                meta: { uiType: "stores_post_v2", title: "投稿編集" },
            },
            {
                path: ":poiGroupId/gmbmedia/edit",
                component: GmbMediaEdit,
                name: "GmbMediaEdit",
                props: true,
                meta: { uiType: "stores_gmb_media", title: "画像・動画" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveMedia) {
                        next({
                            name: "V2PostHistories",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/gmbmedia/histories",
                component: GMBMediaHistories,
                name: "GMBMediaHistories",
                meta: { title: "画像・動画一括登録履歴" },
            },
            {
                path: ":poiGroupId/gmbmedia/upload",
                component: GBPMediaUpload,
                name: "GBPMediaUpload",
                meta: { uiType: "stores_gmb_media", title: "店舗画像一括更新" },
            },
            {
                path: ":poiGroupId/ypmedia/edit",
                component: YpMediaEdit,
                name: "YpMediaEdit",
                props: true,
                meta: { uiType: "stores_gmb_media", title: "画像・動画" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveMedia) {
                        next({
                            name: "V2PostHistories",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/ypmedia/histories",
                component: YpMediaHistories,
                name: "YpMediaHistories",
                meta: { title: "Y!プレイス画像登録履歴" },
            },
            {
                path: ":poiGroupId/inquiries/new",
                component: InquiriesForm,
                name: "InquiriesForm",
                props: true,
                meta: { title: "お問い合わせ" },
            },
            {
                path: ":poiGroupId/report-files",
                component: ReportFiles,
                name: "ReportFiles",
                props: true,
                meta: { title: "レポートダウンロード" },
            },
            {
                path: ":poiGroupId/store-updates",
                component: StoreUpdates,
                name: "StoreUpdates",
                props: true,
                meta: { title: "変更提案" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveStoreUpdate) {
                        next({
                            name: "GmbMediaEdit",
                            params: { poiGroupId: to.params.poiGroupId },
                        });
                        return;
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/error",
                name: "Error",
                component: Error,
                meta: { title: "エラー" },
            },
            {
                path: ":poiGroupId/citation-settings",
                name: "CitationSettings",
                component: CitationSettings,
                meta: { uiType: "citation_settings", title: "Facebook/Instagram 連携設定" },
            },
            {
                path: ":poiGroupId/citation-settings-yplace",
                name: "CitationSettingsYplace",
                component: CitationSettingsYplace,
                meta: { uiType: "citation_settings_yplace", title: "Yahoo!プレイス連携" },
            },
            {
                path: ":poiGroupId/citation-settings-apple",
                name: "CitationSettingsApple",
                component: CitationSettingsApple,
                meta: { uiType: "citation_settings_apple", title: "Apple Business Connect連携" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveApple) {
                        next({ name: "ReviewReply", params: { poiGroupId: to.params.poiGroupId } });
                        return;
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/service-items",
                name: "ServiceItems",
                component: ServiceItems,
                meta: { uiType: "service_items", title: "サービス機能" },
            },
            {
                path: ":poiGroupId/food-menu/groups",
                name: "FoodMenuGroupList",
                component: FoodMenuGroupList,
                meta: { title: "メニュー" },
                beforeEnter: async (to, from, next) => {
                    if (useIndexedDb().isInactiveMenu) {
                        next({ name: "ReviewReply", params: { poiGroupId: to.params.poiGroupId } });
                        return;
                    }
                    next();
                },
            },
            {
                path: ":poiGroupId/food-menu/groups/:foodMenuGroupId/edit",
                name: "FoodMenuGroupEdit",
                component: FoodMenuGroupEdit,
                meta: { title: "メニュー編集" },
            },
            {
                path: ":poiGroupId/qanda",
                name: "QandaList",
                component: QandaList,
                meta: { title: "質問と回答" },
            },
            {
                path: ":poiGroupId/v2-post-template",
                name: "V2PostTemplate",
                component: V2PostTemplate,
                meta: { uiType: "v2-post-template", title: "投稿テンプレートの作成" },
            },
            {
                path: ":poiGroupId/v2-post-file",
                name: "V2PostFile",
                component: V2PostFile,
                props: (route) => ({
                    mode: route.query.mode,
                    defaultAspectNoCheck: route.query.aspectNoCheck,
                }),
                meta: { uiType: "v2-post-file", title: "ファイルでの投稿" },
            },
            {
                path: ":poiGroupId/update-multilingual-info",
                name: "UpdateMultilingualInfo",
                component: UpdateMultilingualInfo,
                meta: { uiType: "update-multilingual-info", title: "多言語店舗登録" },
            },
            {
                path: ":poiGroupId/custom-roles",
                name: "CustomRoles",
                component: CustomRolesList,
                meta: { uiType: "custom-roles", title: "権限管理" },
            },
            {
                path: ":poiGroupId/custom-roles/new",
                name: "CustomRolesNew",
                component: CustomRolesForm,
                meta: { uiType: "custom-roles", title: "権限作成" },
            },
            {
                path: ":poiGroupId/custom-roles/:roleLv/edit",
                name: "CustomRolesEdit",
                component: CustomRolesForm,
                meta: { uiType: "custom-roles", title: "権限編集" },
            },
        ],
    },
    {
        path: "/admin",
        component: Admin,
        name: "Admin",
        props: true,
        beforeEnter: async (to, from, next) => {
            document.title = `${PRODUCT_NAME}-社内用`;
            next();
        },
        children: [
            {
                path: "beta",
                component: Beta,
                name: "Beta",
                props: true,
                meta: { title: "ベータ機能" },
            },
            {
                path: "dashboard/health-check",
                component: HealthCheck,
                name: "HealthCheck",
                props: true,
                meta: { title: "ヘルスチェック" },
            },
            {
                path: "dashboard/health-check/:customerPoiGroupId/health-check-logs",
                component: HealthCheckLog,
                name: "HealthCheckLog",
                props: true,
                meta: { title: "ヘルスチェックログ" },
            },
            {
                path: "companies",
                component: Companies,
                name: "AdminCompanies",
                props: true,
                meta: { title: "企業管理" },
            },
            {
                path: "companies/new",
                component: CompaniesForm,
                name: "AdminCompaniesNew",
                props: true,
                meta: { uiType: "companies_form", title: "企業登録" },
            },
            {
                path: "companies/:poiGroupId/edit",
                component: CompaniesForm,
                name: "AdminCompaniesEdit",
                meta: { uiType: "companies_form", title: "企業編集" },
            },
            {
                path: "companies/:poiGroupId/facebook-deletion/:confirmationCode",
                component: FacebookDeletion,
                name: "AdminFacebookDeletion",
                props: true,
                meta: { title: "Facebook連携削除" },
            },
            {
                path: "companies/:poiGroupId/stores",
                component: AdminStores,
                name: "AdminStores",
                props: true,
                meta: { title: "店舗管理" },
            },
            {
                path: "companies/:poiGroupId/stores/store-keywords",
                component: StoreKeywords,
                name: "StoreKeywords",
                props: true,
                meta: { title: "検索キーワード設定" },
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/not-found",
    },
];
export const router = createRouter({
    routes,
    history: createWebHashHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

let isBrowserReloading = true;
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
    // ページ遷移時にamcharts5エレメントをdisposeする
    try {
        am5.disposeAllRootElements();
    } catch (e) {
        // 何らかの理由でdisposeできなかった場合は、エラーを出力して続行する
        console.log("[am5] dispose error", e);
    }

    await useIndexedDb().initIfNotInitialized();

    // ブラウザによるリロード時Snackbarのメッセージを初期化する
    if (isBrowserReloading) {
        useSnackbar().resetSnackbarQueue();
        isBrowserReloading = false;
    }

    // ヘルスチェックのための実行時識別子を生成してmetaに保存する. requestID とは、クライアントが画面遷移の都度生成するUUID
    to.meta.requestID = uuidv4();

    // 遷移先がエラー画面ならば何もチェックしない
    if (to.name === "Error" || to.name === "CommonError") {
        next();
        return;
    }
    // ログインが必要な画面か否か
    const isLoginNeeded = to.path.startsWith("/companies") || to.path.startsWith("/admin");
    // ログインが必要な画面で、ログインしていなかったら、ログイン画面へ遷移させる
    if (isLoginNeeded && (sessionStorage.IdToken == null || sessionStorage.IdToken === "null")) {
        next({ name: "UserLogin", query: { redirect: to.fullPath } });
        return;
    }
    const featureName = toFeatureName(to);
    const poiGroupId = to.params.poiGroupId ? parseInt(to.params.poiGroupId as string) : 0;
    // フィーチャトグルを取得
    const featureToggle: FeatureToggle = await getFeatureToggle(
        isLoginNeeded,
        poiGroupId,
        featureName
    );
    // フィーチャトグルの取得に失敗したらエラー画面へ遷移させる
    switch (featureToggle.statusCode) {
        case 0:
            next({
                name: "CommonError",
                query: {
                    message: "ただいま利用ができません",
                    back: "false",
                },
            });
            return;
        case 401:
            next({
                name: "CommonError",
                query: {
                    code: "401",
                    message: "セッションがタイムアウトしました。<br>ログインし直してください。",
                    back: "true",
                },
            });
            return;
        case 403:
            next({
                name: "CommonError",
                query: {
                    code: "403",
                    message: "所属する企業以外にアクセスしています。<br>ログインし直してください。",
                    back: "true",
                },
            });
            return;
    }
    // ステータスが STOP の場合は画面遷移させない
    if (featureToggle.getStatus() === "STOP") {
        useSnackbar().addSnackbarMessages({
            text: featureToggle.getMessage(),
            color: "danger",
        });
        next(false);
        return;
    }
    // STOP 以外は画面遷移させる
    to.meta.featureToggle = featureToggle;
    next();
});
/**
 * パスを元にフィーチャトグルの機能名を作成する
 */
export function toFeatureName(to: RouteLocationNormalized): string {
    if (!to.matched || to.matched.length === 0) {
        return null;
    }
    const matched = to.matched[to.matched.length - 1];
    return (
        "client" +
        matched.path
            .split("/")
            .map((part) => (part.startsWith(":") ? "x" : part))
            .join("/")
    );
}

const theme = currentTheme();
router.afterEach((to) => {
    const title = to.meta.title ? `${to.meta.title} | ${theme.appName}` : theme.appName;
    document.title = title;
});
