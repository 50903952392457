<template>
  <div>
    <div class="text-h6 font-weight-bold">メディアリンク</div>
    <div>
      <div class="d-flex align-center mb-2">
        <auto-complete-card
          v-if="!canShowAllowedStoresOnly"
          v-model="filter.areaIds"
          :items="areaItems"
          label="グループを選択して下さい"
          unit="グループ"
          show-all-select
          :chip-count="6"
          hint=""
          :persistent-hint="false"
          hide-details
          single-line
          density="compact"
          :clearable="false"
          data-testid="area-selector"
          class="mr-1"
          style="max-width: 250px"
          @update:model-value="updateFilter"
        />
        <v-text-field
          v-model="filter.searchWord"
          label="検索キーワード"
          variant="underlined"
          density="compact"
          single-line
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          color="primary"
          class="mr-2 w-20"
          style="width: 200px; max-width: 200px"
          @keypress.enter="updateFilter"
          @click:clear="
            filter.searchWord = '';
            updateFilter();
          "
        />
        <v-btn size="small" class="primary me-auto" @click="updateFilter()">絞り込み</v-btn>
        <v-checkbox
          v-if="canManageMediaLink"
          v-model="filter.onlyDirtyRows"
          class="me-1"
          color="primary"
          hide-details
          @update:model-value="updateFilter"
        >
          <template #label>変更行のみ表示</template>
        </v-checkbox>
        <v-btn
          v-if="canManageMediaLink"
          :disabled="!isDirty || isLoading"
          size="small"
          class="primary ml-5"
          @click="submit"
        >
          変更内容を反映
        </v-btn>
        <!-- レイアウトの関係で閲覧のみの場合はこちらにエクスポートボタンを持ってきている -->
        <v-btn
          v-else
          :disabled="isLoading"
          size="small"
          class="primary me-3"
          prepend-icon="mdi-download"
          text="XLSXエクスポート"
          @click="exportXlsx"
        />
      </div>
      <div v-if="canManageMediaLink" class="d-flex align-center mb-2">
        <v-btn
          v-if="prevErrorMessage.length > 0"
          size="small"
          class="primary me-1"
          @click="
            dialog.title = '前回反映時のエラー';
            dialog.show = true;
          "
        >
          前回反映時のエラーを表示
        </v-btn>
        <input ref="importInput" type="file" hidden @change="importXlsx" />
        <v-btn
          :disabled="isLoading"
          size="small"
          class="primary ml-auto me-1"
          prepend-icon="mdi-upload"
          text="XLSXインポート"
          @click="($refs.importInput as HTMLInputElement).click()"
        />
        <v-btn
          :disabled="isLoading"
          size="small"
          class="primary me-1"
          prepend-icon="mdi-download"
          text="XLSXエクスポート"
          @click="exportXlsx"
        />
      </div>
    </div>
    <div style="height: calc(100vh - 240px)" class="parent-container">
      <div v-if="isLoading" class="custom-progress-circular-container">
        <v-progress-circular indeterminate size="80" :width="4" color="primary" />
      </div>
      <div class="custom-grid">
        <c-grid
          ref="grid0"
          class="cgrid"
          font="12.8px sans-serif"
          :frozen-col-count="1"
          :theme="customTheme"
          style="font-size: small"
          :data="dataSource"
          @resize="grid.updateSize()"
        >
          <c-grid-column
            caption="店舗ID"
            :field="(item: Row) => item.poiID"
            :column-style="columnStyle"
          />
          <c-grid-column
            caption="店舗コード"
            field="storeCode"
            :width="100"
            :column-style="columnStyle"
          />
          <c-grid-column
            caption="ビジネス名"
            field="storeName"
            :width="210"
            :column-style="columnStyle"
          />
          <c-grid-input-column
            caption="URL Facebook"
            field="facebook"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('facebook')"
            :column-style="bgStyle('facebook')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="URL Instagram"
            field="instagram"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('instagram')"
            :column-style="bgStyle('instagram')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="URL LinkedIn"
            field="linkedin"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('linkedin')"
            :column-style="bgStyle('linkedin')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="URL Pinterest"
            field="pinterest"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('pinterest')"
            :column-style="bgStyle('pinterest')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="URL Tiktok"
            field="tiktok"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('tiktok')"
            :column-style="bgStyle('tiktok')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="URL Twitter"
            field="twitter"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('twitter')"
            :column-style="bgStyle('twitter')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="URL YouTube"
            field="youtube"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('youtube')"
            :column-style="bgStyle('youtube')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="Y!プレイスウェブサイト"
            field="yahooPlaceWebSite"
            :width="210"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('yahooPlaceWebSite')"
            :column-style="bgStyle('yahooPlaceWebSite')"
            :input-validator="urlValidator"
          />
          <c-grid-input-column
            caption="LINE 公式アカウント名"
            field="line"
            :width="120"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('line')"
            :column-style="bgStyle('line')"
          />
          <c-grid-input-column
            caption="Appleウェブサイト"
            field="appleWebSite"
            :width="210"
            :readonly="(r) => !canManageMediaLink"
            :disabled="isDisabled('appleWebSite')"
            :column-style="bgStyle('appleWebSite')"
            :input-validator="urlValidator"
          />
        </c-grid>
      </div>
    </div>
    <!-- 反映ダイアログ -->
    <ProgressDialog
      v-model="dialog.show"
      :title="dialog.title"
      :message="dialog.message"
      :percentage="dialog.percentage"
      submit-button="OK"
      :submit-button-disabled="dialog.submitButtonDisabled"
      max-width="600"
      @submit="dialog.show = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import type { ListGrid } from "cheetah-grid";
import * as cg from "cheetah-grid";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ProgressDialog from "./progress-dialog.vue";
import { Model } from "@/components/root/contents/stores/spread-form/table";
import { useIndexedDb } from "@/storepinia/idxdb";
import { api, type LocationAndAttributes } from "@/helpers/api/food-menu";
import { api as yapi } from "@/helpers/api/yahoo";
import { api as abcapi, BATCH_GET_LIMIT } from "@/helpers/api/apple";
import type {
  EntitiesCompany,
  EntitiesStore,
  EntitiesYahooPlaceBusiness,
  MybusinessbusinessinformationAttribute as Attribute,
  EntitiesPutGMBLocationResponse,
  EntitiesPutGMBLocationRequest,
  EntitiesAppleLocation,
  EntitiesAppleLocationUpdateMask,
} from "@/types/ls-api";
import { uniq } from "@/helpers/arrays";
import { parallels } from "@/helpers/parallel-promise";
import { REGEX_URL_OR_BLANK, requiredAuth } from "@/helpers";
import { apiBase } from "@/const";
import { getOperationLogParams } from "@/routes/operation-log";
import { useRoute, useRouter, type RouteLocationNormalizedLoaded } from "vue-router";
import { getAppleErrorMessage, getGBPErrorMessage, getNormalErrorMessage } from "@/helpers/error";
import { Row, columns } from "./model";
import { getDashboardUrl } from "@/helpers/gmb";
import { getAppleLocationID, getErrorMessageList } from "@/helpers/apple";
import { makeSelectItems, type SelectItem } from "@/helpers/select-items";
import AutoCompleteCard from "@/components/shared/auto-complete-card/AutoCompleteCard.vue";
import {
  createDataUpdateGridTheme,
  dataUpdateColumnStyle,
} from "@/components/shared/cheetah-grid-shared";
import wordDictionary from "@/word-dictionary";
import dayjs from "dayjs";
import { arrayBufferToStringsArrays, read } from "@/helpers/xlsxtools";
import { useSnackbar } from "@/storepinia/snackbar";
import type { AxiosError } from "axios";
class FilterDataSource extends cg.data.FilterDataSource<Row> {}

type Patch = {
  num: number;
  poiId: number;
  storeName: string;
  location: LocationAndAttributes;
  attributes: Attribute[];
  attributeMask: string[];
  ypb?: EntitiesYahooPlaceBusiness;
  abc?: EntitiesAppleLocation;
  abcUpdateMask?: EntitiesAppleLocationUpdateMask[];
};
type Err = { poiId: number; storeName: string; message: string };
const model = new Model();
export default defineComponent({
  components: { ProgressDialog, AutoCompleteCard },
  data: () => {
    const customTheme = createDataUpdateGridTheme();
    return {
      $route: useRoute(),
      $router: useRouter(),
      isLoading: false,
      company: useIndexedDb().company,
      hasFullCompanyAccess: useIndexedDb().hasFullCompanyAccess,
      canShowAllowedStoresOnly: useIndexedDb().canShowAllowedStoresOnly,
      canManageMediaLink: useIndexedDb().canManageMediaLink,
      areaItems: [] as SelectItem[],
      isDirtyTrigger: ref(0),
      filter: {
        searchWord: "",
        areaIds: [] as number[],
        onlyDirtyRows: false,
      },
      prevErrorMessage: "",
      // cheetah-gridのカスタムテーマ設定（失敗ステータス/ダーティーフラグの背景色を変更）
      customTheme,
      columnStyle: dataUpdateColumnStyle,
      urlValidator: (value: string) => {
        value = value ?? "";
        return value.match(REGEX_URL_OR_BLANK) === null ? "URL形式で入力してください" : null;
      },
      rows: [] as Row[],
      dataSource: null as FilterDataSource,
      errs: [] as Err[],
      locs: {} as { [locName: string]: LocationAndAttributes },
      dialog: {
        show: false,
        percentage: 0,
        title: "",
        message: "",
        submitButtonDisabled: true,
      },
    };
  },
  computed: {
    poiGroupId: function () {
      return parseInt(this.$route.params.poiGroupId as string, 10);
    },
    grid: function (): ListGrid<Row> {
      return this.$refs.grid0 as ListGrid<Row>;
    },
    isDirty: function (): boolean {
      this.isDirtyTrigger;
      for (const c of columns) {
        if (!c.gbp && !c.yp && !c.abc) continue;
        if (this.rows.some((r) => r.hasFieldChanged(c.field as string))) {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    // グループプルダウン作成
    ({ areas: this.areaItems } = makeSelectItems(
      useIndexedDb().areaStores,
      useIndexedDb().stores?.stores,
      false
    ));
    await model.init();
    this.grid.updateSize();
    this.grid.invalidate();
    this.isLoading = true;
    try {
      await this.fetch();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async updateFilter(): Promise<void> {
      // データが無い場合は何もしない(fetch時に入力条件でフィルタされる)
      if (this.dataSource == null) return;
      // ダーディフラグを更新
      const rows = this.dataSource.dataSource;
      for (let i = 0; i < rows.length; i++) {
        const row = await rows.get(i);
        row.updateIsDirty();
      }
      this.isDirtyTrigger++;
      // フィルターをセットして再描画
      this.dataSource.filter = this.makeFilter();
      this.grid.invalidate();
    },
    async fetch(): Promise<void> {
      const company: EntitiesCompany = useIndexedDb().company;
      const stores: EntitiesStore[] = useIndexedDb().stores.stores.filter((s) => s.enabled);
      // GBPの属性を取得する
      const locNames: string[] = stores
        .map((s) => s.gmbLocationID?.replace(/^accounts\/[^/]+\//, "") ?? "")
        .filter((s) => !!s);
      if (locNames.length !== 0) {
        const locations = await api.getLocationInParallel(
          this.poiGroupId,
          company.gmbAccount,
          locNames,
          "name,categories,attributes",
          100,
          10
        );
        locations.forEach((loc) => {
          this.locs[loc.location.name] = loc;
        });
      }
      // Yahooの属性を取得する
      // オーナー権限以上なら list で取得、それ以外は get で取得
      const yahoos: { [placeSeq: number]: EntitiesYahooPlaceBusiness } = {};
      if (this.hasFullCompanyAccess) {
        let uuids = stores.filter((s) => 0 < s.yahooplace?.placeSeq).map((s) => s.yahooplace?.uuid);
        uuids = uniq(uuids);
        for (const uuid of uuids) {
          let page = 0;
          while (0 <= page) {
            const res = await yapi.listPlaceBusiness(this.poiGroupId, uuid, 50, page);
            if (res.error) {
              useSnackbar().addSnackbarMessages({
                text: `店舗一覧の取得に失敗しました: ${res.error?.errorCode}, ${res.error?.reason}`,
                color: "danger",
              });
              return;
            }
            const data = res.data;
            data.list?.forEach((r) => {
              yahoos[r.placeSeq] = r;
            });
            page = data.totalCount <= data.page * data.size + data.list.length ? -1 : page + 1;
          }
        }
      } else {
        const poiIds = stores.filter((s) => 0 < s.yahooplace?.placeSeq).map((s) => s.poiID);
        for (const poiId of poiIds) {
          const res = await yapi.getPlaceBusiness(this.poiGroupId, poiId);
          if (res.error) {
            useSnackbar().addSnackbarMessages({
              text: `店舗一覧の取得に失敗しました: ${res.error?.errorCode}, ${res.error?.reason}`,
              color: "danger",
            });
            return;
          }
          const data = res.data;
          yahoos[data.placeSeq] = data;
        }
      }
      // Appleの情報を取得する
      const apples: { [locationID: string]: EntitiesAppleLocation } = {};
      const locationIds = stores
        .filter((s) => s.appleBusinessConnect?.locationId != null)
        .map((s) => s.appleBusinessConnect?.locationId);
      for (let i = 0; i < locationIds.length; i += BATCH_GET_LIMIT) {
        try {
          const res = await abcapi.batchGet(
            this.poiGroupId,
            locationIds.slice(i, i + BATCH_GET_LIMIT)
          );
          const errorMessageList = getErrorMessageList(res.error);
          if (errorMessageList.length > 0) {
            useSnackbar().addSnackbarMessages({
              text: `Appleの情報取得に失敗しました: ${errorMessageList.join("<br>")}`,
              color: "danger",
            });
            return;
          }
          res.data.data.forEach((location) => {
            apples[getAppleLocationID(location)] = location;
          });
        } catch (e) {
          const error = e as AxiosError<any>;
          useSnackbar().addSnackbarMessages({
            text: `Appleの情報取得に失敗しました: ${error.response?.data?.errorMessage}`,
            color: "danger",
          });
          return;
        }
      }
      // テーブルに表示するデータを作成
      const rows: Row[] = [];
      for (const s of stores) {
        const name = s.gmbLocationID?.replace(/^accounts\/[^/]+\//, "");
        const location = this.locs[name];
        const row = new Row(s, location?.location?.categories?.primaryCategory?.name ?? "");
        row.setEnabled(await useIndexedDb().getAttributeMetadata(row.categoryId));
        row.setYahoo(yahoos[row.yahooPlaceSeq]);
        row.setApple(apples[row.appleLocationID]);
        row.setGbpAttributes(location?.attributes);
        rows.push(row);
      }
      this.rows = rows;
      this.dataSource = new FilterDataSource(cg.data.DataSource.ofArray(rows), this.makeFilter());
    },
    makeFilter(): (row: Row) => boolean {
      return (row: Row): boolean => {
        if (
          this.filter.areaIds.length > 0 &&
          !this.filter.areaIds.some((value) => row.areas.includes(value))
        ) {
          return false;
        }
        const word = [
          row.poiID,
          row.storeCode,
          row.storeName,
          row.facebook,
          row.instagram,
          row.linkedin,
          row.pinterest,
          row.tiktok,
          row.twitter,
          row.youtube,
          row.yahooPlaceWebSite,
          row.line,
          row.appleWebSite,
        ].toString();
        if (this.filter.searchWord && !word.includes(this.filter.searchWord)) {
          return false;
        }
        if (this.filter.onlyDirtyRows && !row.isDirty) {
          return false;
        }
        return true;
      };
    },
    isDisabled: function (key: string): (row: Row) => boolean {
      return function (row: Row): boolean {
        return !(row.enabled?.[key] ?? false);
      };
    },
    /** 入力可能なカラムの style */
    bgStyle: function (key: string): (rec: Row) => { bgColor: string } {
      const isDisabled = this.isDisabled(key);
      return function (row: Row): { bgColor: string } {
        if (!row) return null;
        return {
          // disabled なら灰色 変更があれば水色
          bgColor: isDisabled(row) ? "#ddd" : row.hasFieldChanged(key) ? "#AFF" : "",
        };
      };
    },
    submit: async function (): Promise<void> {
      //  検索キーワードと変更行チェックをリセットする。
      //  検索と変更行チェック同時使用していた場合、
      //  そのままだと表示されている分しか反映対象に含まれなくなるので、
      //  このタイミングでリセットしてやる必要がある
      this.filter.searchWord = "";
      this.filter.onlyDirtyRows = false;
      this.errs = [];
      await this.$nextTick();
      // 反映ダイアログを表示
      this.dialog.percentage = 0;
      this.dialog.show = true;
      this.dialog.submitButtonDisabled = true;
      this.dialog.title = "変更内容を反映中";
      this.dialog.message = "";
      // 反映する情報を作成する
      const patches: Patch[] = [];
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        const patch: Patch = {
          num: i,
          poiId: row.poiID,
          storeName: row.storeName,
          attributes: [],
          attributeMask: [],
          location: this.locs[row.locationName],
        };
        for (const c of columns) {
          if (!c.gbp) continue;
          const field = c.field as string;
          if (row.hasFieldChanged(field)) {
            patch.attributeMask.push(c.gbp);
            if (row[field]) {
              patch.attributes.push({ name: c.gbp, uriValues: [{ uri: row[field] }] });
            }
          }
        }
        if (row.hasFieldChanged("yahooPlaceWebSite") || row.hasFieldChanged("line")) {
          patch.ypb = {};
          patch.ypb.officialSiteUrl = row.yahooPlaceWebSite ? row.yahooPlaceWebSite : "$delete$";
          patch.ypb.lineOfficialAccount = row.line ? row.line : "$delete$";
        }
        if (row.hasFieldChanged("appleWebSite")) {
          if (!patch.abc) {
            patch.abc = {};
            patch.abc.locationDetails = {};
          }
          patch.abc.locationDetails.urls = [{ type: "HOMEPAGE", url: row.appleWebSite }];
          patch.abcUpdateMask = ["websiteURL"];
        }
        if (0 < patch.attributeMask.length || patch.ypb || patch.abc) {
          patches.push(patch);
        }
      }
      // 反映する
      let count = 0;
      const processing = {} as { [poiId: string]: string };
      const preprocess = async (patch: Patch) => {
        processing[patch.poiId] = patch.storeName;
        this.dialog.message =
          `${count} / ${patches.length}<br>以下の店舗を反映中<br>` +
          Object.values(processing).join("<br/>");
      };
      const postprocess = async (
        patch: Patch,
        res1: EntitiesPutGMBLocationResponse,
        err1: any,
        err2: any,
        err3: any
      ) => {
        count++;
        this.dialog.percentage = Math.floor((count / patches.length) * 100);
        delete processing[patch.poiId];
        this.dialog.message =
          `${count} / ${patches.length}<br>以下の店舗を反映中<br>` +
          Object.values(processing).join("<br/>");
        const row = this.rows[patch.num];
        if (patch.attributeMask.length > 0) {
          if (err1) {
            this.errs.push({
              poiId: patch.poiId,
              storeName: patch.storeName,
              message: getErrorMessage(err1, row.locationName),
            });
          } else {
            row.setGbpAttributes(res1.attributes.attributes);
          }
        }
        if (patch.ypb) {
          if (err2) {
            this.errs.push({
              poiId: patch.poiId,
              storeName: patch.storeName,
              message: "Yahoo!プレイスへの情報反映に失敗しました",
            });
          } else {
            let lineOfficialAccount = patch.ypb.lineOfficialAccount;
            if (lineOfficialAccount === "$delete$") {
              lineOfficialAccount = "";
            }
            let officialSiteUrl = patch.ypb.officialSiteUrl;
            if (officialSiteUrl === "$delete$") {
              officialSiteUrl = "";
            }
            row.setYahoo({ lineOfficialAccount, officialSiteUrl });
          }
        }
        if (patch.abc) {
          if (err3) {
            this.errs.push({
              poiId: patch.poiId,
              storeName: patch.storeName,
              message: getAppleErrorMessage(err3),
            });
          } else {
            row.setApple(patch.abc);
          }
        }
        this.grid.invalidate();
      };
      await putLocationInParallel(
        this.$route,
        this.poiGroupId,
        patches,
        5,
        preprocess,
        postprocess
      );
      this.dialog.title = "反映が完了しました";
      this.dialog.message = `${count} / ${patches.length}`;
      if (this.errs.length > 0) {
        this.dialog.title += " (エラーあり)";
        this.dialog.message +=
          "<br>以下の店舗はエラーがあり反映できませんでした。<br>エラー内容をご確認の上、修正と再反映をお試しください。<hr>";
        this.errs.forEach((e) => {
          this.dialog.message += `<br><b>${e.poiId}</b> ${e.storeName}<br>${e.message}`;
        });
        this.prevErrorMessage = this.dialog.message;
      } else {
        this.prevErrorMessage = "";
      }
      this.dialog.submitButtonDisabled = false;
    },
    // XLSXエクスポート
    async exportXlsx() {
      const aoa: string[][] = []; // Array of arrays
      // ヘッダー行追加
      const header: string[] = [];
      for (const column of columns) {
        header.push(column.caption as string);
      }
      aoa.push(header);
      // ヘッダー行追加
      const rows = this.dataSource.dataSource;
      for (let i = 0; i < rows.length; i++) {
        const row = await rows.get(i);
        const rec: string[] = [];
        for (const column of columns) {
          rec.push(row[column.field as string] ?? "");
        }
        aoa.push(rec);
      }
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, "media-link");
      const buf: ArrayBuffer = XLSX.write(wb, {
        type: "array",
        bookType: "xlsx",
        bookSST: true,
      });
      const datetime = dayjs().format("YYYYMMDD");
      saveAs(
        new Blob([buf], { type: "application/octet-stream" }),
        `${wordDictionary.service.name}-メディアリンクエクスポート-${this.company.name}-${datetime}.xlsx`
      );
    },
    // XLSXインポート
    async importXlsx(event: Event) {
      const target = event.target as HTMLInputElement;
      const file: File = target.files[0];
      if (!file) {
        return;
      }
      target.value = "";
      const buf = await read(file);
      const aoa = arrayBufferToStringsArrays(buf)[0];
      for (let i = 0; i < this.dataSource.dataSource.length; i++) {
        const row = await this.dataSource.dataSource.get(i);
        const array = aoa.find((a) => a[0] === row.poiID.toString());
        if (!array) continue;
        for (let j = 0; j < columns.length; j++) {
          const column = columns[j];
          // cheetah-grid のセルがreadonlyの場合はスキップ
          if (!row.enabled[column.field as string]) continue;
          row[column.field as string] = aoa[i + 1][j];
        }
      }
      this.updateFilter();
      this.grid.invalidate();
      useSnackbar().addSnackbarMessages({ text: "インポートしました", color: "info" });
    },
    showErrMessages: function () {},
  },
});
async function putLocationInParallel(
  route: RouteLocationNormalizedLoaded,
  poiGroupId: number,
  patches: Patch[],
  concurrency: number,
  preprocess: (patch: Patch) => Promise<void>,
  postprocess: (
    patch: Patch,
    res1: EntitiesPutGMBLocationResponse,
    err1: any,
    err2: any,
    err3: any
  ) => Promise<void>
): Promise<void> {
  const ps = parallels();
  for (const patch of patches) {
    const gmbLocation: LocationAndAttributes = JSON.parse(JSON.stringify(patch.location));
    gmbLocation.attributes = patch.attributes;
    const data: EntitiesPutGMBLocationRequest = {
      gmbLocation: gmbLocation,
      oldGmbLocation: patch.location,
      updateAttributes: patch.attributeMask,
      updateItems: [],
    };
    const url1 = `${apiBase}/companies/${poiGroupId}/stores/${patch.poiId}/gmbLocation/attributes`;
    const params1 = getOperationLogParams(route, "addition-put");
    const params3 = getOperationLogParams(route, "apple-patch");
    const f = async () => {
      await preprocess(patch);
      let res1: EntitiesPutGMBLocationResponse;
      let err1: any;
      if (patch.attributeMask.length > 0) {
        try {
          res1 = (await requiredAuth<EntitiesPutGMBLocationResponse>("put", url1, params1, data))
            .data;
        } catch (ex) {
          err1 = ex;
        }
      }
      let err2: any;
      if (patch.ypb) {
        try {
          const res = await yapi.patchStore(poiGroupId, patch.poiId, patch.ypb);
          if (res.error) {
            throw new Error(`${res.error.errorCode}, ${res.error.reason}`);
          }
        } catch (ex) {
          err2 = ex;
        }
      }
      let err3: any;
      if (patch.abc) {
        try {
          await abcapi.patchAppleLocation(
            poiGroupId,
            patch.poiId,
            patch.abc,
            patch.abcUpdateMask,
            params3
          );
        } catch (ex) {
          err3 = ex;
        }
      }
      await postprocess(patch, res1, err1, err2, err3);
    };
    ps.add(f());
    await ps.race(concurrency);
  }
  await ps.all();
  return;
}
function getErrorMessage(
  e: {
    response: { data: { GMBError: { error: any }; message: string; errorMessage: any } };
    message: any;
  },
  locationName: string
): string {
  let errorMessage = "";
  if (e?.response?.data?.GMBError?.error) {
    // GBPからエラー情報が返ってきた
    errorMessage = `${getGBPErrorMessage(
      e.response.data.GMBError.error,
      getDashboardUrl(locationName) // GBP管理画面のURL
    )}`;
  } else {
    errorMessage = getNormalErrorMessage(e as any);
  }
  return errorMessage;
}
</script>

<style lang="scss" scoped>
.custom-grid {
  box-sizing: border-box;
  height: calc(100vh - 220px);
  min-width: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: -70px;
}
.parent-container {
  position: relative;
}
.custom-progress-circular-container {
  position: absolute;
  z-index: var(--z-index-loading);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
</style>
