import { arrayBufferToStringsArray, read } from "@/helpers/xlsxtools";

/**
 * XLSXを読み込んだ結果を保持するクラス
 */
export class XlsxTable {
    static REGEX_CELL: RegExp = /^([A-Za-z_]+)\s*:\s*(.+)$/;
    rows: string[][]; // 0行目はヘッダ行
    poiIdIndex: number; // 店舗IDの列番号
    ignoreIndices: number[]; // 読み飛ばす列番号（店舗ID、店舗コード、店舗名）
    constructor(rows: string[][], poiIdIndex: number, ignoreIndices: number[]) {
        this.rows = rows;
        this.poiIdIndex = poiIdIndex;
        this.ignoreIndices = ignoreIndices;
    }
    /**
     * XLSXの書式チェックを行う
     */
    validate(parseCategory: (s: string) => any): { i: number; j: number; error: string }[] {
        const errs: { i: number; j: number; error: string }[] = [];
        if (this.poiIdIndex < 0) {
            errs.push({ i: 0, j: 0, error: "店舗IDがありません" });
            return errs;
        }
        for (let i = 1; i < this.rows.length; i++) {
            const row = this.rows[i];
            for (let j = 0; j < row.length; j++) {
                if (this.ignoreIndices.includes(j)) {
                    continue;
                }
                const col = row[j];
                if (col === "") {
                    continue;
                }
                const match = col.match(XlsxTable.REGEX_CELL);
                if (!match) {
                    errs.push({ i, j, error: `フォーマットが正しくありません: '${col}'` });
                    continue;
                }
                const [category, _] = [match[1], match[2]];
                const ci = parseCategory(category);
                if (!ci) {
                    errs.push({ i, j, error: `カテゴリが正しくありません: '${category}'` });
                }
            }
        }
        return errs;
    }
    /**
     * poiIdの行を取得し、その行データと行番号を返す
     */
    findRow(poiId: number): [string[], number] {
        for (let i = 1; i < this.rows.length; i++) {
            const row = this.rows[i];
            if (row[this.poiIdIndex] === `${poiId}`) {
                return [row, i];
            }
        }
        return [null, null];
    }
    /**
     * poiIdとサムネイルURLに一致するデータと行番号と列番号を返す
     */
    get(poiId: number, thumbnail: string): [string, string, number, number] {
        const [row, i] = this.findRow(poiId);
        for (let j = 0; j < row.length; j++) {
            if (this.ignoreIndices.includes(j)) {
                continue;
            }
            const col = row[j];
            const match = col.match(XlsxTable.REGEX_CELL);
            if (!match) {
                continue;
            }
            const [cate, thumb] = [match[1], match[2]];
            if (thumbnail === thumb) {
                return [cate, thumb, i, j];
            }
        }
        return [null, null, null, null];
    }
    /**
     * poiIdの行の中でhttpで開始しないURLの、カテゴリ、サムネイルURL、行番号、列番号の配列を返す
     */
    getAddedCols(poiId: number): [string, string, number, number][] {
        const result: [string, string, number, number][] = [];
        const [row, i] = this.findRow(poiId);
        for (let j = 0; j < row.length; j++) {
            if (this.ignoreIndices.includes(j)) {
                continue;
            }
            const col = row[j];
            const match = col.match(XlsxTable.REGEX_CELL);
            if (!match) {
                continue;
            }
            if (match[2].startsWith("http") === false) {
                const [cate, thumb] = [match[1], match[2]];
                result.push([cate, thumb, i, j]);
            }
        }
        return result;
    }
    /**
     * XLSXファイルを読み込む
     */
    static async read(file: File): Promise<XlsxTable> {
        const buf = await read(file);
        const lines = arrayBufferToStringsArray(buf);
        if (lines.length === 0) {
            return new XlsxTable([], -1, []);
        }
        for (const row of lines) {
            for (let i = 0; i < row.length; i++) {
                row[i] = row[i].trim();
            }
        }
        const fields = lines[0].map((s) => s.trim());
        const poiIdIndex = fields.indexOf("店舗ID");
        const ignoreIndices = [
            poiIdIndex,
            fields.indexOf("店舗コード"),
            fields.indexOf("ビジネス名"),
        ].filter((x) => x != -1);
        return new XlsxTable(lines, poiIdIndex, ignoreIndices);
    }
}
